import React from 'react';
import { useEffect, useState } from "react"
import { staffingUrl } from "../interceptor"
import { DatePicker, Select, Button, Space } from 'antd';
import dayjs from "dayjs";
import { useNavigate } from 'react-router-dom';
import { TailSpin } from "react-loader-spinner";

const { RangePicker } = DatePicker;

export default function DailyTracker() {

    const dateFormat = 'YYYY/MM/DD';
    const currentDate = dayjs().format('DD-MM-YYYY');

    const Loader = <TailSpin visible={true} height="50" width="80" radius="1" color='#6C5295' />;

    const initialDateRange = {
        startDate: currentDate,
        endDate: currentDate,
    };

    const [eventLogData, setEventLogData] = useState([]);
    const [candidateLogs, setCandidateLogs] = useState([]);
    const [formattedLogData, setFormattedLogData] = useState([]);
    const [isBtnClicked, setIsBtnClicked] = useState("today");
    const [dateRange, setDateRange] = useState(initialDateRange);
    const [logFilterList, setLogFilterList] = useState([]);
    const [backupLogData, setBackUpLogData] = useState([]);
    const [logFilterType, setLogFilterType] = useState('Talent');
    const [logFilterValue, setLogFilterValue] = useState('All');
    const [filteredLogIds, setFilteredLogIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [groupedData, setGroupedData] = useState([]);
    const [location, setLocation] = useState(localStorage.getItem('current_location') || '')

    const navigate = useNavigate();

    //const groupedData = groupedLogData(formattedLogData);
    const groupedLogData = (logData) => {
        const grouped = logData.reduce((acc, item) => {
            const date = dayjs(item.occuredAt).format('DD MMM, YYYY');
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(item);
            return acc;
        }, {});

        setGroupedData(grouped);
    };

    const fetchReqData = async (start, end) => {
        try {
            const apiResponse = await staffingUrl.get(`api/Log/getEventLogswithDateRange/${start}/${end}/${location}`);
            const data = apiResponse.data.data;
            const reqId = [];
            const reqTitleMap = {};

            const uniqueReqIds = [...new Set(data.map(item => item.req_id).filter(Boolean))];

            await Promise.all(
                uniqueReqIds.map(async (id) => {
                    try {
                        const apiValue = await staffingUrl.get(`api/StaffMgnt/getRequirementbyID?id=${id}`);
                        reqTitleMap[id] = apiValue.data.Requirement_title;
                    } catch (error) {
                        reqTitleMap[id] = null;
                    }
                })
            );

            const updatedData = data.map((item) => {
                if (item.req_id) {
                    reqId.push(item.req_id);
                    return { ...item, req_title: reqTitleMap[item.req_id] };
                }
                return item;
            });

            // return updatedData;
            setEventLogData(updatedData);
            setFormattedLogData([]);
            return updatedData;
        } catch (error) {
            console.error('Failed to fetch initial data', error);
        }
    };

    const fetchCandData = async (start, end) => {
        try {
            const apiResponse = await staffingUrl.get(`api/Log/getCandidateLogswithDateRange/${start}/${end}/${location}`);
            setCandidateLogs(apiResponse.data?.data);
            setFormattedLogData([]);
            return apiResponse.data;
        }
        catch (err) {
            console.log(err);
        }
    }

    const onRangeChange = async (dates, dateStrings) => {
        setLogFilterValue('All');
        // setLogFilterType('Talent');
        getLogFilterList(logFilterType);
        if (dates) {
            setDateRange({
                startDate: dayjs(dateStrings[0]).format('DD-MM-YYYY'),
                endDate: dayjs(dateStrings[1]).format('DD-MM-YYYY')
            });
        } else {
            setDateRange(initialDateRange);
        }
        setIsLoading(false);
        await fetchReqData(dayjs(dateStrings[0]).format('DD-MM-YYYY'), dayjs(dateStrings[1]).format('DD-MM-YYYY'));
        await fetchCandData(dayjs(dateStrings[0]).format('DD-MM-YYYY'), dayjs(dateStrings[1]).format('DD-MM-YYYY'));
        setIsLoading(true);
    };

    const handleTypeChange = (value) => {
        setLogFilterType(value);
        getLogFilterList(value);
    }

    const handleValueChange = async (value) => {
        setLogFilterValue(value);
        if (value === 0) {
            setIsLoading(false);
            await fetchReqData(dateRange.startDate, dateRange.endDate);
            await fetchCandData(dateRange.startDate, dateRange.endDate);
            setIsLoading(true);
        } else {
            try {
                setIsLoading(false);
                const apiResponse = await staffingUrl.get(`api/StaffMgnt/getFilterValuesById/${logFilterType}/${value}/${dateRange.startDate}/${dateRange.endDate}`);
                setFilteredLogIds(apiResponse.data.logIds);
                const idSet = new Set(apiResponse.data.logIds);
                let matchingData = backupLogData.filter(item => idSet.has(item.id));
                matchingData = Array.from(new Map(matchingData.map(item => [item.id, item])).values());

                setFormattedLogData(matchingData);
                setIsLoading(true);
            } catch (err) {
                console.log(err)
            }
        }
    }

    const getLogFilterList = async (filter) => {
        let talentList = [];
        try {
            const apiResponse = await staffingUrl.get(`api/StaffMgnt/getLogFilterValues/${filter}`)
            if (apiResponse.data?.Talent_details?.length > 0) {
                talentList = apiResponse.data.Talent_details?.map((item) => ({
                    value: item.Emp_id,
                    // value: `${item.Emp_id}-${item.Email_id?.split('@')[0]}`,
                    label: item.Email_id?.split('@')[0]
                }))
            } else if (apiResponse.data?.Vendor_details?.length > 0) {
                talentList = apiResponse.data.Vendor_details?.map((item) => ({
                    value: item.sno,
                    label: item.customer1
                }))
            }
        } catch (err) {
            console.log(err)
        }
        setLogFilterList(talentList);
    }

    useEffect(() => {
        const sortedData = eventLogData.sort((a, b) => new Date(b.occuredAt) - new Date(a.occuredAt));
        sortedData.map((item) => {
            if (!(item?.actionEvent === "Requirement Updated")) {
                setFormattedLogData(prev => [...prev, item]);
                setBackUpLogData(prev => [...prev, item])
            }
        })

        const sortedCandidateData = candidateLogs.sort((a, b) => new Date(b.occuredAt) - new Date(a.occuredAt));
        sortedCandidateData.map((item) => {
            if (!(item?.actionEvent === "Interview Scheduled"
                || item?.actionEvent === "Candidate Mapping"
                || item?.actionEvent === "Candidate Removing"
                || item?.actionEvent === "Candidate Updated"
                || item?.actionEvent === "Candidate Status Change"
            )) {
                setFormattedLogData(prev => [...prev, item]);
                setBackUpLogData(prev => [...prev, item])
            }
        })
    }, [eventLogData, candidateLogs])

    useEffect(() => {
        groupedLogData(formattedLogData);
    }, [formattedLogData])

    useEffect(() => {
        const fetchDetails = async () => {
            setIsLoading(false);
            await fetchReqData(currentDate, currentDate);
            await fetchCandData(currentDate, currentDate);
            setIsLoading(true);
        }
        fetchDetails();
        getLogFilterList("Talent");
    }, [])

    useEffect(() => {
        const fetchDetails = async () => {
            setIsLoading(false);
            await fetchReqData(dateRange.startDate, dateRange.endDate);
            await fetchCandData(dateRange.startDate, dateRange.endDate);
            setIsLoading(true);
        }
        fetchDetails();
    }, [location])

    useEffect(() => {
        const onLocationChange = () => {
            setLocation(localStorage.getItem('current_location') || '');
        };
        window.addEventListener('locationChanged', onLocationChange);

        return () => {
            window.removeEventListener('locationChanged', onLocationChange);
        };
    }, []);

    return (
        <div>
            <div className="flex items-center py-3 gap-3 justify-between">
                <div className="flex gap-2 items-center">
                    <Button
                        className={`text-[12px] font-normal text-[#fff] pt-[3px] rounded-none ${isBtnClicked !== 'today' ? 'bg-[#6c5295]' : ' bg-[white] text-[black]'}`}
                        onClick={() => {
                            onRangeChange(
                                [dayjs().startOf("day"), dayjs().endOf("day")],
                                [
                                    dayjs().startOf("day").format("YYYY-MM-DD"),
                                    dayjs().endOf("day").format("YYYY-MM-DD"),
                                ]
                            );
                            setIsBtnClicked('today');
                        }}
                    >
                        Today
                    </Button>
                    <Button
                        className={`text-[12px] font-normal text-[#fff] pt-[3px] rounded-none ${isBtnClicked !== 'yesterday' ? 'bg-[#6c5295]' : ' bg-[white] text-[black]'}`}
                        onClick={() => {
                            onRangeChange(
                                [
                                    dayjs().subtract(1, "day").startOf("day"),
                                    dayjs().subtract(1, "day").endOf("day"),
                                ],
                                [
                                    dayjs()
                                        .subtract(1, "day")
                                        .startOf("day")
                                        .format("YYYY-MM-DD"),
                                    dayjs().subtract(1, "day").endOf("day").format("YYYY-MM-DD"),
                                ]
                            );
                            setIsBtnClicked('yesterday');
                        }}
                    >
                        Yesterday
                    </Button>
                    <Button
                        className={`text-[12px] font-normal text-[#fff] pt-[3px] rounded-none ${isBtnClicked !== 'thisweek' ? 'bg-[#6c5295]' : ' bg-[white] text-[black]'}`}
                        onClick={() => {
                            onRangeChange(
                                [dayjs().startOf("week"), dayjs().endOf("day")],
                                [
                                    dayjs().startOf("week").format("YYYY-MM-DD"),
                                    dayjs().endOf("day").format("YYYY-MM-DD"),
                                ]
                            );
                            setIsBtnClicked('thisweek');
                        }}
                    >
                        This Week
                    </Button>
                    <Button
                        className={`text-[12px] font-normal text-[#fff] pt-[3px] rounded-none ${isBtnClicked !== 'lastweek' ? 'bg-[#6c5295]' : ' bg-[white] text-[black]'}`}
                        onClick={() => {
                            onRangeChange(
                                [
                                    dayjs().subtract(1, "week").startOf("week"),
                                    dayjs().subtract(1, "week").endOf("week"),
                                ],
                                [
                                    dayjs()
                                        .subtract(1, "week")
                                        .startOf("week")
                                        .format("YYYY-MM-DD"),
                                    dayjs()
                                        .subtract(1, "week")
                                        .endOf("week")
                                        .format("YYYY-MM-DD"),
                                ]
                            );
                            setIsBtnClicked('lastweek');
                        }}
                    >
                        Last Week
                    </Button>
                    <Button
                        className={`text-[12px] font-normal text-[#fff] pt-[3px] rounded-none ${isBtnClicked !== 'thismonth' ? 'bg-[#6c5295]' : ' bg-[white] text-[black]'}`}
                        onClick={() => {
                            onRangeChange(
                                [dayjs().startOf("month"), dayjs().endOf("day")],
                                [
                                    dayjs().startOf("month").format("YYYY-MM-DD"),
                                    dayjs().endOf("day").format("YYYY-MM-DD"),
                                ]
                            );
                            setIsBtnClicked('thismonth');
                        }}
                    >
                        This Month
                    </Button>
                    <Button
                        className={`text-[12px] font-normal text-[#fff] pt-[3px] rounded-none ${isBtnClicked !== 'lastmonth' ? 'bg-[#6c5295]' : ' bg-[white] text-[black]'}`}
                        onClick={() => {
                            onRangeChange(
                                [
                                    dayjs().subtract(1, "month").startOf("month"),
                                    dayjs().subtract(1, "month").endOf("month"),
                                ],
                                [
                                    dayjs()
                                        .subtract(1, "month")
                                        .startOf("month")
                                        .format("YYYY-MM-DD"),
                                    dayjs()
                                        .subtract(1, "month")
                                        .endOf("month")
                                        .format("YYYY-MM-DD"),
                                ]
                            );
                            setIsBtnClicked('lastmonth');
                        }}
                    >
                        Last Month
                    </Button>
                    {/* <Button
                        className={`text-[12px] font-normal text-[#fff] pt-[3px] rounded-none ${isBtnClicked !== 'all' ? 'bg-[#6c5295]' : ' bg-[white] text-[black]'}`}
                        onClick={() => {
                            setDateRange(initialDateRange);
                            setIsBtnClicked("all");
                        }}
                    >
                        All
                        {/* <MdOutlineRefresh /> 
                    </Button> */}
                </div>
                <div className="flex flex-row items-center gap-2">
                    <Space direction="horizontal" size={12}>
                        <Select
                            value={logFilterType}
                            style={{ width: 150 }}
                            onSelect={handleTypeChange}
                            options={[
                                { value: 'Talent', label: 'Talent' },
                                { value: 'Vendor', label: 'Vendor' },
                            ]}
                        />
                        <Select
                            value={logFilterValue}
                            style={{ width: 200 }}
                            onSelect={handleValueChange}
                            options={[
                                ...logFilterList,
                                { value: 0, label: 'All' },
                            ]}
                        />
                        {/* <RangePicker
                            onChange={onRangeChange}
                            defaultValue={[dayjs(currentDate, dateFormat), dayjs(currentDate, dateFormat)]}
                        /> */}
                    </Space>
                </div>
            </div>

            {(formattedLogData.length > 0) ? (
                <>
                    <div style={{ maxHeight: 'calc(100vh - 165px)', overflow: 'hidden auto' }}>
                        <div>
                            {isLoading ? (
                                <table className="traker_table" style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                                    <thead style={{ backgroundColor: '#f2f2f2' }}>
                                        <tr>
                                            <th >Time</th>
                                            <th style={{ textAlign: 'center', width: '10%' }}>Changed By</th>
                                            <th style={{ textAlign: 'center' }}>Activity Logs</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(groupedData).map((date, index) => (
                                            <React.Fragment key={index}>
                                                <tr>
                                                    <td colSpan="3">
                                                        <div className="my-2 first:mt-4 relative flex justify-center">
                                                            <span className="w-full h-[2px] absolute inset-0 bg-gray-200 top-1/2"></span>
                                                            <h3 className="text-[#7c7c7c] text-xs font-medium dark:text-neutral-400 p-2 bg-[#f9fafb] relative z-9">
                                                                {dayjs(date).isSame(dayjs(), 'day')
                                                                    ? 'Today'
                                                                    : dayjs(date).format('DD MMM, YYYY')
                                                                }
                                                            </h3>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {groupedData[date].map((item, logIndex) => (
                                                    <tr key={logIndex}>
                                                        <td style={styles.cell}>
                                                            {dayjs(item.occuredAt).format('HH:mm')}
                                                        </td>
                                                        <td style={{ whiteSpace: 'nowrap', textAlign: "center", textTransform: 'capitalize' }}>
                                                            {item.actionEvent === "Task Board State Changes" ?
                                                                (item.changedBy.split(' ')[0]) : item.changedBy.replace(/@jetzerp\.com/, '').toLowerCase()}
                                                        </td>
                                                        <td style={styles.cell}>
                                                            <p>
                                                                {/* onClick={() => { item?.req_id ? navigate(`/requirement/info/${item?.req_id}`) : navigate(`/candidateInfo/info/${item?.candidateId}`) }} */}

                                                                {
                                                                    !!item?.req_id
                                                                        ? (item?.actionEvent === "Requirement Created"
                                                                            ? (
                                                                                <span
                                                                                    onClick={() => { navigate(`/requirement/info/${item?.req_id}`) }}
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: `#${item?.req_id}-${item.req_title} requirement is created`.replace(
                                                                                            new RegExp(`(#${item?.req_id}-${item.req_title})`, 'g'),
                                                                                            `<strong style="color: #da0a00; font-style: italic; font-size: 12px; cursor: pointer;">$1</strong>`
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            ) :
                                                                            item.actionEvent === "Candidate Mapping"
                                                                                ? (
                                                                                    <span
                                                                                        onClick={() => { navigate(`/requirement/info/${item?.req_id}`) }}

                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: `#${item?.req_id}-${item.req_title}, ${JSON.parse(item.changes)[0].content?.split('by')[0]}`
                                                                                                .replace(
                                                                                                    new RegExp(`#${item?.req_id}-${item.req_title}`, 'g'),
                                                                                                    `<strong class="clickable-req" style="color: #da0a00; font-style: italic; font-size: 12px;cursor: pointer;" >#${item?.req_id
                                                                                                    }-${item.req_title}</strong>`
                                                                                                )
                                                                                                .replace(
                                                                                                    JSON.parse(item.changes)[0].content?.split(" is")[0],
                                                                                                    `<strong style="color: #6C5295; font-style: italic; font-size: 12px;cursor: pointer;" >${JSON.parse(item.changes)[0].content?.split(" is")[0]}</strong>`
                                                                                                )
                                                                                        }}
                                                                                    />
                                                                                ) :
                                                                                item.actionEvent === "Candidate Status Change"
                                                                                    ? (
                                                                                        <span
                                                                                            onClick={() => { navigate(`/requirement/info/${item?.req_id}`) }}

                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: `#${item?.req_id}-${item.req_title}, ${JSON.parse(item.changes)[0].content?.split("'")[0]}'s ${JSON.parse(item.changes)[0].content?.split("'s")[1]?.split('by')[0]}`
                                                                                                    .replace(
                                                                                                        new RegExp(`#${item?.req_id}-${item.req_title}`, 'g'),
                                                                                                        `<strong style="color: #da0a00; font-style: italic; font-size: 12px; cursor: pointer;">#${item?.req_id}-${item.req_title}</strong>`
                                                                                                    )
                                                                                                    .replace(
                                                                                                        JSON.parse(item.changes)[0].content?.split("'")[0],
                                                                                                        `<strong style="color: #6C5295; font-style: italic; font-size: 12px; cursor: pointer;">${JSON.parse(item.changes)[0].content?.split("'")[0]}</strong>`
                                                                                                    )
                                                                                            }}
                                                                                        />
                                                                                    ) :
                                                                                    item.actionEvent === "Task Created"
                                                                                        ? (
                                                                                            <span
                                                                                                onClick={() => { navigate(`/requirement/info/${item?.req_id}`) }}

                                                                                                dangerouslySetInnerHTML={{
                                                                                                    __html: `#${item?.req_id}-${item.req_title}, ${JSON.parse(item.changes)[0].content?.split('by')[0]} and ${JSON.parse(item.changes)[0].content?.split('by')[1]?.split('and')[1]}`
                                                                                                        .replace(
                                                                                                            new RegExp(`#${item?.req_id}-${item.req_title}`, 'g'),
                                                                                                            `<strong style="color: #da0a00; font-style: italic; font-size: 12px; cursor: pointer;">#${item?.req_id
                                                                                                            }-${item.req_title}</strong>`
                                                                                                        )
                                                                                                }}
                                                                                            />
                                                                                        ) :
                                                                                        item.actionEvent === "Task Board State Changes"
                                                                                            ? (
                                                                                                <span
                                                                                                    onClick={() => { navigate(`/requirement/info/${item?.req_id}`) }}

                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html: `#${item?.req_id}-${item.req_title}, ${JSON.parse(item.changes)[0].content?.split('by')[0]}`
                                                                                                            .replace(
                                                                                                                new RegExp(`#${item?.req_id}-${item.req_title}`, 'g'),
                                                                                                                `<strong style="color: #da0a00; font-style: italic; font-size: 12px; cursor: pointer;">#${item?.req_id
                                                                                                                }-${item.req_title}</strong>`
                                                                                                            )
                                                                                                    }}
                                                                                                />
                                                                                            ) :
                                                                                            item.actionEvent === "Discussion Log"
                                                                                                ? (
                                                                                                    <span
                                                                                                        onClick={() => { navigate(`/requirement/info/${item?.req_id}`) }}

                                                                                                        dangerouslySetInnerHTML={{
                                                                                                            __html: `Comments:- ${JSON.parse(item.changes)[0].content?.includes('$') ? JSON.parse(item.changes)[0].content.split('$')[1] : JSON.parse(item.changes)[0].content}`
                                                                                                                .replace(
                                                                                                                    new RegExp(`Comments:-`, 'g'),
                                                                                                                    `<strong style="color: #11726B; font-style: italic; font-size: 12px; cursor: pointer;">Comments:-</strong>`
                                                                                                                )
                                                                                                        }}
                                                                                                    />

                                                                                                ) :
                                                                                                item.actionEvent === "Interview Scheduled"
                                                                                                    ? (
                                                                                                        <span
                                                                                                            onClick={() => { navigate(`/requirement/info/${item?.req_id}`) }}

                                                                                                            dangerouslySetInnerHTML={{
                                                                                                                __html: `#${item?.req_id}-${item.req_title}, ${JSON.parse(item.changes)[0].content?.split('by')[0]}`
                                                                                                                    .replace(
                                                                                                                        new RegExp(`#${item?.req_id}-${item.req_title}`, 'g'),
                                                                                                                        `<strong style="color: #da0a00; font-style: italic; font-size: 12px; cursor: pointer;">#${item?.req_id
                                                                                                                        }-${item.req_title}</strong>`
                                                                                                                    )
                                                                                                            }}
                                                                                                        />
                                                                                                    ) :
                                                                                                    item.actionEvent === "Candidate Removing"
                                                                                                        ? (
                                                                                                            <span
                                                                                                                onClick={() => { navigate(`/requirement/info/${item?.req_id}`) }}

                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                    __html: `#${item?.req_id}-${item.req_title}, ${JSON.parse(item.changes)[0].content?.split('by')[0]}`
                                                                                                                        .replace(
                                                                                                                            new RegExp(`#${item?.req_id}-${item.req_title}`, 'g'),
                                                                                                                            `<strong style="color: #da0a00; font-style: italic; font-size: 12px; cursor: pointer;">#${item?.req_id
                                                                                                                            }-${item.req_title}</strong>`
                                                                                                                        )
                                                                                                                }}
                                                                                                            />
                                                                                                        ) : ('')

                                                                        ) : (
                                                                            item.actionEvent === "Candidate Created"
                                                                                ? (
                                                                                    <span
                                                                                        onClick={() => { navigate(`/candidateInfo/info/${item?.candidateId}`) }}

                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: `${JSON.parse(item.changes)[0].content?.split('created')[0]} is created as new candidate`
                                                                                                .replace(
                                                                                                    new RegExp(`(${JSON.parse(item.changes)[0].content?.split('created')[0]})`, 'g'),
                                                                                                    `<strong style="color: #6C5295; font-style: italic; font-size: 12px; cursor: pointer;">$1</strong>`
                                                                                                )
                                                                                        }}
                                                                                    />
                                                                                ) : item.actionEvent === "Discussion Log"
                                                                                    ?
                                                                                    <span
                                                                                        onClick={() => { navigate(`/candidateInfo/info/${item?.candidateId}`) }}
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: `Comments:- ${JSON.parse(item.changes)[0].content?.includes('$') ? JSON.parse(item.changes)[0].content.split('$')[1] : JSON.parse(item.changes)[0].content}`
                                                                                                .replace(
                                                                                                    new RegExp(`Comments:-`, 'g'),
                                                                                                    `<strong style="color: #11726B; font-style: italic; font-size: 12px; cursor: pointer;">Comments:-</strong>`
                                                                                                )
                                                                                        }}
                                                                                    />
                                                                                    : ('')
                                                                        )
                                                                }

                                                            </p>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <div style={{ width: '100%', marginTop: "10%", display: 'flex', justifyContent: "center" }}>
                                    {Loader}
                                </div>
                            )
                            }

                        </div>
                    </div>

                </>
            ) : (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>No records to display</div>
                </>
            )}
        </div >
    )
}

const styles = {

    cell: {
        padding: '8px',
        textAlign: 'left',
        fontSize: '13px',
    },
    datecell: {
        padding: '8px',
        textAlign: 'left',
        fontSize: '13px',
    },
    button: {
        padding: '6px 12px',
        backgroundColor: '#f44336',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
    },
};