import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { staffingUrl } from "../interceptor";
import VideoSrc from "../assets/video/homeVdo.mp4";
import { TailSpin } from "react-loader-spinner";

export const Signin = ({ isAuthenticated, setIsRegistered }) => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const Loader =
    <TailSpin
      visible={true}
      height="30"
      width="50"
      radius="1"
    />;

  const handleSignInWithMicrosoft = async () => {
    instance.loginRedirect({
      scopes: ["user.read"],
    });
  };

  const GetEmployeeDetails = async (email) => {
    setIsLoading(true);
    await staffingUrl
      .get(`api/StaffMgnt/getEmployeeDetails/${email}/`)
      .then((res) => {
        if (res.data?.msg === "Employee doesn't exist") {
          navigate("/employee", { state: { disableEmail: true } });
          setIsRegistered(false);
        } else if (res.data?.employeeDetails?.position === "HR Reqcruiter"
          && (res.data?.employeeDetails?.phone_number === null
            || res.data?.employeeDetails?.phone_number === "null"
            || res.data?.employeeDetails?.username === null
            || res.data?.employeeDetails?.username === "null"
          )) {
          navigate("/employee", { state: { disableEmail: true } })
          setIsRegistered(false);
        } else {
          setIsRegistered(true);
          localStorage.setItem("current_emp_id", res.data?.employeeDetails?.Emp_id)
          navigate("/dashboard")
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (isAuthenticated) {
      const { username } = instance?.getActiveAccount();
      GetEmployeeDetails(username);
    }
  }, [isAuthenticated]);

  return (
    <div>
      <div className="h-screen grid grid-cols-2">
        <div className="flex justify-center items-center md:flex">
          {/* <img className="" src="/jetz-logo.png" /> */}
          <video
            className="w-full h-full object-cover"
            autoPlay
            loop
            muted
          >
            <source src={VideoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="bg-blue-100 flex flex-col gap-6 justify-center items-center p-10 sm:p-12.5 xl:p-17.5">
          <img className="sm:max-w-full max-w-48" src="./jetz-logo.png" />
          {/* <h3 className='text-2xl'>Login using Microsoft</h3> */}
          {isLoading ? Loader : (
            <button
              className="bg-[#3f51b5] w-full text-white justify-center px-5 py-5 rounded-md flex gap-5 items-center"
              onClick={handleSignInWithMicrosoft}
            >
              <img style={{ width: "1.5em" }} src="./microsoft.png" />
              Sign in with Microsoft
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
