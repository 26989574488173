import { staffingUrl } from "../interceptor";

export const logger = async (payload) => {
  try {
    const createLog = await staffingUrl.post(`api/Log/masterEventLogger`, payload);

    return createLog.data;
  } catch (error) {
    console.log(error);
  }
}
