import React, { useEffect, useState } from "react";
import MentionDropdown from "./MentionDropdown";
import { CgAttachment, CgExpand, CgSmile } from "react-icons/cg";
import { Button, Form, Mentions } from "antd";
import { useMsal } from "@azure/msal-react";
import { staffingUrl } from "../../interceptor";
import { logger } from "../../utils/logger";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export default function Chat({
  showSender,
  employeeLists,
  candidateLists,
  reqLists,
  mentionedEmpId,
  mentionedReqId,
  mentionedCandId,
  setMentionedEmpId,
  setMentionedReqId,
  setMentionedCandId,
  getDiscussion,
  id,
  setFilterValue,
  from,
  openNotificationWithIcon
}) {
  const { instance } = useMsal();
  const { name, username } = instance?.getActiveAccount();

  const [commentForm] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [prefix, setPrefix] = useState('@');

  const onSearch = (_, newPrefix) => {
    setPrefix(newPrefix);
  };

  const handleChange = value => {
    if (value.length === 0) {
      setMentionedEmpId(null);
      from === "requirement" && setMentionedCandId(null);
      from !== "requirement" && setMentionedReqId(null);
    }

    if (mentionedEmpId) {
      const mentionedEmpName = employeeLists.find(item => item.emp_id === mentionedEmpId).value;

      const findLongestPrefix = (fullName, inputValue) => {
        for (let i = fullName.length; i > 0; i--) {
          const prefix = fullName.substring(0, i);
          if (inputValue.includes(`@${prefix}`)) {
            return prefix;
          }
        }
        return '';
      };

      const longestPrefix = findLongestPrefix(mentionedEmpName, value);
      const mentionRegex = new RegExp(`@${mentionedEmpName}`, 'g');

      if (!mentionRegex.test(value)) {
        const newValue = value.replace(`@${longestPrefix}`, '').trim();
        commentForm.setFieldValue('notes', newValue);
        setMentionedEmpId(null);
      }
    }

    if (mentionedReqId) {
      const mentionedReqName = reqLists.find(item => item.req_id === mentionedReqId).value;

      const findLongestPrefix = (fullName, inputValue) => {
        for (let i = fullName.length; i > 0; i--) {
          const prefix = fullName.substring(0, i);
          if (inputValue.includes(`#${prefix}`)) {
            return prefix;
          }
        }
        return '';
      };

      const longestPrefix = findLongestPrefix(mentionedReqName, value);
      const mentionRegex = new RegExp(`#${mentionedReqName}`, 'g');

      if (!mentionRegex.test(value)) {
        const newValue = value.replace(`#${longestPrefix}`, '').trim();
        commentForm.setFieldValue('notes', newValue);
        setMentionedReqId(null);
      }
    }

    if (mentionedCandId) {
      const mentionedCandName = candidateLists.find(item => item.candidate_id === mentionedCandId).value;

      const findLongestPrefix = (fullName, inputValue) => {
        for (let i = fullName.length; i > 0; i--) {
          const prefix = fullName.substring(0, i);
          if (inputValue.includes(`$${prefix}`)) {
            return prefix;
          }
        }
        return '';
      };

      const longestPrefix = findLongestPrefix(mentionedCandName, value);
      const mentionRegex = new RegExp(`\\$${mentionedCandName}`, 'g');

      if (!mentionRegex.test(value)) {
        const newValue = value.replace(`$${longestPrefix}`, '').trim();
        commentForm.setFieldValue('notes', newValue);
        setMentionedCandId(null);
      }
    }
  }

  const handleReqNotesSubmit = async (data) => {
    try {
      setIsLoading(true);
      setFilterValue('All');
      const payload = {
        req_id: !!id ? parseInt(id) : id,
        actionEvent: "Discussion Log",
        changedBy: username,
        username: name,
        occuredAt: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
        changes: [
          {
            content: data.notes.trim(),
            mentionedCand:
              candidateLists.find(item => item.candidate_id === mentionedCandId)?.value || null,
            mentionedCandId,
            mentionedEmpId,
            mentionedEmp:
              employeeLists.find(item => item.emp_id === mentionedEmpId)?.value || null
          },
        ],
      };

      if (mentionedCandId !== "") {
        const payload = {
          candidate_id: mentionedCandId,
          actionEvent: "Discussion Log",
          changedBy: username,
          username: name,
          occuredAt: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
          changes: [
            {
              content: data.notes.trim(),
              mentionedCand:
                candidateLists.find(item => item.candidate_id === mentionedCandId)?.value || null,
              mentionedEmp:
                employeeLists.find(item => item.emp_id === mentionedEmpId)?.value || null
            },
          ],
        };

        await logger(payload);
      }

      const reqLog = await logger(payload);
      if (reqLog?.status === 1) {
        commentForm.resetFields();
        setIsLoading(false);
        getDiscussion();
      } else {
        setIsLoading(false);
      }
      // const discussionPayload = {
      //   req_id: id,
      //   from_emp_id: localStorage.getItem("current_emp_id"),
      //   to_emp_id: (mentionedEmpId.length > 0 || !!mentionedEmpId) ? mentionedEmpId : 0,
      //   comment: data.notes,
      // };

      // const saveDiscussions = await staffingUrl.post(
      //   `api/StaffMgnt/employeeDiscussionsonRequirement`,
      //   discussionPayload
      // );

      // if (saveDiscussions.data.status === 1) {
      //   setIsLoading(false);
      //   commentForm.resetFields();
      //   getDiscussion();
      //   setMentionedEmpId("");
      // } else {
      //   setIsLoading(false);
      // }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  const handleCandNotesSubmit = async (data) => {
    try {
      setIsLoading(true);
      setFilterValue('All');
      const payload = {
        candidate_id: !!id ? parseInt(id) : id,
        actionEvent: "Discussion Log",
        changedBy: username,
        username: name,
        occuredAt: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
        changes: [
          {
            content: data.notes.trim(),
            mentionedReq:
              reqLists.find(item => item.req_id === mentionedReqId)?.value || null,
            mentionedReqId,
            mentionedEmpId,
            mentionedEmp:
              employeeLists.find(item => item.emp_id === mentionedEmpId)?.value || null
          },
        ],
      };

      if (mentionedReqId !== "") {
        const payload = {
          req_id: mentionedReqId,
          actionEvent: "Discussion Log",
          changedBy: username,
          username: name,
          occuredAt: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
          changes: [
            {
              content: data.notes.trim(),
              mentionedReq:
                reqLists.find(item => item.req_id === mentionedReqId)?.value || null,
              mentionedEmp:
                employeeLists.find(item => item.emp_id === mentionedEmpId)?.value || null
            },
          ],
        };

        await logger(payload);
      }

      const candidateLog = await logger(payload);
      if (candidateLog?.status === 1) {
        commentForm.resetFields();
        setIsLoading(false);
        getDiscussion();
      } else {
        setIsLoading(false);
      }


    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  return (
    <div className="max-w-2xl mx-auto">
      <Form
        form={commentForm}
        initialValues={{
          remember: true,
        }}
        onFinish={
          from === "requirement"
            ? handleReqNotesSubmit
            : handleCandNotesSubmit
        }
        autoComplete="off"
        className="w-full"
      >
        <div className="w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
          {showSender && <MentionDropdown />}

          <div className="flex flex-wrap gap-2 p-2">
            <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-md dark:bg-gray-600">
              <span className="font-medium text-gray-600 dark:text-gray-300">
                {name.charAt(0)}
              </span>
            </div>
            <div className="px-4 py-2 bg-white rounded-t-lg dark:bg-gray-800 flex-1">
              <label htmlFor="comment" className="sr-only">
                Your comment
              </label>
              <Form.Item
                name={"notes"}
                className="w-full"
                rules={[
                  {
                    required: true,
                    message: "Please enter the notes"
                  }
                ]}
              >
                <Mentions
                  prefix={
                    from === "requirement"
                      ? ['@', '$']
                      : ['@', '#']
                  }
                  onSearch={onSearch}
                  onChange={handleChange}
                  onSelect={(value) => {
                    prefix === "@" && setMentionedEmpId(value.emp_id);
                    prefix === "#" && setMentionedReqId(value.req_id);
                    prefix === "$" && setMentionedCandId(value.candidate_id);
                  }}
                  options={
                    prefix === "@"
                      ? employeeLists
                      : prefix === "#"
                        ? reqLists
                        : candidateLists
                  }
                  className="w-full px-0 text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 focus-visible:outline-0 dark:text-white dark:placeholder-gray-400 min-h-14"
                  placeholder={
                    from === "requirement"
                      ? "Add a comment. Use @ to mention a talent person, $ to mention the candidate"
                      : "Add a comment. Use # to mention the requirement, @ to mention a talent person"
                  }
                  rows={4}
                // autoSize
                />
              </Form.Item>
            </div>
          </div>
          <div className="flex items-center justify-end px-3 py-2 border-t dark:border-gray-600">
            <Form.Item>
              <Button
                variant="contained"
                className="toolbutton"
                type="primary"
                htmlType="submit"
                disabled={isLoading}
              >
                Send
              </Button>
            </Form.Item>
            {/* <div className="flex ps-0 space-x-1 rtl:space-x-reverse sm:ps-2">
              <Button
                type="text"
                className="text-gray-500 p-2 flex justify-center items-center"
              >
                <CgAttachment size={18} />
                <span className="sr-only">Attach file</span>
              </Button>
              <Button
                type="text"
                className="text-gray-500 p-2 flex justify-center items-center"
              >
                <CgSmile size={24} />
                <span className="sr-only">Add emoji</span>
              </Button>
              <Button
                type="text"
                className="text-gray-500 p-2 flex justify-center items-center"
              >
                <CgExpand size={24} />
                <span className="sr-only">Expand Chat</span>
              </Button>
            </div> */}
          </div>
        </div >
      </Form >
    </div >
  );
}
