import React, { useEffect, useState, useRef } from "react";
import {
  Input,
  Select,
  InputNumber,
  DatePicker,
  Form,
  notification,
  Button,
  Divider,
  Space,
  Breadcrumb,
  Modal,
  Tooltip
} from "antd";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { staffingUrl } from "../interceptor";
import { useMsal } from "@azure/msal-react";
import { MdDeleteOutline, MdContentCopy, MdOutlineDoneAll } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { PlusOutlined } from '@ant-design/icons';
import { logger } from "../utils/logger";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ font: [] }],
    [{ size: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ script: "sub" }, { script: "super" }],
    [{ color: [] }, { background: [] }],
    ["link", "image"],
    ["clean"],
  ],
};

const priority = [
  {
    value: "Low",
    label: "Low",
  },
  {
    value: "Medium",
    label: "Medium",
  },
  {
    value: "High",
    label: "High",
  },
];

const work_mode = [
  {
    value: "On-Site",
    label: "On-Site",
  },
  {
    value: "Hybrid",
    label: "Hybrid",
  },
  {
    value: "Remote",
    label: "Remote",
  },
];

const options = [
  { label: "Yet to Start", value: 4 },
  { label: "Ongoing", value: 1 },
  { label: "Hold", value: 2 },
  { label: "Closed", value: 3 },
];

const initialYOE = Array.from({ length: 26 }).map(
  (_, index) => ({
    value:
      index < 2
        ? `${index} Year`
        : `${index} Years`,
    label:
      index < 2
        ? `${index} Year`
        : `${index} Years`,
    disabled: false,
  })
);

export default function Requirement() {
  const [api, contextHolder] = notification.useNotification();
  const { instance } = useMsal();
  const userName = instance.getActiveAccount()["username"];
  const name = instance.getActiveAccount()["name"];

  const { id } = useParams();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const quillRef = useRef(null);

  const [RequirementForm] = Form.useForm();
  const [addCityForm] = Form.useForm();
  const [addSkillForm] = Form.useForm();
  const [addDepartmentForm] = Form.useForm();
  const [vendorForm] = Form.useForm();
  const [editVendorForm] = Form.useForm();
  const [contactPersonEditForm] = Form.useForm();

  const [skillSet, setSkillset] = useState([]);
  const [deptName, setdeptName] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [country_code, setCountry_Code] = useState("+91");
  const [phoneNumLength, setPhoneNumLength] = useState(10);
  const [customerName, setCustomerName] = useState([]);
  const [selectedContactPerson, setSelectedContactPerson] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [cities, setCities] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAssignChanged, setIsAssignChanged] = useState(false);
  const [contactPersonList, setContactPersonList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [comparisonData, setComparisonData] = useState({});
  const [showContactTable, setShowContactTable] = useState(false)
  const [showEditVendor, setShowEditVendor] = useState(false);
  const [startValue, setStartValue] = useState(null);
  const [endOptions, setEndOptions] = useState(initialYOE);
  const [startOptions, setStartOptions] = useState(initialYOE);
  const [addContactPersonModal, setAddContactPersonModal] = useState(false);
  const [isContactModalVisible, setIsContactModalVisible] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [editVendorId, setEditVendorId] = useState(null);
  const [contactPersonId, setContactPersonId] = useState(null);
  const [enableDeleteModal, setEnableDeleteModal] = useState(false);
  const [isVendorDelete, setIsVendorDelete] = useState(false);
  const [copyLinkModal, setCopyLinkModal] = useState(false);
  const [createdReqURL, setCreatedReqURL] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [location, setLocation] = useState(localStorage.getItem('current_location') || '')


  const countryCode = ["+91", "+1", "+44", "+52", "+86", "+61"];

  const openNotificationWithIcon = (req) => {
    api[req?.color]({
      message: req?.Title,
      description: req?.description,
      duration: req?.duration,
    });
  };

  const dateCheck = (values) => {
    if (values.values.Yoe_start == undefined) {
      RequirementForm.setFields([
        {
          name: "Yoe_start",
          errors: ["Please select your year of experience"],
        },
      ]);

      return;
    }

    if (values.values.Yoe_End == undefined) {
      RequirementForm.setFields([
        {
          name: "Yoe_End",
          errors: ["Please select your year of experience"],
        },
      ]);

      return;
    }
  };

  const handleAddVendor = (values) => {
    setShowContactTable(true);
    const newVendor = {
      vendorName: values.vendorName,
      contactPerson: values.contactPerson,
      customerEmail: values.customerEmail,
      phoneNumber: values.contact,
      countryCode: country_code
    };
    setVendorList([...vendorList, newVendor]);
    setCountry_Code("+91");
    vendorForm.resetFields(['contactPerson', 'customerEmail', 'contact']);
  };

  function getLabelByValue(value) {
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === value) {
        return options[i].label;
      }
    }
    return null; // Return null if value is not found
  }

  const modifyKeys = (key) => {
    return key === "customerName"
      ? "Customer Name"
      : key === "customer_id"
        ? "Customer ID"
        : key === "customer_email"
          ? "Customer Email"
          : key === "contact"
            ? "Contact No"
            : key === "RequirementTitle"
              ? "Requirement Title"
              : key === "jobDescription"
                ? "Job Description"
                : key === "yearOfExperience"
                  ? "Year Of Experience"
                  : key === "position_closed"
                    ? "Position Closed"
                    : key === "jobDuration"
                      ? "Job Duration"
                      : key === "rateCard"
                        ? "Rate Card"
                        : key === "assign_to"
                          ? "Assigned To"
                          : key === "priority"
                            ? "Priority"
                            : key === "skill_set"
                              ? "Skill Set"
                              : key === "openings"
                                ? "Openings"
                                : key === "work_mode"
                                  ? "Work Mode"
                                  : key === "req_status"
                                    ? "Requirement Status"
                                    : key === "end_client"
                                      ? "End Client"
                                      : key === "city"
                                        ? "City"
                                        : key === "contactPerson_id"
                                          ? "Contact Person Id"
                                          : key === "contactPerson"
                                            ? "Contact Person"
                                            : key === "integrated_with"
                                              ? "Associated With"
                                              : key;
  }

  const copyText = () => {
    inputRef.current.select();
    document.execCommand('copy');
    setIsCopied(true);
    setTimeout(() => {
      setCopyLinkModal(false);
      setIsCopied(false);
      setCreatedReqURL(null);
    }, 1000);
  };

  const trimContent = () => {
    const quill = quillRef.current.editor;
    const content = quill.getText();
    const trimmedContent = content.trim();

    // Update the editor's content with the trimmed version.
    quill.setText(trimmedContent);
    RequirementForm.setFieldValue(
      "jobDescription",
      trimmedContent
    );
  };

  const handleSubmit = async (values, key) => {
    setIsLoading(true);
    let yoe = "";
    if (values.Yoe_start == undefined && values.Yoe_End == undefined) {
      RequirementForm.setFields([
        {
          name: "Yoe_start",
          errors: ["Please select your year of experience"],
        },
      ]);
      RequirementForm.setFields([
        {
          name: "Yoe_End",
          errors: ["Please select your year of experience"],
        },
      ]);
      setIsLoading(false);
      return;
    } else {
      if (values.Yoe_start != undefined && values.Yoe_End != undefined) {
        yoe = `${values.Yoe_start.split(" ")[0]}-${values.Yoe_End}`;
      } else if (values.Yoe_start != undefined) {
        yoe = `${values.Yoe_start}`;
      } else if (values.Yoe_End != undefined) {
        yoe = `${values.Yoe_End}`;
      }
    }
    let req =
      key === "add"
        ? {
          customerName: !!values?.customerName
            ? values?.customerName?.split('-')[0]
            : "",
          RequirementTitle: values?.requirementTitle,
          jobDescription: values?.jobDescription,
          yearOfExperience: yoe,
          rateCard: values?.rateCard?.toString(),
          positionClose: new Date(values?.positionClose)?.toDateString(),
          jobDuration: values?.jobDuration,
          priority: values?.priority,
          skill_set: values?.Skill_set?.toString(),
          openings: !!values?.numberofopenings
            ? parseInt(values?.numberofopenings)
            : 0,
          work_mode: values?.work_mode?.toString(),
          location: "",
          Department: values?.department,
          city: values?.city?.toString(),
          state: values?.state?.toString(),
          country: values?.country?.toString(),
          username: userName,
          customer_email: values?.customer_email,
          contact: values.contact,
          assign_to: values?.assign_to === 0
            ? 0
            : parseInt(values?.assign_to.split("-")[1]),
          req_status: values?.req_status,
          end_client: values?.end_client,
          contactPerson_id: !!values.contact_name
            ? parseInt(values.contact_name.split("-")[1])
            : 0,
          integrated_with: values.associated_with
        }
        : {
          req_id: id,
          position_closed: new Date(values?.positionClose).toDateString(),
          customerName: !!values?.customerName
            ? values?.customerName?.split('-')[0]
            : "",
          RequirementTitle: values?.requirementTitle,
          jobDescription: values?.jobDescription,
          yearOfExperience: yoe,
          rateCard: values?.rateCard?.toString(),
          jobDuration: values?.jobDuration,
          priority: values?.priority,
          skill_set: values?.Skill_set?.toString(),
          openings: parseInt(values?.numberofopenings),
          work_mode: values?.work_mode?.toString(),
          location: "",
          Department: values?.department,
          city: values?.city?.toString() || "",
          state: values?.state?.toString() || "",
          country: values?.country?.toString() || "",
          username: userName,
          customer_email: values?.customer_email,
          contact: values.contact,
          assign_to: typeof values?.assign_to === "string"
            ? parseInt(values?.assign_to.split("-")[1])
            : values?.assign_to,
          req_status: values?.req_status,
          end_client: values?.end_client,
          contactPerson_id: !!values.contact_name
            ? parseInt(values.contact_name?.split("-")[1])
            : 0,
          integrated_with: values.associated_with
        };

    await staffingUrl
      .post(
        key === "update"
          ? `api/StaffMgnt/updateRequirement`
          : `api/StaffMgnt/addNewRequirement`,
        req
      )
      .then(async (res) => {
        if (res?.data?.status === 1) {
          if (key === "add") {
            RequirementForm.resetFields();
            openNotificationWithIcon({
              color: "success",
              Title: "Success",
              description: "The Requirement is added successfully",
              duration: 2,
            });
            setCopyLinkModal(true);
            setCreatedReqURL(res.data.req_url);
            const payload = {
              req_id: res.data.req_id,
              actionEvent: "Requirement Created",
              changedBy: userName,
              username: name,
              occuredAt: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
              changes: [
                {
                  req_title: values?.requirementTitle,
                  req_priority: values?.priority,
                  req_status: values?.req_status
                }
              ]
            }
            logger(payload);
          } else {
            openNotificationWithIcon({
              color: "success",
              Title: "Success",
              description: "The Requirement is updated successfully",
              duration: 2,
            });
            setCopyLinkModal(true);
            setCreatedReqURL(res.data.req_url);
            const changes = Object.keys(comparisonData).reduce((acc, key) => {
              if (req.hasOwnProperty(key)
                && req[key] != comparisonData[key]
              ) {
                let modifiedKey = modifyKeys(key);
                if (key === "assign_to") {
                  if (req[key] === 0) {
                    req[key] = "Unassigned"
                  } else {
                    req[key] =
                      employees.find(item => parseInt(item.value.split('-')[1]) === req[key]).label;
                  }

                  if (comparisonData[key] === 0) {
                    comparisonData[key] = "Unassigned"
                  } else {
                    comparisonData[key] =
                      employees.find(item => parseInt(item.value.split('-')[1]) === comparisonData[key]).label;
                  }
                } else if (key === "req_status") {
                  req[key] = options.find(item => item.value === req[key]).label;
                  comparisonData[key] = options.find(item => item.value === comparisonData[key]).label;
                } else if (key === "contactPerson_id") {
                  req[key] = contactPersonList.find(item => item?.contactPerson_id === req[key])?.contactPerson;

                  comparisonData[key] = contactPersonList.find(item => item?.contactPerson_id === comparisonData[key])?.contactPerson;
                }

                acc.push({
                  existing_data: {
                    key: modifiedKey,
                    value: comparisonData[key] === ""
                      ? "-"
                      : comparisonData[key]
                  },
                  new_data: {
                    key: modifiedKey,
                    value: req[key] === ""
                      ? "-"
                      : req[key]
                  }
                });
              }
              return acc;
            }, []);
            const payload = {
              req_id: id,
              actionEvent: "Requirement Updated",
              changedBy: userName,
              username: name,
              occuredAt: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
              changes
            };
            if (changes.length > 0) {
              logger(payload);
            }
            await get_req_list_by_id();
          }

          if (isAssignChanged) {
            if (values?.assign_to == 0) {
              let req = {
                req_id: key === "add"
                  ? res.res.data.req_id
                  : id,
                emp_id: values?.assign_to,
                username: userName
              };
              await staffingUrl
                .post(`api/StaffMgnt/assignEmployee`, req)
                .then((res) => {
                  if (res.data.status === 1) {
                    // openNotificationWithIcon({
                    //   color: "success",
                    //   Title: "Success",
                    //   description: "Updated successfully",
                    //   duration: 2,
                    // });
                  } else {
                    // openNotificationWithIcon({
                    //   color: "warning",
                    //   Title: "Warning",
                    //   description: "Failed to update",
                    //   duration: 2,
                    // });
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              let req_status = values?.req_status;
              let req = {
                req_id: key === "add"
                  ? res.data.req_id
                  : id,
                emp_id: values?.assign_to === 0
                  ? 0
                  : parseInt(values?.assign_to.split("-")[1]),
                req_title: values?.requirementTitle,
                assigned_by: name,
                req_priority: values?.priority,
                req_status: getLabelByValue(req_status),
                req_customer: values?.customerName.split('-')[0],
                req_department: values?.department,
                assigned_username: userName
              };
              await staffingUrl
                .post(`api/StaffMgnt/assignEmployeewithReminder`, req)
                .then((res) => {
                  if (res.data.status === 1) {
                    // openNotificationWithIcon({
                    //   color: "success",
                    //   Title: "Success",
                    //   description: "Updated successfully",
                    //   duration: 2,
                    // });
                  } else {
                    // openNotificationWithIcon({
                    //   color: "warning",
                    //   Title: "Warning",
                    //   description: "Failed to update",
                    //   duration: 2,
                    // });
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            setIsAssignChanged(false);
          }
        } else {
          openNotificationWithIcon({
            color: "warning",
            Title: "Warning",
            description: "The Requirement is not updated",
            duration: 2,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  };

  const get_req_list_by_id = async () => {
    let empList;
    await staffingUrl
      .get(`api/StaffMgnt/getEmployees/0`)
      .then((res) => {
        empList = res?.data
          ?.filter((a) => a.position === 'Talent Team')
          .map((a) => ({
            value: `${a.username}-${a.Emp_id}`,
            label: a.username,
          }));
        setEmployees(empList);
      })
      .catch((err) => {
        console.log(err);
      });
    await staffingUrl
      // .get(`api/StaffMgnt/getRequirementsList/all/all`)
      .get(`api/StaffMgnt/getRequirementsList/${location}/all/all`)
      .then((res) => {
        const data = res.data.req_list;
        const filterCity = [...new Set(data.map(item =>
          item.city.includes(',')
            ? (item.city?.split(','))
            : (item.city))
          .filter(item => item)
          .flat())
        ].sort();

        setCities(filterCity);
      })
      .catch((err) => {
        console.log(err);
      });
    await staffingUrl
      .get(`api/StaffMgnt/getRequirementbyID?id=${id}`)
      .then(async (res) => {
        let test = res?.data?.Yoe.split("-");
        let yoe_start = undefined;
        let yoe_end = undefined;
        if (test.length == 2) {
          yoe_start = `${res?.data?.Yoe.split("-")[0]} ${parseInt(res?.data?.Yoe.split("-")[0]) < 2 ? "Year" : "Years"
            }`;
          yoe_end = `${res?.data?.Yoe.split("-")[1].split(" ")[0]} ${parseInt(res?.data?.Yoe.split("-")[1].split(" ")[0]) < 2
            ? "Year"
            : "Years"
            }`;
        } else {
          yoe_start = `${res?.data?.Yoe.split(" ")[0]} ${parseInt(res?.data?.Yoe.split("-")[0]) < 2 ? "Year" : "Years"
            }`;
        }

        if (!!res?.data?.customer_id) {
          await staffingUrl
            .post('api/StaffMgnt/clientContactListing',
              {
                customer_id: res?.data?.customer_id
              }
            )
            .then((res) => {
              setContactPersonList(res.data.contact_persons);
            })
            .catch(err => { console.log(err) });
        }
        RequirementForm.setFieldValue("customerName", res?.data?.Customer_name);
        RequirementForm.setFieldValue(
          "requirementTitle",
          res?.data?.Requirement_title
        );
        RequirementForm.setFieldValue("Yoe_start", yoe_start);
        RequirementForm.setFieldValue("Yoe_End", yoe_end);
        RequirementForm.setFieldValue("rateCard", res?.data?.Rate_card);
        RequirementForm.setFieldValue(
          "positionClose",
          dayjs(res?.data?.Postion_closed)
        );
        RequirementForm.setFieldValue("jobDuration", res?.data?.Job_duration);
        RequirementForm.setFieldValue(
          "jobDescription",
          res?.data?.Job_description
        );
        RequirementForm.setFieldValue("contact_name",
          `${res?.data?.contactPerson}-${res?.data?.contactPersonId}`
        );
        setSelectedVendor(res?.data?.customer_id);
        setEditVendorId(res?.data?.customer_id);
        setSelectedContactPerson(res?.data?.contactPersonId);
        RequirementForm.setFieldValue("customer_email", res?.data?.customer_email);
        RequirementForm.setFieldValue("contact", res?.data?.contact);
        setCountry_Code(countryCode.filter(item => res?.data?.contact?.startsWith(item))[0] || countryCode[0])
        RequirementForm.setFieldValue("priority", res?.data?.priority);
        RequirementForm.setFieldValue(
          "Skill_set",
          res?.data?.skill_set.split(",")
            ? res?.data?.skill_set.split(",")?.map((a) => a)
            : res?.data?.skill_set
        );
        RequirementForm.setFieldValue("numberofopenings", res?.data?.openings);
        RequirementForm.setFieldValue(
          "work_mode",
          res?.data?.work_mode.split(",")
            ? res?.data?.work_mode.split(",").map((a) => a)
            : res?.data?.work_mode
        );
        // RequirementForm.setFieldValue("location", res?.data?.location.split(",") ? res?.data?.location.split(",").map(a => a) : res?.data?.location)
        RequirementForm.setFieldValue("associated_with", res?.data?.integrated_with)
        RequirementForm.setFieldValue("department", res?.data?.Department);
        const assignedEmp = empList.find(item => parseInt(item.value.split("-")[1]) === res?.data?.assigned_to)?.value || 0;
        RequirementForm.setFieldValue("assign_to", assignedEmp);
        RequirementForm.setFieldValue("end_client", res?.data?.end_client);
        RequirementForm.setFieldValue("req_status", res?.data?.req_status);
        RequirementForm.setFieldValue(
          "country",
          res?.data?.country
            ? res?.data?.country?.split(",").map((a) => a)
            : undefined
        );
        RequirementForm.setFieldValue(
          "state",
          res?.data?.state
            ? res?.data?.state?.split(",").map((a) => a)
            : undefined
        );
        RequirementForm.setFieldValue(
          "city",
          res?.data?.city
            ? res?.data?.city?.split(",").map((a) => a)
            : undefined
        );
        const transformedObj = Object.fromEntries(Object.entries(res.data).map(([key, value]) => {
          switch (key) {
            case "Requirement_id":
              return ["req_id", value];
            case "Customer_name":
              return ["customerName", value];
            case "Requirement_title":
              return ["RequirementTitle", value];
            case "Job_description":
              return ["jobDescription", value];
            case "Yoe":
              return ["yearOfExperience", `${value}`];
            case "Postion_closed":
              return ["position_closed", new Date(value).toDateString()];
            case "Rate_card":
              return ["rateCard", value];
            case "Job_duration":
              return ["jobDuration", value];
            case "assigned_to":
              return ["assign_to", value];
            case "contactPersonId":
              return ["contactPerson_id", value];
            case "associated_with":
              return ["integrated_with", value]
            default:
              return [key, value];
          }
        }));
        setComparisonData(transformedObj);
      })
      .catch((err) => console.log(err));
  };

  const getCustomer = async () => {
    await staffingUrl
      .get(`api/StaffMgnt/getcustomer`)
      .then((res) => {
        setCustomerName(res.data);
      })
      .catch((err) => console.error(err));
  };

  const getSkillset = async () => {
    await staffingUrl
      .get(`api/StaffMgnt/getSkillset`)
      .then((res) => {
        setSkillset(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getdepartmentlist = async () => {
    await staffingUrl
      .get(`api/StaffMgnt/getDepartmentlist`)
      .then((res) => {
        setdeptName(res.data);
      })
      .catch((err) => console.log(err));
  };

  const addSkill = async (value) => {
    await staffingUrl
      .post(
        `api/StaffMgnt/addSkill?skill=${value.skillName}&username=${userName}`
      )
      .then((res) => {
        if (res.data.status == 1) {
          openNotificationWithIcon({
            color: "success",
            Title: "Success",
            description: "The skill Added successfully",
            duration: 2,
          });
          addSkillForm.resetFields();
          getSkillset();
        } else if (res.data.status == 2) {
          addSkillForm.resetFields();
          openNotificationWithIcon({
            color: "warning",
            Title: "Warning",
            description: `${value.skillName} is already exist`,
            duration: 2,
          });
        } else {
          addSkillForm.resetFields();
          openNotificationWithIcon({
            color: "warning",
            Title: "Warning",
            description: "The skill is not Added",
            duration: 2,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  const addDepartment = async (value) => {
    await staffingUrl
      .post(
        `api/StaffMgnt/addDepartment?dept=${value.departmentName.toUpperCase()}&username=${userName}`
      )
      .then((res) => {
        if (res.data.status == 1) {
          openNotificationWithIcon({
            color: "success",
            Title: "Success",
            description: "The department added successfully",
            duration: 2,
          });
          addDepartmentForm.resetFields();
          getdepartmentlist();
        } else if (res.data.status == 2) {
          addDepartmentForm.resetFields();
          openNotificationWithIcon({
            color: "warning",
            Title: "Warning",
            description: `${value.departmentName} is already exist`,
            duration: 2,
          });
        } else {
          addDepartmentForm.resetFields();
          openNotificationWithIcon({
            color: "warning",
            Title: "Warning",
            description: "The department is not Added",
            duration: 2,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  const handleModalOk = async () => {
    if (vendorList.length > 0) {
      setIsLoading(true);
      const payload = {
        customer: vendorList[vendorList.length - 1].vendorName,
        customer_id: 0,
        username: userName,
        contactPersonList: vendorList.map((item) => {
          return {
            contactPerson: item.contactPerson,
            email: item.customerEmail,
            contact_no: `${item.countryCode} ${item.phoneNumber}`,
            contactPerson_id: 0
          }
        })
      }

      await staffingUrl
        .post('api/StaffMgnt/addCustomer', payload)
        .then((res) => {
          if (res.data.status === 1) {
            openNotificationWithIcon({
              color: "success",
              Title: "Success",
              description: "The customer is added successfully",
              duration: 2,
            });
          } else {
            openNotificationWithIcon({
              color: "warning",
              Title: "Warning",
              description: res.data.msg,
              duration: 2,
            });

          }
          getCustomer();
          setIsModalVisible(false);
          setIsLoading(false)
          vendorForm.resetFields();
          setVendorList([]);
          setShowContactTable(false);
          setIsLoading(false);
        })
        .catch(err => {
          openNotificationWithIcon({
            color: "warning",
            Title: "Warning",
            description: "Failed to add customer",
            duration: 2,
          });
          setIsModalVisible(false);
          vendorForm.resetFields();
          setVendorList([]);
          setShowContactTable(false);
          setIsLoading(false);
          console.log(err);
        });
    }
  }

  const handleOk = () => {
    editVendorForm.submit();
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setShowContactTable(false);
    setVendorList([]);
    vendorForm.resetFields();
    editVendorForm.resetFields();
  };

  const addNewCustomer = (menu) => (
    <>
      {menu}
      <Divider
        style={{
          margin: "8px 0",
        }}
      />
      <Space
        style={{
          padding: "0 8px 4px", width: '100%', justifyContent: 'end'
        }}
      >
        <div
          style={{
            display: "flex",
            // gap: "10px",
            alignItems: 'center',
            justifyContent: "center"
          }}
        >
          <Button onClick={handleAddVendorModal} icon={<PlusOutlined />}>
            Add
          </Button>
        </div>
      </Space>
    </>
  );

  const addNewContactPerson = (menu) => (
    <>
      {menu}
      <Divider
        style={{
          margin: "8px 0",
        }}
      />
      <Space
        style={{
          padding: "0 8px 4px", width: '100%', justifyContent: 'end'
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: 'center',
            justifyContent: "center"
          }}
        >
          <Button onClick={handleAddContactDetails} icon={<PlusOutlined />}>
            Add
          </Button>
        </div>
      </Space>
    </>
  );

  const handleAddCity = (values) => {
    const { city } = values;
    setCities(prevState => ([...prevState, city]));
    openNotificationWithIcon({
      color: "success",
      Title: "Success",
      description: "City added successfully",
      duration: 2,
    });
    addCityForm.resetFields();
  }

  const cityValidator = async (rule, value) => {
    const searchedCity = addCityForm.getFieldValue('city')
    if (cities.some(obj => obj?.trim()?.toLowerCase() === searchedCity?.trim()?.toLowerCase())) {
      throw new Error('City already exists');
    }
  };

  const addNewCity = (menu) => (
    <>
      {menu}
      <Divider
        style={{
          margin: "8px 0",
        }}
      />
      <Space
        style={{
          padding: "0 8px 4px",
        }}
      >
        <Form form={addCityForm} onFinish={handleAddCity}>
          <Form.Item
            name="city"
            rules={[
              {
                required: true,
                message: "Please enter the city",
              },
              {
                validator: cityValidator,
              },
              {
                pattern: /^[a-zA-Z\s]+$/,
                message: "Please enter valid name"
              }
            ]}
            normalize={(value) => value.trim()}
            validateTrigger={"onBlur"}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Input
                placeholder="Please enter item"
                className="w-full"
                maxLength={20}
                size="middle"
                onKeyDown={(e) => e.stopPropagation()}
              />
              <Button htmlType="submit" icon={<PlusOutlined />}>
                Add city
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Space>
    </>
  );

  const addNewSkill = (menu) => (
    <>
      {menu}
      <Divider
        style={{
          margin: "8px 0",
        }}
      />
      <Space
        style={{
          padding: "0 8px 4px",
        }}
      >
        <Form form={addSkillForm} onFinish={addSkill}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              gap: "10px",
            }}
          >
            <Form.Item
              name="skillName"
              rules={[
                {
                  required: true,
                  message: "Please enter name of the skill",
                },
                // {
                //   pattern: /^[ a-zA-Z0-9]*$/,
                //   message: "Please enter valid skill"
                // }
              ]}
              normalize={(value) => value.trim()}
              validateTrigger={"onBlur"}
            >
              <Input
                placeholder="Please enter item"
                className="w-full"
                size="middle"
                onKeyDown={(e) => e.stopPropagation()}
              />
            </Form.Item>
            <Button htmlType="submit" icon={<PlusOutlined />}>
              Add skill
            </Button>
          </div>
        </Form>
      </Space>
    </>
  );

  const addNewDepartment = (menu) => (
    <>
      {menu}
      <Divider
        style={{
          margin: "8px 0",
        }}
      />
      <Space
        style={{
          padding: "0 8px 4px",
        }}
      >
        <Form form={addDepartmentForm} onFinish={addDepartment}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              gap: "10px",
            }}
          >
            <Form.Item
              name="departmentName"
              rules={[
                {
                  required: true,
                  message: "Please enter name of the department",
                },
                // {
                //   pattern: /^[ a-zA-Z0-9]*$/,
                //   message: "Please enter valid name"
                // },
                {
                  validator: (rule, value) => {
                    return new Promise((resolve, reject) => {
                      const findDept = deptName.find(
                        (item) => item.Name.toLowerCase() === value.toLowerCase()
                      )
                      if (findDept) {
                        reject('Department already exists');
                      } else {
                        resolve();
                      }
                    })
                  }
                }
              ]}
              normalize={(value) => value.trim()}
            // validateTrigger={"onBlur"}
            >
              <Input
                placeholder="Please enter item"
                className="w-full"
                size="middle"
                maxLength={25}
              // onKeyDown={(e) => {
              //   // e.stopPropagation();
              //   if (/\d/.test(e.key) || /[^a-zA-Z ]/.test(e.key)) {
              //     e.preventDefault();
              //   }
              // }}
              />
            </Form.Item>
            <Button htmlType="submit" icon={<PlusOutlined />}>
              Add department
            </Button>
          </div>
        </Form>
      </Space>
    </>
  );

  const handleRateCardKeyDown = event => {
    const allowedKeys = /[0-9.]/
    const specialKeys = [
      'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'
    ];

    if (!allowedKeys.test(event.key) && !specialKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  const handleKeyDown = (event) => {
    // const allowedKeys = /[\d\-()+\s]/;
    const allowedKeys = /[0-9]/;
    const specialKeys = [
      'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'
    ];

    if (!allowedKeys.test(event.key) && !specialKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const handleContactPerson = (value) => {
    const filterValue = parseInt(value.split("-")[1]);
    const filterPerson = contactPersonList.filter(item => filterValue === item.contactPerson_id)[0];
    setSelectedContactPerson(filterValue);
    RequirementForm.setFieldValue('customer_email', filterPerson.email)
    RequirementForm.setFieldValue('contact', filterPerson.contact_no)
  }

  const handleVendorChange = async (value) => {
    const val = typeof value === "string"
      ? parseInt(value.split('-')[1])
      : value;

    const payload = {
      customer_id: val
    }
    setEditVendorId(val);
    setSelectedVendor(val);
    await staffingUrl
      .post('api/StaffMgnt/clientContactListing', payload)
      .then((res) => {
        setContactPersonList(res.data.contact_persons);
        if (selectedContactPerson) {
          const updatedInfo = res.data.contact_persons.filter(item => item.contactPerson_id === selectedContactPerson)[0];

          if (val !== editVendorId || !updatedInfo) {
            RequirementForm.setFieldValue("contact_name", null);
            RequirementForm.setFieldValue("customer_email", null);
            RequirementForm.setFieldValue("contact", null);
          } else if (updatedInfo) {
            RequirementForm.setFieldValue("contact_name", updatedInfo.contactPerson);
            RequirementForm.setFieldValue("customer_email", updatedInfo.email);
            RequirementForm.setFieldValue("contact", updatedInfo.contact_no);
          }
        }
      })
      .catch(err => { console.log(err) });
  }

  const handleEditVendorModal = (value) => {
    setIsModalVisible(true);
    setShowEditVendor(true);
    setEditVendorId(value.sno);
    editVendorForm.setFieldValue("vendorname", value.customer1);
  }

  const handleAddVendorModal = () => {
    setIsModalVisible(true);
    setShowEditVendor(false);
  }

  const customValidator = async (rule, value) => {
    if (customerName.some(obj => obj?.customer1?.trim()?.toLowerCase() === value?.trim()?.toLowerCase())) {
      throw new Error('User already exists');
    }
  };

  const editCustomValidator = async (rule, value) => {
    const normalizedValue = value.trim().toLowerCase();
    const customerIndex = customerName.findIndex(obj =>
      typeof obj.customer1 === 'string' && obj?.customer1?.trim()?.toLowerCase() === normalizedValue
    );
    if (customerIndex !== -1) {
      if (editVendorId === null || customerName[customerIndex].sno !== editVendorId) {
        throw new Error('User already exists');
      }
    }
  };

  const handleStartChange = (value) => {
    RequirementForm.setFieldValue("Yoe_End", null);
    const selectedValue = parseInt(value);
    setStartValue(selectedValue);
    setEndOptions(prevState => {
      return prevState.map((item, index) => ({
        ...item,
        disabled: index + 1 <= selectedValue + 1
      }))
    });
  };

  const labelRender = (props) => {
    return props.value.split("-")[0]
  }

  const labelRenderCP = (props) => {
    return props.value.split("-")[0];
  }

  const handleEditContactPerson = (value) => {
    setIsContactModalVisible(true);
    setContactPersonId(value);

    const selectedItem = contactPersonList.find(item => item.contactPerson_id === value);
    console.log(selectedItem?.contactPerson);

    const currentCode = countryCode.filter(item => selectedItem?.contact_no.startsWith(item))[0];
    const currentNum = selectedItem?.contact_no.startsWith("undefined")
      ? ""
      : selectedItem?.contact_no.split(
        countryCode.filter(item => selectedItem?.contact_no.startsWith(item))[0]);

    contactPersonEditForm.setFieldValue("contactPersonName", selectedItem?.contactPerson);
    contactPersonEditForm.setFieldValue('contactPersonEmail', selectedItem?.email);
    // contactPersonEditForm.setFieldValue('contactPersonPhone', selectedItem?.contact_no);
    contactPersonEditForm.setFieldValue("contactPersonPhone",
      currentNum.length === 2 ? currentNum[1] : currentNum[0]
    );


    setCountry_Code(
      (!!currentCode && !currentCode.startsWith("undefined"))
        ? currentCode
        : "+91"
    );
  }

  const handleCPModalCancel = () => {
    setIsContactModalVisible(false);
    setContactPersonId(null);
    setAddContactPersonModal(false);
    setCountry_Code("+91");
    contactPersonEditForm.resetFields();
  }

  const handleEditCPModalSubmit = () => {
    contactPersonEditForm.submit();
  }

  const handleAddContactDetails = () => {
    setIsContactModalVisible(true);
    setAddContactPersonModal(true);
  }

  const handleVendorEditSubmit = async (values) => {
    try {
      setIsLoading(true);
      const payload = {
        "customer_id": editVendorId,
        "customer_new_name": values.vendorname?.trim()
      }
      const editVendorName = await staffingUrl.post(`api/StaffMgnt/CustomerNameUpdate`, payload);

      if (editVendorName.data.status === 1) {
        openNotificationWithIcon({
          color: "success",
          Title: "Success",
          description: "Vendor name changed successfully",
          duration: 2,
        });
        await staffingUrl
          .get(`api/StaffMgnt/getcustomer`)
          .then((res) => {
            setCustomerName(res.data);
            if (selectedVendor === editVendorId) {
              const cust_id = res.data.find(item => item.sno === selectedVendor);

              RequirementForm.setFieldValue("customerName", `${cust_id.customer1}-${cust_id.sno}`);
            }
          })
          .catch((err) => console.error(err));
      } else {
        openNotificationWithIcon({
          color: "warning",
          Title: "Warning",
          description: "Failed to update",
          duration: 2,
        });
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon({
        color: "warning",
        Title: "Warning",
        description: "Failed to update",
        duration: 2,
      });
      setEditVendorId(null);
    } finally {
      setIsLoading(false);
      handleModalCancel();
    }
  }

  const handleCPEditSubmit = async (values) => {
    try {
      setIsLoading(true);
      const customer = customerName.find(item => item.sno === editVendorId).customer1;

      let payload;

      if (addContactPersonModal) {
        payload = {
          customer,
          customer_id: editVendorId,
          username: userName,
          contactPersonList: [{
            contactPerson: values.contactPersonName,
            email: values.contactPersonEmail,
            contact_no: `${country_code}${values.contactPersonPhone}`,
            contactPerson_id: 0
          }]
        }
      } else {
        payload = {
          customer,
          customer_id: editVendorId,
          username: userName,
          contactPersonList: [
            {
              "contactPerson": values.contactPersonName,
              "email": values.contactPersonEmail,
              "contact_no": `${country_code}${values.contactPersonPhone}`,
              "contactPerson_id": contactPersonId
            }
          ]
        }
      }

      const editCPName = await staffingUrl.post('api/StaffMgnt/addCustomer', payload);

      if (editCPName.data.status === 1) {
        openNotificationWithIcon({
          color: "success",
          Title: "Success",
          description: "The customer is added successfully",
          duration: 2,
        });
        await staffingUrl
          .post('api/StaffMgnt/clientContactListing', {
            customer_id: editVendorId
          })
          .then((res) => {
            setContactPersonList(res.data.contact_persons);
          })
          .catch(err => { console.log(err) });
      } else if (editCPName.data.status === 2) {
        openNotificationWithIcon({
          color: "success",
          Title: "Success",
          description: "Contact details changed successfully",
          duration: 2,
        });

        await staffingUrl
          .post('api/StaffMgnt/clientContactListing',
            { customer_id: editVendorId }
          )
          .then((res) => {
            setContactPersonList(res.data.contact_persons);
            const updatedInfo = res.data.contact_persons.filter(item => item.contactPerson_id === selectedContactPerson)[0];

            RequirementForm.setFieldValue("contact_name", `${updatedInfo.contactPerson}-${updatedInfo.contactPerson_id}`);
            RequirementForm.setFieldValue("customer_email", updatedInfo.email);
            RequirementForm.setFieldValue("contact", updatedInfo.contact_no);
          })
          .catch(err => { console.log(err) });
      } else {
        openNotificationWithIcon({
          color: "warning",
          Title: "Warning",
          description: editCPName.data.msg,
          duration: 2,
        });
      }
    } catch (err) {
      console.log(err);
      openNotificationWithIcon({
        color: "warning",
        Title: "Warning",
        description: "Failed to update",
        duration: 2,
      });
    } finally {
      setIsLoading(false);
      handleCPModalCancel();
    }
  }

  const handleCPEditFormChanges = (values) => {
    if (values?.contactPersonName?.length === 1 && values.contactPersonName) {
      contactPersonEditForm.setFieldValue('contactPersonName', values?.contactPersonName?.trim())
    }
    if (values?.contactPersonEmail?.length === 1 && values.contactPersonEmail) {
      contactPersonEditForm.setFieldValue('contactPersonEmail', values?.contactPersonEmail?.trim())
    }
  }

  const handleVendorDeleteModal = (e, item) => {
    e.stopPropagation();
    setEnableDeleteModal(true);
    setIsVendorDelete(true);
    setEditVendorId(item.sno);
  }

  const handleContactDeleteModal = (e, item) => {
    e.stopPropagation();
    setEnableDeleteModal(true);
    setIsVendorDelete(false);
    setContactPersonId(item);
  }

  const handleVendorDeleteSubmit = async () => {
    try {
      setIsLoading(true);
      const payload = {
        "customer_id": editVendorId
      };

      const vendorDelete = await staffingUrl.post('api/StaffMgnt/deleteCustomer', payload);
      if (vendorDelete.data.status === 1) {
        openNotificationWithIcon({
          color: "success",
          Title: "Success",
          description: "Vendor deleted successfully",
          duration: 2,
        });

        await staffingUrl
          .get(`api/StaffMgnt/getcustomer`)
          .then((res) => {
            setCustomerName(res.data);
          })
          .catch((err) => console.error(err));
      } else {
        openNotificationWithIcon({
          color: "warning",
          Title: "Warning",
          description: "Failed to delete",
          duration: 2,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setEnableDeleteModal(false);
    }
  }

  const handleCPDeleteSubmit = async () => {
    try {
      setIsLoading(true);
      const payload = {
        username: userName,
        contactPerson_id: contactPersonId
      };

      const contactPersonDelete = await staffingUrl.post('api/StaffMgnt/deleteClientContact', payload);

      if (contactPersonDelete.data.status === 1) {
        openNotificationWithIcon({
          color: "success",
          Title: "Success",
          description: "Contact details deleted successfully",
          duration: 2,
        });

        await staffingUrl
          .post('api/StaffMgnt/clientContactListing',
            { customer_id: editVendorId }
          )
          .then((res) => {
            setContactPersonList(res.data.contact_persons);

            const updatedInfo = res.data.contact_persons.filter(item => item.contactPerson_id === selectedContactPerson)[0];

            if (!updatedInfo) {
              RequirementForm.setFieldValue("contact_name", null);
              RequirementForm.setFieldValue("customer_email", null);
              RequirementForm.setFieldValue("contact", null);
            }
          })
          .catch(err => { console.log(err) });
      } else {
        openNotificationWithIcon({
          color: "warning",
          Title: "Warning",
          description: "Failed to delete",
          duration: 2,
        });
      }
    } catch (err) {
      console.log(err);
      openNotificationWithIcon({
        color: "warning",
        Title: "Warning",
        description: "Failed to delete",
        duration: 2,
      });
    } finally {
      setIsLoading(false);
      setEnableDeleteModal(false);
    }
  }

  const handleVendorFormValuesChange = (values) => {
    if (values?.vendorName?.length === 1 && values.vendorName) {
      vendorForm.setFieldValue('vendorName', values?.vendorName?.trim())
    } if (values?.contactPerson?.length == 1 && values.contactPerson) {
      vendorForm.setFieldValue('contactPerson', values?.contactPerson?.trim())
    } if (values?.customerEmail?.length === 1 && values.customerEmail) {
      vendorForm.setFieldValue('customerEmail', values?.customerEmail?.trim())
    }
  }

  const handleRequirementFormChange = (values) => {
    if (values?.requirementTitle?.length === 1 && values.requirementTitle) {
      RequirementForm.setFieldValue('requirementTitle', values?.requirementTitle?.trim())
    } if (values?.end_client?.length === 1 && values.end_client) {
      RequirementForm.setFieldValue('end_client', values?.end_client?.trim())
    }
  }

  const getData = async () => {
    await staffingUrl
      // .get(`api/StaffMgnt/getRequirementsList/all/all`)
      .get(`api/StaffMgnt/getRequirementsList/${location}/all/all`)
      .then((res) => {
        const data = res.data.req_list;
        const filterCity = [...new Set(data.map(item =>
          item.city.includes(',')
            ? (item.city?.split(','))
            : (item.city))
          .filter(item => item)
          .flat())
        ].sort();

        setCities(filterCity);
      })
      .catch((err) => {
        console.log(err);
      });

    await staffingUrl
      .get(`api/StaffMgnt/getEmployees/0`)
      .then((res) => {
        let empList = res?.data
          ?.filter((a) => a.position === 'Talent Team')
          .map((a) => ({
            value: `${a.username}-${a.Emp_id}`,
            label: a.username,
          }));
        setEmployees(empList);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getSkillset();
    getdepartmentlist();
    getCustomer();
    getData();
    if (id) {
      get_req_list_by_id();
    }
  }, []);

  useEffect(() => {
    if (
      country_code === "+91"
      || country_code === "+61"
      || country_code === "+1"
      || country_code === "+44"
      || country_code === "+52"
    ) {
      setPhoneNumLength(10);
    } else if (country_code === "+86") {
      setPhoneNumLength(11);
    }
  }, [country_code]);

  useEffect(()=> {
    getData();
    get_req_list_by_id();
  },[location])

  useEffect(() => {
    const onLocationChange = () => {
      setLocation(localStorage.getItem('current_location') || '');
    };
    window.addEventListener('locationChanged', onLocationChange);

    return () => {
      window.removeEventListener('locationChanged', onLocationChange);
    };
  }, []);

  return (
    <>
      {contextHolder}
      <Grid className="mb-3">
        <Breadcrumb
          items={[
            {
              title: (
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    navigate("/requirementdashboard");
                  }}
                >
                  Requirement Dashboard
                </span>
              ),
            },
            {
              title: "Create Requirement",
            },
          ]}
        />
      </Grid>
      <div>
        <div className="text-left">
          <h4 className="text-[#6c5295] text-lg font-medium">Requirement</h4>
        </div>
        <Box className="tab-section" sx={{ width: "100%" }}>
          <CustomTabPanel className="CustomTabPanel" value={0} index={0}>
            <div
              style={{
                border: "1px solid #c3c3c7",
                borderRadius: "10px",
                marginTop: "20px",
              }}
            >
              <Form
                layout="vertical"
                onFinish={(values) => {
                  handleSubmit(values, id ? "update" : "add");
                }}
                onFinishFailed={dateCheck}
                form={RequirementForm}
                onValuesChange={handleRequirementFormChange}
                className="w-full px-2 py-2"
              >
                <div className="md:flex md:flex-wrap py-2">
                  <div className="md:flex md:gap-2 md:w-1/2 px-3 mb-6 md:mb-0">
                    <div className="md:w-1/2">
                      <Form.Item
                        name="requirementTitle"
                        label="Job Title"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your job title.",
                          },
                          // {
                          //   pattern: /^[a-zA-Z\s]+$/,
                          //   message: "Please enter valid name"
                          // }
                        ]}
                        validateTrigger={"onBlur"}
                      >
                        <Input
                          name="requirementTitle"
                          className="w-full h-10"
                          // size="large"
                          placeholder="Enter your job title"
                          maxLength={30}
                        />
                      </Form.Item>
                    </div>
                    <div className="md:w-1/2 mb-6 md:mb-0">
                      <Form.Item
                        name="customerName"
                        label="Vendor Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your customer name.",
                          },
                        ]}
                      >
                        <Select
                          name="customerName"
                          className="w-full"
                          size="large"
                          placeholder="Enter vendor name"
                          showSearch
                          dropdownRender={addNewCustomer}
                          labelRender={labelRender}
                          onSelect={handleVendorChange}
                          onInput={(e) => {
                            e.target.value = e.target.value.substring(0, 25);
                          }}
                        >
                          {!!customerName && customerName.map(item => (
                            <Select.Option
                              key={item.sno}
                              value={`${item.customer1}-${item.sno}`}
                              label={item.customer1}
                            >
                              <div
                                className="flex justify-between items-center"
                              >
                                <span className="inline-block w-60 text-ellipsis overflow-hidden">
                                  {item.customer1}
                                </span>
                                <div className="flex gap-2 items-center">
                                  <span>
                                    <CiEdit
                                      color="green"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleEditVendorModal(item);
                                      }}
                                    />
                                  </span>
                                  {/* <span>
                                    <MdDeleteOutline
                                      color="red"
                                      onClick={e => handleVendorDeleteModal(e, item)}
                                    />
                                  </span> */}
                                </div>
                              </div>
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="md:flex md:gap-2 px-3 md:w-1/2 mb-6 md:mb-0">
                    <div className="md:w-full mb-6 md:mb-0">
                      <Form.Item
                        name="contact_name"
                        label="Contact Person"
                      >
                        <Select
                          name="contactperson"
                          className="w-full"
                          size="large"
                          placeholder="Enter contact person"
                          onChange={handleContactPerson}
                          showSearch
                          dropdownRender={addNewContactPerson}
                          labelRender={labelRenderCP}
                          disabled={editVendorId === null}
                        >
                          {!!contactPersonList && contactPersonList.map(item => (
                            <Select.Option
                              key={item.contactPerson_id}
                              value={`${item.contactPerson}-${item.contactPerson_id}`}
                              label={item.contactPerson}
                            >
                              <div className="flex justify-between items-center">
                                <span>{item.contactPerson}</span>
                                <div className="flex gap-2 items-center">
                                  <span>
                                    <CiEdit
                                      color="green"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleEditContactPerson(item.contactPerson_id);
                                      }}
                                    />
                                  </span>
                                  <span>
                                    <MdDeleteOutline
                                      color="red"
                                      onClick={e => handleContactDeleteModal(e, item.contactPerson_id)}
                                    />
                                  </span>
                                </div>
                              </div>
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="md:w-full items-center">
                      <Form.Item
                        name="customer_email"
                        label="Contact Email"
                        rules={[
                          // {
                          //   required: true,
                          //   message: "Please enter your customer email",
                          // },
                          {
                            type: 'email',
                            message: "Please enter valid email",
                          }
                        ]}
                      >
                        <Input
                          name="email"
                          className="w-full h-10"
                          // size="large"
                          placeholder="Enter customer email"
                          disabled
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="md:flex md:flex-wrap py-2">
                  <div className="md:flex md:gap-2 md:w-1/2 px-3 mb-6 md:mb-0">
                    <div className="md:w-1/2 mb-6 md:mb-0">
                      <Form.Item
                        name="contact"
                        label="Phone Number"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please enter your Phone Number",
                      //   },
                      //   {
                      //     pattern: /^[\d\-()+\s]+$/,
                      //     message: "Invalid phone number format",
                      //   },
                      // ]}
                      >
                        <Input
                          className="w-full"
                          size="large"
                          // maxLength={phoneNumLength}
                          maxLength={17}
                          onKeyDown={handleKeyDown}
                          disabled
                          // addonBefore={
                          //   <Select
                          //     defaultValue="+91"
                          //     style={{ width: 70 }}
                          //     options={countryCode.map((a) => {
                          //       return {
                          //         value: a,
                          //         lable: a,
                          //       };
                          //     })}
                          //     onChange={e => setCountry_Code(e)}
                          //     value={country_code}
                          //     disabled
                          //   />
                          // }
                          placeholder="Enter your Phone Number"
                        />
                      </Form.Item>
                    </div>
                    <div className="md:w-1/2">
                      <Form.Item
                        name="positionClose"
                        label="Position Closed"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your position closed.",
                          },
                        ]}
                      >
                        <DatePicker
                          className="w-full"
                          size="large"
                          minDate={dayjs(new Date())}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="md:flex md:gap-2 md:w-1/2 px-3 mb-6 md:mb-0">
                    <div className="w-full items-center">
                      <Form.Item
                        name="numberofopenings"
                        label="Number of Openings"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your number of openings",
                          },
                        ]}
                      >
                        <InputNumber
                          controls={false}
                          className="w-full"
                          size="large"
                          placeholder="Number of Openings"
                          maxLength={3}
                          min={1}
                          max={999}
                          onKeyDown={handleKeyDown}
                        />
                      </Form.Item>
                    </div>
                    <div className="w-full items-center">
                      <Form.Item
                        name="work_mode"
                        label="Work Mode"
                        rules={[
                          {
                            required: true,
                            message: "Please select your work mode",
                          },
                        ]}
                      >
                        <Select
                          //mode="multiple"
                          className="w-full"
                          size="large"
                          placeholder="Select Work Mode"
                          options={work_mode}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="md:flex md:flex-wrap py-2">
                  <div className="md:w-1/2 px-3 items-center">
                    <Form.Item
                      name="Skill_set"
                      label="Skill Set"
                      rules={[
                        {
                          required: true,
                          message: "Please select your skill set",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        size="large"
                        className="w-full"
                        placeholder="Select Skill Set"
                        dropdownRender={addNewSkill}
                        onInput={(e) => {
                          e.target.value = e.target.value.substring(0, 25);
                        }}
                        options={skillSet.map((item) => ({
                          value: item.skill,
                          label: item.skill,
                        }))}
                        onDropdownVisibleChange={e => !e && addSkillForm.setFieldValue('skillName', null)}
                      />
                    </Form.Item>
                  </div>
                  <div className="md:flex md:gap-2 md:w-1/2 px-3 mb-6 md:mb-0">
                    <div className="w-full items-center mb-6 md:mb-0">
                      <Form.Item
                        name="jobDuration"
                        label="Job Duration"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your job duration",
                          },
                        ]}
                      >
                        <Select
                          className="w-full"
                          size="large"
                          placeholder="Select Job Duration"
                          options={Array.from({ length: 6 }).map(
                            (_, index) => ({
                              value:
                                index === 0
                                  ? "6 Month"
                                  : index === 5
                                    ? "Permanent"
                                    : `${index} Year`,
                              label:
                                index === 0
                                  ? "6 Month"
                                  : index === 5
                                    ? "Permanent"
                                    : `${index} Year`,
                            })
                          )}
                        />
                      </Form.Item>
                    </div>
                    <div className="w-full items-center">
                      <Form.Item
                        name="priority"
                        label="Priority"
                        rules={[
                          {
                            required: true,
                            message: "Please select the priority type",
                          },
                        ]}
                      >
                        <Select
                          className="w-full"
                          size="large"
                          placeholder="Select Priority"
                          options={priority}
                        />
                      </Form.Item>
                    </div>
                    <div className="w-full items-center">
                      <Form.Item
                        name="associated_with"
                        label="Associated With"
                        rules={[
                          {
                            required: true,
                            message: "Please select the associate",
                          },
                        ]}
                      >
                        <Select
                          className="w-full"
                          size="large"
                          placeholder="Select Associate"
                          options={[
                            {
                              value: "JETZ",
                              label: "JETZ"
                            },
                            {
                              value: "RCK",
                              label: "RCK"
                            },
                          ]}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="md:flex md:flex-wrap py-2">
                  <div className="md:flex md:gap-2 md:w-1/2 px-3 mb-6 md:mb-0">
                    <div className="w-full items-center">
                      <Form.Item label="Years of Experience" required>
                        <div className="flex gap-2 items-center">
                          <div className="w-full">
                            <Form.Item
                              name="Yoe_start"
                              requiredMark={true}
                              rules={[
                                {
                                  message:
                                    "Please select your year of experience",
                                },
                              ]}
                              style={{ marginBottom: 0 }}
                            >
                              <Select
                                allowClear={true}
                                className="w-full"
                                size="large"
                                placeholder="From"
                                options={startOptions}
                                onChange={handleStartChange}
                              />
                            </Form.Item>
                          </div>
                          <div className="w-full">
                            <Form.Item
                              name="Yoe_End"
                              rules={[
                                {
                                  message:
                                    "Please select your year of experience",
                                },
                              ]}
                              style={{ marginBottom: 0 }}
                            >
                              <Select
                                allowClear={true}
                                className="w-full"
                                size="large"
                                placeholder="To"
                                options={endOptions}
                                disabled={!startValue && startValue !== 0}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="md:flex md:gap-2 md:w-1/2 px-3 mb-6 md:mb-0">
                    <div className="w-full items-center mb-6 md:mb-0">
                      <Form.Item
                        name="rateCard"
                        label="Rate Card"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your rate card.",
                          },
                        ]}
                      >
                        <InputNumber
                          controls={false}
                          className="w-full"
                          size="large"
                          placeholder="Enter your rate card"
                          suffix="LPA"
                          maxLength={4}
                          min={1}
                          max={999}
                          onKeyDown={handleRateCardKeyDown}
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          } // Formats the value as currency
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")} // Parses the value to remove currency symbols and commas
                        />
                      </Form.Item>
                    </div>
                    <div className="w-full items-center">
                      <Form.Item
                        name="department"
                        label="Department"
                        rules={[
                          {
                            required: false,
                            message: "Please select your department.",
                          },
                        ]}
                      >
                        <Select
                          className="w-full"
                          size="large"
                          placeholder="Select Department"
                          dropdownRender={addNewDepartment}
                          options={deptName.map((item) => ({
                            value: item.Name,
                            label: item.Name,
                          }))}
                          showSearch
                          onDropdownVisibleChange={e => !e && addDepartmentForm.setFieldValue('departmentName', null)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="md:flex md:flex-wrap py-2">
                  <div className="md:flex md:gap-2 md:w-1/2 px-3 mb-6 md:mb-0">
                    <div className="w-full items-center">
                      <Form.Item
                        name="city"
                        label="Location"
                        rules={[
                          {
                            required: false,
                            message: "Please select a location",
                          },
                        ]}
                      >
                        <Select
                          className="w-full"
                          size="large"
                          mode="multiple"
                          dropdownRender={addNewCity}
                          placeholder="City"
                          onInput={(e) => {
                            e.target.value = e.target.value.substring(0, 25);
                          }}
                          onDropdownVisibleChange={(e) => !e && addCityForm.resetFields()}
                          options={cities.map(item => ({
                            label: item,
                            value: item
                          }))}
                        />
                      </Form.Item>
                    </div>
                    <div className="w-full items-center">
                      <Form.Item
                        name="end_client"
                        label="End Client"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please select the end client",
                      //   },
                      // ]}
                      >
                        {/* <Select
                          className="w-full"
                          size="large"
                          // mode="multiple"
                          dropdownRender={addNewCustomer}
                          showSearch
                          placeholder="End Client"
                          options={customerName?.map((item) => ({
                            label: item.customer1,
                            value: item.customer1,
                          }))}
                        /> */}
                        <Input
                          className="w-full h-10"
                          // size="large"
                          maxLength={25}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="md:flex md:gap-2 md:w-1/2 px-3 mb-6 md:mb-0">
                    <div className="md:w-1/2 mb-6 md:mb-0">
                      <Form.Item
                        name="assign_to"
                        label="Assign To"
                        rules={[
                          {
                            required: true,
                            message: "Please select the assignee",
                          },
                        ]}
                      >
                        <Select
                          name="assign_to"
                          className="w-full"
                          size="large"
                          placeholder="Select the assignee"
                          showSearch
                          options={[
                            ...employees,
                            { label: "Unassign", value: 0 },
                          ]}
                          onChange={() => setIsAssignChanged(true)}
                        />
                      </Form.Item>
                    </div>
                    <div className="md:w-1/2 mb-6 md:mb-0">
                      <Form.Item
                        name="req_status"
                        label="Requirement Status"
                        rules={[
                          {
                            required: true,
                            message: "Please select the status",
                          },
                        ]}
                      >
                        <Select
                          name="req_status"
                          className="w-full"
                          size="large"
                          placeholder="Select the status"
                          showSearch
                          options={options}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="md:flex md:flex-wrap py-2">
                  <div className="md:w-full px-3 mb-6 md:mb-0">
                    <div>
                      <Form.Item
                        name="jobDescription"
                        label="Job Description"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your job description.",
                          },
                        ]}
                      >
                        <ReactQuill
                          ref={quillRef}
                          modules={modules}
                          onChange={() => {
                            RequirementForm.getFieldValue("jobDescription") ===
                              "<p><br></p>" &&
                              RequirementForm.setFieldValue(
                                "jobDescription",
                                undefined
                              );
                          }}
                          onBlur={trimContent}
                          theme="snow"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center mb-5 gap-3">
                  <Form.Item>
                    <Button
                      className="btn save-btn"
                      type="primary"
                      htmlType="submit"
                      disabled={isLoading}
                      loading={isLoading}
                      iconPosition={"start"}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                  <button
                    className="btn close-btn"
                    onClick={() => navigate("/requirementdashboard")}
                  >
                    Back
                  </button>
                </div>
              </Form>
            </div>
          </CustomTabPanel>
        </Box>
      </div>
      <Modal
        className="custom-modal"
        title={"Add Vendor"}
        visible={isModalVisible && !showEditVendor}
        onCancel={handleModalCancel}
        onOk={handleModalOk}
        footer={[
          <Button
            key="submit"
            className="btn save-btn"
            onClick={handleModalOk}
            loading={isLoading}
          >
            Save
          </Button>,
          <Button
            key="cancel"
            className="btn save-btn"
            onClick={handleModalCancel}
          >
            Cancel
          </Button>,
        ]}
        width={700}
      >
        <Form
          form={vendorForm}
          onFinish={handleAddVendor}
          onValuesChange={handleVendorFormValuesChange}
        >
          <div className="grid grid-cols-2 gap-2">
            <Form.Item className="custom-formItem"
              name="vendorName"
              label="Vendor Name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please enter your vendor name",
                },
                {
                  validator: customValidator
                }
              ]}
            >
              <Input
                maxLength={25}
                onKeyDown={(e) => {
                  if (/\d/.test(e.key) || /[^a-zA-Z ]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item className="custom-formItem"
              name="contactPerson"
              label="Contact Person"
              style={{ marginRight: '10px', flex: 1 }}
              rules={[
                {
                  required: true,
                  message: "Enter contact person name",
                },
                {
                  pattern: /^[a-zA-Z\s]+$/,
                  message: "Invalid contact person name",
                }
              ]}
            >
              <Input
                maxLength={20}
                minLength={3}
                onKeyDown={(e) => {
                  if (/\d/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <Form.Item className="custom-formItem"
              name="customerEmail"
              label="Contact Email"
              style={{ marginRight: '10px', flex: 1 }}
              rules={[
                {
                  required: true,
                  message: "Enter the customer email",
                },
                {
                  type: "email",
                  message: "Enter valid email address",
                },
                {
                  validator: (rule, value) => {
                    return new Promise((resolve, reject) => {
                      const findMail = vendorList.find(
                        (item) => item.customerEmail === value
                      );
                      if (findMail) {
                        reject('User already exists');
                      } else {
                        resolve();
                      }
                    });
                  },
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item className="custom-formItem"
              name="contact"
              label="Phone Number"
              style={{ marginRight: '10px', flex: 1 }}
              rules={[
                {
                  required: true,
                  message: "Please enter your Phone Number",
                },
                {
                  pattern: /^[\d\-()+\s]+$/,
                  message: "Invalid phone number format",
                },
                // {
                //   min: phoneNumLength,
                //   message: "Invalid phone number format",
                // },
                {
                  max: 17,
                  message: "Invalid phone number",
                }
              ]}
            >
              <Input
                className="w-full"
                // maxLength={phoneNumLength}
                maxLength={17}
                addonBefore={
                  <Select
                    defaultValue="+91"
                    style={{ width: 70 }}
                    options={countryCode.map((a) => {
                      return {
                        value: a,
                        lable: a,
                      };
                    })}
                    onChange={e => setCountry_Code(e)}
                    value={country_code}
                  />
                }
                onKeyDown={handleKeyDown}
              />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-4 mr-3 mb-4">
            <Button
              htmlType="submit"
              className="btn save-btn"
              icon={<PlusOutlined />}
            >
              Add
            </Button>
          </div>
          {showContactTable &&
            <div className="mr-3">
              <table className="table-grid">
                <thead>
                  <tr>
                    <th>Contact Person</th>
                    <th>Contact Email</th>
                    <th>Phone Number</th>
                    {/* <th>Actions</th> */}
                  </tr>
                </thead>
                <tbody>
                  {vendorList.map((vendor, index) => (
                    <tr key={index}>
                      <td>{vendor.editing ? (
                        <Form.Item
                          name={`contactPerson_${index}`}
                          initialValue={vendor.contactPerson}
                          rules={[{
                            required: true,
                            message: 'Please enter contact person'
                          },
                          {
                            pattern: /^[a-zA-Z\s]*$/,
                            message: 'Please enter a valid name'
                          }
                          ]}
                        >
                          <Input
                            disabled={!vendor.editing}
                            maxLength={20} minLength={3}
                            onKeyDown={(e) => {
                              if (/\d/.test(e.key)) { // Check if the pressed key is a number
                                e.preventDefault(); // Prevent input of numbers
                              }
                            }}
                          />
                        </Form.Item>) : (
                        vendor.contactPerson
                      )}</td>
                      <td>{vendor.editing ? (
                        <Form.Item
                          name={`customerEmail${index}`}
                          initialValue={vendor.customerEmail}
                          rules={[{ required: true, type: 'email', message: 'Please enter a valid email' }]}
                        >
                          <Input
                            disabled={!vendor.editing}
                          />
                        </Form.Item>
                      ) : (
                        vendor.customerEmail
                      )}</td>
                      <td>{vendor.editing ? (
                        <>
                          <Form.Item
                            name={`phoneNumber${index}`}
                            initialValue={vendor.phoneNumber.replace(/^\+\d+\s/, '')}
                            style={{ marginRight: '10px', flex: 1 }}
                            rules={[
                              {
                                required: true,
                                message: "Please enter your Phone Number",
                              },
                              {
                                pattern: /^[\d\-()+\s]+$/,
                                message: "Invalid phone number format",
                              },
                              {
                                max: 17,
                                message: "Invalid phone number",
                              },
                              // {
                              //   min: phoneNumLength,
                              //   message: "Invalid phone number",
                              // }
                            ]}
                          >
                            <Input
                              // maxLength={phoneNumLength}
                              maxLength={17}
                              disabled={!vendor.editing}
                              // addonBefore={
                              //   <Select
                              //     defaultValue="+91"
                              //     style={{ width: 70 }}
                              //     options={countryCode.map((a) => {
                              //       return {
                              //         value: a,
                              //         lable: a,
                              //       };
                              //     })}
                              //     onChange={e => setCountry_Code(e)}
                              //     value={country_code}
                              //   />
                              // }
                              onKeyDown={(e) => {
                                if ((e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 97 && e.keyCode <= 122)) {
                                  e.preventDefault();
                                } if (e.keyCode === 32) { // keyCode for spacebar
                                  e.preventDefault();
                                }
                              }}
                              placeholder="Enter your Phone Number"
                            />
                          </Form.Item>
                        </>
                      ) : (
                        `${vendor.countryCode || "+91"}  ${vendor.phoneNumber}`
                      )}</td>
                      {/* <td>
                              {vendor.editing ? (
                                <>
                                  <div className="flex items-center gap-2 justify-center">
                                    <Tooltip title="Save" placement="bottom">
                                      <Button
                                        icon={<DoneIcon />}
                                        style={{
                                          color: "#00FF00",
                                          fontSize: "18px"
                                        }}
                                        onClick={() => handleSave(index)}
                                      // disabled={!enableSaveButton}
                                      />
                                    </Tooltip>
                                    <Tooltip title="Close" placement="bottom">
                                      <Button
                                        icon={<CloseIcon />}
                                        style={{
                                          color: "#FF0000",
                                          fontSize: "18px"
                                        }}
                                        onClick={() => handleCancel(index)}
                                      />
                                    </Tooltip>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="flex items-center gap-2 justify-center">
                                    <Tooltip title="Edit" placement="bottom">
                                      <Button icon={<MdEditNote />} style={{ color: "#3366FF", fontSize: "18px" }}
                                        onClick={() => handleEdit(index)} />
                                    </Tooltip>
                                    <Tooltip title="Delete" placement="bottom">
                                      <Button icon={<MdDelete />} style={{ color: "#FF3333", fontSize: "18px" }}
                                        onClick={() => handleDelete(index)} />
                                    </Tooltip>
                                  </div>

                                </>
                              )}
                            </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }
        </Form>
      </Modal>
      <Modal
        className="custom-modal"
        title={"Edit Vendor"}
        visible={isModalVisible && showEditVendor}
        onCancel={handleModalCancel}
        onOk={handleOk}
        footer={[
          <Button
            key="submit"
            className="btn save-btn"
            onClick={handleOk}
            loading={isLoading}
          >
            Save
          </Button>,
          <Button
            key="cancel"
            className="btn save-btn"
            onClick={handleModalCancel}
          >
            Cancel
          </Button>,
        ]}
        width={700}
      >
        <Form form={editVendorForm} onFinish={handleVendorEditSubmit}>
          <div className="flex items-center justify-between">
            <Form.Item style={{ width: '50%' }}
              name="vendorname"
              label="Vendor Name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Enter your vendor name",
                },
                {
                  validator: editCustomValidator
                }
              ]}
            >
              <Input maxLength={25} />
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <Modal
        className="custom-modal"
        title={addContactPersonModal
          ? "Add Contact Person"
          : "Edit Contact Person"
        }
        width={700}
        visible={isContactModalVisible}
        onCancel={handleCPModalCancel}
        footer={[
          <Button
            htmlType="submit"
            className="btn save-btn"
            onClick={handleEditCPModalSubmit}
            loading={isLoading}
          >
            Save
          </Button>,
          <Button
            key="cancel"
            className="btn save-btn"
            onClick={handleCPModalCancel}
          >
            Cancel
          </Button>,
        ]}
      >
        <Form
          form={contactPersonEditForm}
          onFinish={handleCPEditSubmit}
          onValuesChange={handleCPEditFormChanges}
        >
          <div className="font-semibold">
            Vendor :
            <span className="pl-2">
              {RequirementForm.getFieldValue("customerName")?.split("-")[0]}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingBottom: '25px',
              marginTop: '1rem'
            }}
          >
            <Form.Item className="custom-formItem"
              name="contactPersonName"
              label="Contact Person"
              style={{ marginRight: '10px', flex: 1 }}
              rules={[
                {
                  required: true,
                  message: "Enter contact person name",
                  pattern: /^[a-zA-Z\s]+$/,
                },
              ]}
            >
              <Input
                maxLength={20}
                minLength={3}
              />
            </Form.Item>
            <Form.Item className="custom-formItem"
              name="contactPersonEmail"
              label="Contact Email"
              style={{ marginRight: '10px', flex: 1 }}
              rules={[
                {
                  required: true,
                  message: "Enter the customer email",
                },
                {
                  type: "email",
                  message: "Enter valid email address.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item className="custom-formItem"
              name="contactPersonPhone"
              label="Phone Number"
              style={{ marginRight: '10px' }}
              rules={[
                {
                  required: true,
                  message: "Please enter your phone number",
                },
                {
                  pattern: /^[\d\-()+\s]+$/,
                  message: "Invalid phone number format",
                },
                {
                  max: 17,
                  message: "Invalid phone number",
                },
                // {
                //   min: phoneNumLength,
                //   message: "Invalid phone number",
                // }
              ]}
            >
              <Input
                className="w-full"
                // maxLength={phoneNumLength}
                maxLength={17}
                onKeyDown={handleKeyDown}
                addonBefore={
                  <Select
                    defaultValue="+91"
                    style={{ width: 70 }}
                    options={countryCode.map((a) => {
                      return {
                        value: a,
                        lable: a,
                      };
                    })}
                    onChange={e => setCountry_Code(e)}
                    value={country_code}
                  />
                }
                placeholder="Enter your Phone Number"
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <Modal
        title={isVendorDelete ? " Delete Vendor" : "Delete Contact Person"}
        visible={enableDeleteModal}
        onCancel={() => { setEnableDeleteModal(false) }}
        onOk={isVendorDelete ? handleVendorDeleteSubmit : handleCPDeleteSubmit}
        footer={[
          <Button
            key="submit"
            className="btn save-btn"
            loading={isLoading}
            onClick={
              isVendorDelete
                ? handleVendorDeleteSubmit
                : handleCPDeleteSubmit
            }
          >
            Delete
          </Button>,
          <Button
            key="cancel"
            className="btn save-btn"
            onClick={() => { setEnableDeleteModal(false) }}
          >
            Cancel
          </Button>
        ]}
        width={350}
      >
        <div className="text-sm  pt-2 pb-4">
          <span> Do you want delete this {isVendorDelete ? "Vendor" : "Contact Person"} ?</span>
        </div>
      </Modal>
      <Modal
        title={"Copy Link"}
        visible={copyLinkModal}
        onCancel={() => {
          setCopyLinkModal(false);
          setCreatedReqURL(null);
          setIsCopied(false);
        }}
        footer={[]}
      >
        <div className="flex gap-2 pt-7">
          <Input
            ref={inputRef}
            type="text"
            // value={"Hello Sabari"}
            readOnly
            className="border-0 border-b-2"
            value={createdReqURL}
          />
          <Tooltip
            title={isCopied ? "Copied" : "Copy"}
            placement="bottom"
          >
            <Button
              icon={isCopied ? <MdOutlineDoneAll /> : <MdContentCopy />}
              onClick={copyText}
              className="flex items-center save-btn"
            >
            </Button>
          </Tooltip>
        </div>
      </Modal>
    </>
  );
}
