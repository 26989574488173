import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Dashboardlayout } from "./dashboard/Dashboardlayout";
import { Dashboard } from "./dashboard/Dashboard";
import { Signin } from "./Signin/Signin";
import { useEffect, useState } from "react";
import Requirement from "./dashboard/Requirement";
import Candidate from "./dashboard/Candidate";
import RequirementInfo from "./dashboard/RequirementInfo";
import CandidateDashboard from "./dashboard/CandidateDasboard";
import EmployeeForm from "./dashboard/EmployeeForm";
import RequirementDashboard from "./dashboard/RequirementDashboard";
import CandidateReport from "./dashboard/CandidateReport";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { staffingUrl } from "./interceptor";
import TaskBoard from "./dashboard/TaskBoard";
import EmployeeDashboard from "./dashboard/EmployeeDashboard";
import CandidateInfo from "./dashboard/CandidateInfo";
import Email from "./dashboard/Email";
import DailyTracker from "./dashboard/DailyTracker";

function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [isRegistered, setIsRegistered] = useState(false);
  const [empPosition, setEmpPosition] = useState(null);

  const GetEmployeeDetails = async (email) => {
    await staffingUrl
      .get(`api/StaffMgnt/getEmployeeDetails/${email}/`)
      .then((res) => {
        if (res.data?.msg === "Employee doesn't exist") {
          // navigate("/employee");
          setIsRegistered(false);
        } else if (
          res.data?.employeeDetails?.phone_number === null ||
          res.data?.employeeDetails?.phone_number === "null" ||
          res.data?.employeeDetails?.username === null ||
          res.data?.employeeDetails?.username === "null"
        ) {
          setIsRegistered(false);
        } else {
          if (res.data?.employeeDetails?.status == 0) {
            setIsRegistered(false);
            instance.logoutRedirect();
          } else {
            setIsRegistered(true);
            localStorage.setItem(
              "current_emp_id",
              res.data?.employeeDetails?.Emp_id
            );
            localStorage.setItem("current_emp_position",res?.data?.employeeDetails?.position)
            setEmpPosition(res?.data?.employeeDetails?.position);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (isAuthenticated) {
      const { username } = instance?.getActiveAccount();
      GetEmployeeDetails(username);
    }
  }, [isAuthenticated]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Signin
                isAuthenticated={isAuthenticated}
                setIsRegistered={setIsRegistered}
              />
            }
          />
          <Route
            element={
              <Dashboardlayout
                isRegistered={isRegistered}
                empPosition={empPosition}
              />
            }
          >
            {isRegistered && (
              <>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/requirementdashboard"
                  element={<RequirementDashboard />}
                />
                <Route path="/requirement" element={<Requirement />} />
                <Route path="/requirement/:id" element={<Requirement />} />
                <Route path="/candidate" element={<Candidate />} />
                <Route
                  path="/requirement/info/:id"
                  element={<RequirementInfo />}
                />
                <Route
                  path="/candidatedashboard"
                  element={<CandidateDashboard />}
                />
                <Route path="/reports" element={<CandidateReport />} />
                <Route
                  path="/CandidateInfo/info/:id"
                  element={<CandidateInfo />}
                />
                <Route path="/candidatedashboard/:id" element={<Candidate />} />
                <Route path="/email" element={<Email />} />
                {(empPosition === "Talent Team" || empPosition === "Management Team") && (
                  <Route path="/taskboard" element={<TaskBoard />} />
                )}
                <Route
                  path="/employee/:id"
                  element={
                    <EmployeeForm
                      setIsRegistered={setIsRegistered}
                      setEmpPosition={setEmpPosition}
                    />
                  }
                />
                {empPosition === "Management Team" && (
                  <Route
                    path="/employeedashboard"
                    element={<EmployeeDashboard />}
                  />
                )}
                {empPosition === "Management Team" && (
                  <Route
                    path="/dailyTracker"
                    element={<DailyTracker />}
                  />
                )}
                {/* <Route
                  path="/employee/:id"
                  element={<EmployeeForm setEmpPosition={setEmpPosition} />}
                /> */}
              </>
            )}
            <Route
              path="/employee"
              element={
                <EmployeeForm
                  setEmpPosition={setEmpPosition}
                  setIsRegistered={setIsRegistered}
                />
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

const Loader = () => {
  return (
    <div className="flex h-screen items-center justify-center bg-white">
      <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div>
    </div>
  );
};
