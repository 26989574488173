import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Form, Select, DatePicker, Input } from "antd";
import Draggable from "react-draggable";
import { staffingUrl } from "../../interceptor";
import dayjs from "dayjs";
import { logger } from "../../utils/logger";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import moment from 'moment';


const { TextArea } = Input;

const Scheduler = ({
  id,
  openNotificationWithIcon,
  open,
  setOpen,
  handlefilterLog,
  setFilterValue
}) => {
  const { instance } = useMsal();
  const userName = instance.getActiveAccount()["username"];
  const name = instance.getActiveAccount()["name"];

  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [activityForm] = Form.useForm();

  const draggleRef = useRef(null);

  const disabledDate = current => current && current < moment().startOf('day');

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = (e) => {
    console.log(e);
    setOpen(false);
  };

  const handleCancel = (e) => {
    activityForm.resetFields();
    setOpen(false);
  };

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const handleActivitySubmit = async (value) => {
    try {
      setIsLoading(true);
      const payload = {
        req_id: id,
        activity_assignedto: value.assign_to,
        activity_type: value.activity_type,
        activity_duedate: dayjs(value.due_date).format("DD-MM-YYYY"),
        activity_description: value.feedback,
        activity_assignedby: id,
      }
      const createNewTask = await staffingUrl.post(`api/StaffMgnt/assignTasktoEmployee`, payload);

      if (createNewTask.data?.status === 1) {
        openNotificationWithIcon({
          color: 'success',
          Title: 'Success',
          description: "Task created successfully",
          duration: 2
        });
        activityForm.resetFields();

        const employeeName = employees.map(item => {
          if (item.value === value.assign_to) {
            return item.label
          }
        }).filter(item => item)[0];
        const logPayload = {
          req_id: id,
          actionEvent: "Task Created",
          changedBy: userName,
          username: name,
          occuredAt: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
          changes: [
            {
              content: `The ${value.activity_type} task is created by ${name} and it is assigned to ${employeeName}`,
              activity_assignedto: value.assign_to,
              activity_type: value.activity_type,
              activity_duedate: dayjs(value.due_date).format("DD-MM-YYYY"),
              activity_description: value.feedback
            }
          ]
        }
        logger(logPayload);
        handlefilterLog(0, "all");
        setFilterValue('All');
      } else {
        openNotificationWithIcon({
          color: "warning",
          Title: "Warning",
          description: "Failed to create task",
          duration: 2,
        });
        activityForm.resetFields();
      }
      setOpen(false);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  const getEmployeeDetails = async () => {
    await staffingUrl
      .get(`api/StaffMgnt/getEmployees/0`)
      .then((res) => {
        setEmployees(
          res?.data
            ?.filter((a) => a.position === 'Talent Team')
            .map((a) => ({
              value: a.Emp_id,
              label: a.username,
            }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getEmployeeDetails();
  }, [])

  return (
    <>
      <Button className="activity-btn" onClick={showModal}>Activities</Button>
      <Modal className="custom-modal"
        width={800}
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            // fix eslintjsx-a11y/mouse-events-have-key-events
            // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            Schedule Activity
          </div>
        }
        footer={[
          // <Button
          //   key="schedule"
          //   type="primary"
          //   variant="contained"
          //   className="toolbutton"
          //   onClick={handleOk}
          // >
          //   Save
          // </Button>,

          // <Button
          //   key="back"
          //   type="primary"
          //   variant="contained"
          //   className="toolbutton"
          //   onClick={handleCancel}
          // >
          //   Cancel
          // </Button>,
        ]}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <Form
          form={activityForm}
          onFinish={handleActivitySubmit}
          labelCol={{
            flex: "100px",
          }}
          labelAlign="left"
          labelWrap
          wrapperCol={{
            flex: 1,
          }}
          colon={false}
        // className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-6"
        >
          <div className="grid grid-cols-3 gap-5">
            <Form.Item className="custom-formItem"
              label="Activity Type"
              name={"activity_type"}
              rules={[
                {
                  required: true,
                  message: "Please select the activity type",
                },
              ]}
            >
              <Select>
                <Select.Option value="email">Email</Select.Option>
                <Select.Option value="call">Call</Select.Option>
                {/* <Select.Option value="meeting">Meeting</Select.Option> */}
                {/* <Select.Option value="upsell">Order Upsell</Select.Option> */}
                {/* <Select.Option value="toDo">To-Do</Select.Option> */}
                <Select.Option value="reminder">Reminder</Select.Option>
                <Select.Option value="document">Upload Document</Select.Option>
                {/* <Select.Option value="signature">Request Signature</Select.Option> */}
                {/* <Select.Option value="more">Search More</Select.Option> */}
              </Select>
            </Form.Item>
            <Form.Item className="custom-formItem"
              label="Due Date"
              name={"due_date"}
              rules={[
                {
                  required: true,
                  message: "Please select the due date",
                },
              ]}
            >
              <DatePicker className="w-full" disabledDate={disabledDate} />
            </Form.Item>
            <Form.Item className="custom-formItem"
              label="Assigned to"
              name={"assign_to"}
              rules={[
                {
                  required: true,
                  message: "Please select the assignee",
                },
              ]}
            >
              <Select
                options={employees}
              />
            </Form.Item>
          </div>
          <div className="mt-3">
            <Form.Item className="custom-formItem"
              label="Feedback"
              name={"feedback"}
            >
              <TextArea
                className="col-span-2 min-h-16"
                rows={6}
                placeholder="Your Message"
              />
            </Form.Item>
          </div>
          <div className="col-span-2 mt-5">
            <div className="flex justify-end gap-2">
              <Button
                htmlType="submit"
                className="toolbutton"
                loading={isLoading}
              >
                Submit
              </Button>
              <Button
                htmlType="button"
                className="toolbutton"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default Scheduler;
