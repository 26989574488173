import React, { useEffect, useState, useRef } from "react";
import {
  Input,
  Tabs,
  Table,
  Checkbox,
  Modal,
  Select,
  Dropdown,
  Menu,
  Tag,
  Upload,
  Button,
  Space,
  DatePicker,
} from "antd";
import { RiAttachment2 } from "react-icons/ri";
import {
  IoSearchOutline,
  IoMailOpenOutline,
  IoStarOutline,
  IoStar,
  IoClose,
} from "react-icons/io5";
import {
  MdOutlineMarkEmailUnread,
  MdOutlineArchive,
  MdOutlineMail,
  MdOutlineDeleteOutline,
  MdOutlineLocalPrintshop,
  MdScheduleSend,
  MdOutlineInbox,
  MdOutlineEditNote,
  MdDelete,
} from "react-icons/md";
import {
  PiArrowBendUpLeftBold,
  PiArrowBendUpRightBold,
  PiArrowBendDoubleUpLeftBold,
  PiTelegramLogo,
} from "react-icons/pi";
import {
  FolderOpenOutlined,
} from "@ant-design/icons";
import { VscSend } from "react-icons/vsc";
import { IoMdArrowDropdown } from "react-icons/io";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useMsal } from "@azure/msal-react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { staffingUrl } from "../interceptor";
import { Replay } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
dayjs.extend(utc)

const { TabPane } = Tabs;

const initialDateRange = {
  startDate: null,
  endDate: null,
};

const Email = () => {
  const { instance } = useMsal();
  const { RangePicker } = DatePicker;
  const userName = instance.getActiveAccount()["username"];

  const inputRef = useRef(null);
  const tabRef = useRef("1");

  const [visible, setVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allMails, setAllMails] = useState([]);
  const [selectedSentRow, setSelectedSentRow] = useState(null);
  const [selectedInboxRow, setSelectedInboxRow] = useState(null);
  const [selectedDraftRow, setSelectedDraftRow] = useState(null);
  const [selectedTrashRow, setSelectedTrashRow] = useState(null);
  const [selectedDate, setSelectedDate] = useState("thisWeek");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [inboxItems, setInboxItems] = useState([]);
  const [backupInboxItems, setbackupInboxItems] = useState([]);
  const [sentItems, setSentItems] = useState([]);
  const [backupSentItems, setBackupSentItems] = useState([]);
  const [draftItems, setDraftItems] = useState([]);
  const [backupDraftItems, setBackupDraftItems] = useState([]);
  const [trashItems, setTrashItems] = useState([]);
  const [backupTrashItems, setBackupTrashItems] = useState([]);
  const [inboxItemsResponseData, setInboxItemsResponseData] = useState([]);
  const [sentItemsResponseData, setSentItemsResponseData] = useState([]);
  const [draftItemsResponseData, setDraftItemsResponseData] = useState([]);
  const [trashItemsResponseData, setTrashItemsResponseData] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [starredState, setStarredState] = useState({});
  const [messageData, setMessageData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [toInputValue, setToInputValue] = useState("");
  const [ccInputValue, setCcInputValue] = useState("");
  const [bccInputValue, setBccInputValue] = useState("");
  const [selectedToUsers, setSelectedToUsers] = useState([]);
  const [selectedCCUsers, setSelectedCCUsers] = useState([]);
  const [selectedBCCUsers, setSelectedBCCUsers] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [fromField, setFromField] = useState(null);
  const [sentSubjectInput, setSentSubjectInput] = useState("");
  const [sentDescription, setSentDescription] = useState("");
  const [sentAttachments, setSentAttachments] = useState([]);
  const [tabValue, setTabValue] = useState("1");
  const [dateRange, setDateRange] = useState(initialDateRange);
  const [searchField, setSearchField] = useState("");
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [replyOption, setReplyOption] = useState(null);
  const [attachmentList, setAttachmentList] = useState(null);
  const [messageId, setMessageId] = useState(null);
  const [resumeBlob, setResumeBlob] = useState([]);
  const [resumeTemplate, setResumeTemplate] = useState([]);
  const [selectedResumes,setSelectedResumes] = useState([]);

  const locations = useLocation();

  const { selectedRows } = locations.state || {};

  const isValidEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const dotCount = (email.match(/\./g) || []).length;
    return re.test(email) && dotCount === 1;
  };

  const toggleModalVisibility = (from, item) => {
    setIsModalVisible(!isModalVisible);
    setMessageId(item.id);
    setReplyOption(from);
    if (from === 1) {
      const e = {
        target: {
          value: `${item.from.emailAddress.address},`
        }
      }
      handleInputChange(e, 'to');
      setSentSubjectInput(item.subject);
    } else if (from === 2) {
      const toEvent = {
        target: {
          value: `${item.from.emailAddress.address},`
        }
      }
      handleInputChange(toEvent, 'to');

      item.toRecipients.length > 0 && item.toRecipients.forEach(item2 => {
        if (item2.emailAddress.address !== userName) {
          const toEvent = {
            target: {
              value: `${item2.emailAddress.address},`
            }
          };

          handleInputChange(toEvent, 'to');
        }
      });

      item.ccRecipients.length > 0 && item.ccRecipients.forEach(item2 => {
        if (item2.emailAddress.address !== userName) {
          const ccEvent = {
            target: {
              value: `${item2.emailAddress.address},`
            }
          };

          handleInputChange(ccEvent, 'cc');
        }
      });

      item.bccRecipients.length > 0 && item.bccRecipients.forEach(item2 => {
        const ccEvent = {
          target: {
            value: `${item2.emailAddress.address},`
          }
        };

        handleInputChange(ccEvent, 'bcc');
      });
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchField(value);
    if (value !== "") {
      if (tabValue === "1") {
        const filterItems = backupInboxItems.filter(
          (item) =>
            item.date.includes(value) ||
            item.sender.toLowerCase().includes(value.toLowerCase()) ||
            item.subject.toLowerCase().includes(value.toLowerCase())
        );
        setInboxItems(filterItems);
      } else if (tabValue === "2") {
        const filterItems = backupSentItems.filter(
          (item) =>
            item.date.includes(value) ||
            item.receiver.toLowerCase().includes(value.toLowerCase()) ||
            item.subject.toLowerCase().includes(value.toLowerCase())
        );
        setSentItems(filterItems);
      } else if (tabValue === "3") {
        const filterItems = backupDraftItems.filter(
          (item) =>
            item.date.includes(value) ||
            item.receiver.toLowerCase().includes(value.toLowerCase()) ||
            item.subject.toLowerCase().includes(value.toLowerCase())
        );
        setDraftItems(filterItems);
      } else if (tabValue === "4") {
        const filterItems = backupTrashItems.filter(
          (item) =>
            item.date.includes(value) ||
            (!!item.sender &&
              item.sender.toLowerCase().includes(value.toLowerCase())) ||
            item.subject.toLowerCase().includes(value.toLowerCase())
        );
        setTrashItems(filterItems);
      }
    } else {
      if (tabValue === "1") {
        setInboxItems(backupInboxItems);
      } else if (tabValue === "2") {
        setSentItems(backupSentItems);
      } else if (tabValue === "3") {
        setDraftItems(backupDraftItems);
      } else if (tabValue === "4") {
        setTrashItems(backupTrashItems);
      }
    }
  };

  const handleSelect = (user, from) => {
    if (from === "to") {
      if (
        !selectedToUsers.some(
          (selectedUser) =>
            selectedUser.value?.toLowerCase() === user.value.toLowerCase()
        )
      ) {
        setSelectedToUsers((prevState) => [...prevState, user]);
      }
    } else if (from === "cc") {
      if (
        !selectedCCUsers.some(
          (selectedUser) => selectedUser.value === user.value
        )
      ) {
        setSelectedCCUsers((prevState) => [...prevState, user]);
      }
    } else if (from === "bcc") {
      if (
        !selectedBCCUsers.some(
          (selectedUser) => selectedUser.value === user.value
        )
      ) {
        setSelectedBCCUsers((prevState) => [...prevState, user]);
      }
    }
    setToInputValue("");
    setCcInputValue("");
    setBccInputValue("");
    setSuggestions([]);
    setHighlightedIndex(-1);
    setFromField(null);
  };

  const handleInputChange = (e, from) => {
    setFromField(from);
    const value = e.target?.value;

    from === "to" && setToInputValue(value);
    from === "cc" && setCcInputValue(value);
    from === "bcc" && setBccInputValue(value);

    const separators = /[,;\s]/;
    if (value.length > 0 && separators.test(value.slice(-1))) {
      const email = value.slice(0, -1).trim();
      if (isValidEmail(email)) {
        handleSelect({ label: email, value: email }, from);
        from === "to" && setToInputValue("");
        from === "cc" && setCcInputValue("");
        from === "bcc" && setBccInputValue("");
      } else {
        from === "to" && setToInputValue(value);
        from === "cc" && setCcInputValue(value);
        from === "bcc" && setBccInputValue(value);
      }
      return;
    }

    if (value.length > 0 && value) {
      const filtered = allMails?.filter(
        (user) =>
          (user.label?.toLowerCase().includes(value?.toLowerCase()) ||
            user.value?.toLowerCase().includes(value?.toLowerCase())) &&
          !selectedToUsers?.some((item) => item.value === user.value) &&
          !selectedCCUsers?.some((item) => item.value === user.value) &&
          !selectedBCCUsers?.some((item) => item.value === user.value)
      );
      setSuggestions(filtered);
      setHighlightedIndex(-1);
    } else {
      setSuggestions([]);
      setHighlightedIndex(-1);
    }
  };

  const handleKeyDown = (e, from) => {
    if (suggestions.length === 0) return;

    switch (e.key) {
      case "ArrowDown":
        setHighlightedIndex((prevIndex) =>
          prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0
        );
        break;
      case "ArrowUp":
        setHighlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1
        );
        break;
      case "Enter":
        if (highlightedIndex >= 0 && highlightedIndex < suggestions.length) {
          handleSelect(suggestions[highlightedIndex], from);
        }
        break;
      default:
        break;
    }
  };

  const handleClose = (removedUser, from) => {
    if (from === "to") {
      const newSelectedUsers = selectedToUsers.filter(
        (user) => user.value !== removedUser.value
      );
      setSelectedToUsers(newSelectedUsers);
    } else if (from === "cc") {
      const newSelectedUsers = selectedCCUsers.filter(
        (user) => user.value !== removedUser.value
      );
      setSelectedCCUsers(newSelectedUsers);
    } else if (from === "bcc") {
      const newSelectedUsers = selectedBCCUsers.filter(
        (user) => user.value !== removedUser.value
      );
      setSelectedBCCUsers(newSelectedUsers);
    }
  };

  const handleUpload = (file) => {
    console.log(file);
    if (file.file.status === 'removed') {
      setSentAttachments(prevState => prevState.filter(item => item.uid !== file.file.uid));
    } else {
      setSentAttachments((prevState) => [...prevState, file.file]);
    }
  };

  const handleSentSubmit = async () => {
    try {
      if (selectedToUsers.length === 0) {
        return false;
      }
      setSubmitLoading(true);
      const formData = new FormData();
      formData.append("fromEmailAddress", userName);
      formData.append(
        "ccEmailAddress",
        selectedCCUsers
          .map((item) => item.value)
          .join(",")
          .toString()
      );
      formData.append(
        "bccEmailAddress",
        selectedBCCUsers
          .map((item) => item.value)
          .join(",")
          .toString()
      );
      formData.append("subject", sentSubjectInput);

      Object.keys(resumeBlob).length > 0 && (
        resumeBlob.forEach((item, index) => formData.append(`attachment_${index}`, item))
      )

      sentAttachments.length > 0
        ? sentAttachments.forEach((item, index) => {
          // formData.append(`attachment_${index}`, item);
          formData.append(`attachments`, item);
        })
        : formData.append("attachment", sentAttachments);

      if (tabValue !== "3") {
        const blob = new Blob([sentDescription], { type: "text/html" });
        formData.append("body", blob);
        formData.append(
          "toEmailAddress",
          selectedToUsers
            .map((item) => item.value)
            .join(",")
            .toString()
        );

        const sentMail = await staffingUrl.post(
          `api/StaffMgnt/SendMailAndAttachment`,
          formData
        );

        if (sentMail.data.success === "Email sent successfully") {
          handleCancel();
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } else if (tabValue === "3") {
        formData.append("body", sentDescription);
        formData.append("MessageId", selectedDraftRow);
        formData.append("usermailid", userName);
        formData.append(
          "toRecipientsString",
          selectedToUsers
            .map((item) => item.value)
            .join(",")
            .toString()
        );

        const sentMail = await staffingUrl.post(
          `api/StaffMgnt/SendDraftMessage`,
          formData
        );

        if (sentMail.data.status === 1) {
          handleCancel();
          setSubmitLoading(false);
          fetchData(tabValue);
        } else {
          setSubmitLoading(false);
        }
      }

    } catch (err) {
      console.log(err);
      setSubmitLoading(false);
    }
  };

  const handleReplySubmit = async () => {
    try {
      if (selectedToUsers.length === 0) {
        return false;
      }
      setSubmitLoading(true);
      const formData = new FormData();
      if (replyOption !== 3) {
        formData.append("ReplyAll", replyOption === 2 ? true : false);
        formData.append("UserMailId", userName);
        formData.append("MessageId", messageId);
        const blob = new Blob([sentDescription], { type: "text/html" });
        formData.append("Body", blob);
      } else if (replyOption === 3) {
        formData.append("usermailid", userName);
        formData.append("messageId", messageId);
        formData.append("forwardTo",
          selectedToUsers
            .map((item) => item.value)
            .join(",")
            .toString()
        );
        formData.append("forwardCc", "");
        formData.append("forwardBcc", "");
        formData.append("comment", sentDescription);
        formData.append("forwardBcc", "");
      }

      sentAttachments.length > 0
        ? sentAttachments.forEach((item, index) => {
          formData.append(`attachment_${index}`, item);
        })
        : formData.append("attachment", sentAttachments);

      if (replyOption !== 3) {
        const sentMail = await staffingUrl.post(
          `api/StaffMgnt/ReplyToEmail`,
          formData
        );

        if (sentMail.data.status === 1) {
          handleCancel();
          handleCancelPopup();
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } else if (replyOption === 3) {
        const sentMail = await staffingUrl.post(
          `api/StaffMgnt/ForwardEmail`,
          formData
        );

        if (sentMail.data.Status === 1) {
          handleCancel();
          handleCancelPopup();
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      }

    } catch (error) {
      console.log(error);
      setSubmitLoading(false);
    }
  };

  const saveBase64AsFile = (base64, fileName, contentType) => {
    // Decode base64 string to binary data
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a blob from the byte array
    const blob = new Blob([byteArray], { type: contentType });

    if (tabValue !== "3") {
      return {
        fileURL: window.URL.createObjectURL(blob),
        fileName
      };
    } else if (tabValue === "3") {
      return blob;
    }

  };

  const blobToFile = (theBlob, fileName) => {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  };

  const handleDownloadAttachment = (name, url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = name;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleInboxRowClick = async (key, isRead) => {
    try {
      const payload = {
        usermailid: userName,
        messageid: key,
        isread: true
      }

      if (!isRead) {
        const mailRead = await staffingUrl.post('api/StaffMgnt/UpdateMailReadStatus', payload);

        if (mailRead.data.status === 1) {
          fetchData("1");
        }
      }

      const filterData =
        !!inboxItemsResponseData &&
        inboxItemsResponseData.filter((item) => item.messages.some(item2 => item2.id === key)).map(item => item.messages).flat();

      const allAttachments = [];

      for (const item of filterData) {
        const attachmentPayload = {
          userId: userName,
          messageId: item.id
        };

        const attachmentResponse = await staffingUrl.post('api/StaffMgnt/ListAttachments', attachmentPayload);
        if (attachmentResponse.data.attachments.value.length > 0) {
          const filesArr = attachmentResponse.data.attachments.value.map(file => {
            return saveBase64AsFile(file.contentBytes, file.name, file.contentType);
          });
          allAttachments.push({ messageId: item.id, attachments: filesArr });
        }
      }

      setAttachmentList(allAttachments.length > 0 ? allAttachments : null);

      setMessageData(filterData.reverse());
      setSelectedInboxRow(key);
      setVisible(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSentRowClick = async (key) => {
    try {
      const filterData =
        !!sentItemsResponseData &&
        sentItemsResponseData.filter((item) => item.messages.some(item2 => item2.id === key)).map(item => item.messages).flat();

      const allAttachments = [];

      for (const item of filterData) {
        const attachmentPayload = {
          userId: userName,
          messageId: item.id
        };

        const attachmentResponse = await staffingUrl.post('api/StaffMgnt/ListAttachments', attachmentPayload);
        if (attachmentResponse.data.attachments.value.length > 0) {
          const filesArr = attachmentResponse.data.attachments.value.map(file => {
            return saveBase64AsFile(file.contentBytes, file.name, file.contentType);
          });
          allAttachments.push({ messageId: item.id, attachments: filesArr });
        }
      }

      setAttachmentList(allAttachments.length > 0 ? allAttachments : null);
      setMessageData(filterData);
      setSelectedSentRow(key);
      setVisible(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDraftRowClick = async (key) => {
    try {
      const filterData =
        !!draftItemsResponseData &&
        draftItemsResponseData.filter((item) => item.messages.some(item2 => item2.id === key)).map(item => item.messages).flat();

      const allAttachments = [];

      for (const item of filterData) {
        const attachmentPayload = {
          userId: userName,
          messageId: item.id
        };

        const attachmentResponse = await staffingUrl.post('api/StaffMgnt/ListAttachments', attachmentPayload);
        if (attachmentResponse.data.attachments.value.length > 0) {
          const filesArr = attachmentResponse.data.attachments.value.map(file => {
            return saveBase64AsFile(file.contentBytes, file.name, file.contentType);
          });
          allAttachments.push({ messageId: item.id, attachments: filesArr });
        }
      }

      setAttachmentList(allAttachments.length > 0 ? allAttachments : null);

      if (!!filterData) {
        filterData[0].toRecipients.forEach((item) => {
          handleSelect(
            { value: item.emailAddress.address, label: item.emailAddress.name },
            "to"
          );
        });
        filterData[0].ccRecipients.forEach((item) => {
          handleSelect(
            { value: item.emailAddress.address, label: item.emailAddress.name },
            "cc"
          );
        });
        filterData[0].bccRecipients.forEach((item) => {
          handleSelect(
            { value: item.emailAddress.address, label: item.emailAddress.name },
            "bcc"
          );
        });

        setSentSubjectInput(filterData[0].subject);
        setSentDescription(filterData[0].body.content);
        // !!filterData[0].hasAttachments
        //   && setSentAttachments()
      }
      setSelectedDraftRow(key);
      setIsModalOpen(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleTrashRowClick = async (key, isRead) => {
    try {
      const payload = {
        usermailid: userName,
        messageid: key,
        isread: true
      }

      if (!isRead) {
        const mailRead = await staffingUrl.post('api/StaffMgnt/UpdateMailReadStatus', payload);

        if (mailRead.data.status === 1) {
          fetchData("4");
        }
      }

      const filterData =
        !!trashItemsResponseData &&
        trashItemsResponseData.filter((item) => item.messages.some(item2 => item2.id === key)).map(item => item.messages).flat();

      const allAttachments = [];

      for (const item of filterData) {
        const attachmentPayload = {
          userId: userName,
          messageId: item.id
        };

        const attachmentResponse = await staffingUrl.post('api/StaffMgnt/ListAttachments', attachmentPayload);
        if (attachmentResponse.data.attachments.value.length > 0) {
          const filesArr = attachmentResponse.data.attachments.value.map(file => {
            return saveBase64AsFile(file.contentBytes, file.name, file.contentType);
          });
          allAttachments.push({ messageId: item.id, attachments: filesArr });
        }
      }

      setAttachmentList(allAttachments.length > 0 ? allAttachments : null);

      setMessageData(filterData);
      setSelectedTrashRow(key);
      setVisible(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelPopup = () => {
    setVisible(false);
    setSelectedSentRow(null);
    setSelectedInboxRow(null);
    setSelectedDraftRow(null);
    setSelectedTrashRow(null);
    setMessageData(null);
    setMessageId(null);
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = async (value) => {
    if (value === "makeDraft" && tabValue !== "3") {
      try {
        const formData = new FormData();
        formData.append("usermailid", userName);
        formData.append("subject", sentSubjectInput);
        formData.append("body", sentDescription);
        formData.append("toRecipientsString", selectedToUsers
          .map((item) => item.value)
          .join(",")
          .toString()
        );
        formData.append("ccEmailAddress", selectedCCUsers
          .map((item) => item.value)
          .join(",")
          .toString()
        );
        formData.append("bccEmailAddress", selectedBCCUsers
          .map((item) => item.value)
          .join(",")
          .toString()
        );
        sentAttachments.length > 0
          ? sentAttachments.forEach((item, index) => {
            formData.append(`attachment_${index}`, item);
          })
          : formData.append("attachment", sentAttachments);

        const createDraft = await staffingUrl.post(
          `api/StaffMgnt/createDraft`,
          formData
        );
        fetchData(tabValue);
      } catch (error) {
        console.log(error);
      }
    } else if (value === "makeDraft" && tabValue === "3") {
      try {
        const formData = new FormData();
        formData.append("usermailid", userName);
        formData.append("subject", sentSubjectInput);
        formData.append("body", sentDescription);
        formData.append("toRecipientsString", selectedToUsers
          .map((item) => item.value)
          .join(",")
          .toString()
        );
        formData.append("ccEmailAddress", selectedCCUsers
          .map((item) => item.value)
          .join(",")
          .toString()
        );
        formData.append("bccEmailAddress", selectedBCCUsers
          .map((item) => item.value)
          .join(",")
          .toString()
        );
        formData.append("MessageId", selectedDraftRow);
        sentAttachments.length > 0
          ? sentAttachments.forEach((item) => {
            formData.append(`attachments[]`, item);
          })
          : formData.append("attachment", sentAttachments)

        const updateDraft = await staffingUrl.post(
          `api/StaffMgnt/updateDraft`,
          formData
        );
        fetchData(tabValue);
      } catch (error) {
        console.log(error);
      }
    }
    setIsModalOpen(false);
    setToInputValue("");
    setCcInputValue("");
    setBccInputValue("");
    setSentDescription("");
    setSentSubjectInput("");
    setSentAttachments([]);
    setSuggestions([]);
    setHighlightedIndex(-1);
    setFromField(null);
    setSelectedToUsers([]);
    setSelectedCCUsers([]);
    setSelectedBCCUsers([]);
  };

  const handleStarClick = (e, key) => {
    e.stopPropagation();
    setStarredState((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handlePrint = () => {
    window.print();
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const fetchData = async (value) => {
    try {
      setIsLoading(true);
      const tabText =
        parseInt(value) === 1
          ? "inbox"
          : parseInt(value) === 2
            ? "sentitems"
            : parseInt(value) === 3
              ? "drafts"
              : "deleteditems";

      const fetchItems = await staffingUrl.get(
        `api/StaffMgnt/GetOutlookFolderMails/${userName}/${tabText}/${selectedDate}`
      );


      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: fetchItems.data.data.length
        },
      });

      if (tabText === "inbox") {
        const formatData = fetchItems.data.data.map((item, index) => ({
          key: item.messages[0].id,
          select: "",
          starred: "",
          sender: item.messages[0].sender.emailAddress.name,
          subject: item.messages[0].subject,
          date: item.messages[0].sentDateTime.split("T")[0],
          delete: "",
          isRead: item.messages[0].isRead,
        }));

        setInboxItems(formatData.reverse());
        setbackupInboxItems(formatData);
        setInboxItemsResponseData(fetchItems.data.data);
      } else if (tabText === "sentitems") {
        const formatData = fetchItems.data.data.map((item, index) => {
          const reversedItem = item.messages.reverse();
          const latestSendItem = reversedItem.find(item2 => item2.from.emailAddress.address === userName);

          return ({
            key: latestSendItem.id,
            select: "",
            starred: "",
            receiver: latestSendItem.toRecipients
              .map((item) => item.emailAddress.name)
              .slice(0, 2)
              .join("; ")
              .concat(latestSendItem.toRecipients.length > 2 ? "; ..." : ""),
            subject: latestSendItem.subject,
            date: latestSendItem.sentDateTime.split("T")[0],
            delete: "",
            isRead: latestSendItem.isRead,
          })
        });

        setSentItems(formatData.reverse());
        setBackupSentItems(formatData);
        setSentItemsResponseData(fetchItems.data.data);
      } else if (tabText === "drafts") {
        const formatData = fetchItems.data.data.map((item, index) => ({
          key: item.messages[0]?.id,
          select: "",
          starred: "",
          receiver: `[Draft] ${item.messages[0]?.toRecipients
            .map((item2) => item2.emailAddress.name)
            .slice(0, 2)
            .join("; ")
            .concat(item.messages[0].toRecipients.length > 2 ? "; ..." : "")}`,
          subject: item.messages[0].subject,
          date: item.messages[0].sentDateTime.split("T")[0],
          delete: "",
          isRead: item.messages[0].isRead,
        }));

        setDraftItems(formatData.reverse());
        setBackupDraftItems(formatData.reverse());
        setDraftItemsResponseData(fetchItems.data.data);
      } else if (tabText === "deleteditems") {
        const formatData = fetchItems.data.data.map((item, index) => ({
          key: item.messages[0].id,
          select: "",
          starred: "",
          sender: item.messages[0].sender?.emailAddress?.name,
          subject: item.messages[0].subject,
          date: item.messages[0]?.sentDateTime?.split("T")[0],
          delete: "",
          isRead: item.messages[0].isRead,
        }));

        setTrashItems(formatData.reverse());
        setBackupTrashItems(formatData.reverse());
        setTrashItemsResponseData(fetchItems.data.data);
      }

      if (tabRef.current === tabValue) {
        if (searchField !== "") {
          const e = {
            target: {
              value: searchField,
            },
          };
          handleSearch(e);
        }
      } else {
        setSearchField("");
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getAllMailIdsinOrg = async () => {
    try {
      const res = await staffingUrl.get(`api/StaffMgnt/GetAllOrgMails`)
      setAllMails(res.data.data.map(item => ({ label: item.displayName, value: item.mail })));
    } catch (err) {
      console.log(err);
    }
  };

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination
    });

    // `dataSource` is useless since `pageSize` changed
    // if (pagination.pageSize !== tableParams.pagination?.pageSize) {
    //   setInboxItems([]);
    //   setSentItems([]);
    //   setDraftItems([]);
    //   setTrashItems([]);
    // }
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setDateRange({
        startDate: dateStrings[0],
        endDate: dateStrings[1],
      });
      setSelectedDate(
        `${dayjs(dateStrings[0]).format("DDMMYYYY")}${dayjs(
          dateStrings[1]
        ).format("DDMMYYYY")}`
      );
    } else {
      setDateRange(initialDateRange);
    }
  };

  const handleDateChange = (value) => {
    if (value !== "custom") {
      setSelectedDate(value);
      setShowDatePicker(false);
      setDateRange(initialDateRange);
    } else {
      setShowDatePicker(true);
    }
  };

  const handleDeleteMail = async (MailtoDelete) => {
    try {
      const payload = {
        usermailid: userName,
        messageid: MailtoDelete,
      };
      const DeleteMailResponse = await staffingUrl.post(
        `api/StaffMgnt/DeleteMail`,
        payload
      );
      if (DeleteMailResponse.data.status === 1) {
        handleCancelPopup();
        handleDateChange(selectedDate);
        fetchData(tabValue);
      }
    } catch (err) {
      console.log("Error while deleting mail : ", err);
    }
  };

  const menu = (from) => (
    <Menu className="max-h-50 overflow-auto">
      {suggestions.map((user, index) => (
        <Menu.Item
          key={user.value}
          onClick={() => handleSelect(user, from)}
          style={{
            backgroundColor: index === highlightedIndex ? "#e6f7ff" : "",
          }}
        >
          {user.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const inboxColumns = [
    // {
    //   title: '',
    //   dataIndex: 'select',
    //   key: 'select',
    //   render: () => (
    //     <Checkbox
    //       onClick={e => e.stopPropagation()}
    //     />
    //   ),
    //   width: 50,
    // },
    // {
    //   title: '',
    //   dataIndex: 'starred',
    //   key: 'starred',
    //   render: (text, record) => (
    //     <div className='flex items-center'>
    //       {starredState[record.key] ? (
    //         <IoStar
    //           onClick={(e) => handleStarClick(e, record.key)}
    //           style={{ color: '#f3d16f', fontSize: '18px', cursor: 'pointer' }}
    //         />
    //       ) : (
    //         <IoStarOutline
    //           onClick={(e) => handleStarClick(e, record.key)}
    //           style={{ color: '#dadce0', fontSize: '18px', cursor: 'pointer' }}
    //         />
    //       )}
    //     </div>
    //   ),
    //   width: 50,
    // },
    {
      title: "",
      dataIndex: "sender",
      key: "sender",
      width: 100,
      render: (text, record) => (
        <div className={`${!record.isRead ? 'font-semibold' : ''}`} >
          {text}
        </div>
      )
    },
    {
      title: "",
      dataIndex: "subject",
      key: "subject",
      render: (text, record) => (
        <div
          className={`${!record.isRead ? 'font-semibold' : ''}`}
          style={{
            maxWidth: "400px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {text}
        </div>
      ),
      width: 350,
    },
    {
      title: "",
      dataIndex: "date",
      key: "date",
      width: 100,
      render: (text, record) => (
        <div className={`${!record.isRead ? 'font-semibold' : ''}`} >
          {text}
        </div>
      )
    },
    {
      title: "",
      dataIndex: "isRead",
      key: "isRead",
      render: (isRead, record) => {
        return (
          <div className="flex items-center gap-4">
            {/* <MdOutlineArchive
              style={{ color: '#474d6a', fontSize: '18px' }}
              onClick={e => e.stopPropagation()}
            /> */}
            {!!isRead ? (
              <IoMailOpenOutline
                style={{ color: "#474d6a", fontSize: "18px" }}
                onClick={(e) => e.stopPropagation()}
              />
            ) : (
              <MdOutlineMarkEmailUnread
                style={{ color: "#474d6a", fontSize: "18px" }}
                onClick={(e) => e.stopPropagation()}
              />
            )}
            <MdDelete
              style={{ color: "#474d6a", fontSize: "18px" }}
              onClick={(e) => { e.stopPropagation(); handleDeleteMail(record.key) }}
            />
          </div>
        );
      },
      width: 50,
    },
  ];

  const sentColumns = [
    // {
    //   title: '',
    //   dataIndex: 'select',
    //   key: 'select',
    //   render: () => (
    //     <Checkbox
    //       onClick={e => e.stopPropagation()}
    //     />
    //   ),
    //   width: 50,
    // },
    // {
    //   title: '',
    //   dataIndex: 'starred',
    //   key: 'starred',
    //   render: (text, record) => (
    //     <div className='flex items-center'>
    //       {starredState[record.key] ? (
    //         <IoStar
    //           onClick={(e) => handleStarClick(e, record.key)}
    //           style={{ color: '#f3d16f', fontSize: '18px', cursor: 'pointer' }}
    //         />
    //       ) : (
    //         <IoStarOutline
    //           onClick={(e) => handleStarClick(e, record.key)}
    //           style={{ color: '#dadce0', fontSize: '18px', cursor: 'pointer' }}
    //         />
    //       )}
    //     </div>
    //   ),
    //   width: 50,
    // },
    {
      title: "",
      dataIndex: "receiver",
      key: "receiver",
      width: 100,
      render: (text, record) => (
        <div className={`${!record.isRead ? 'font-semibold' : ''}`}>
          {text}
        </div>
      )
    },
    {
      title: "",
      dataIndex: "subject",
      key: "subject",
      render: (text, record) => (
        <div
          className={`${!record.isRead ? 'font-semibold' : ''}`}
          style={{
            maxWidth: "400px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {text}
        </div>
      ),
      width: 350,
    },
    {
      title: "",
      dataIndex: "date",
      key: "date",
      width: 100,
      render: (text, record) => (
        <div className={`${!record.isRead ? 'font-semibold' : ''}`}>
          {text}
        </div>
      )
    },
    {
      title: "",
      dataIndex: "isRead",
      key: "isRead",
      render: (isRead, record) => (
        <div className="flex items-center gap-4">
          {/* <MdOutlineArchive
            style={{ color: '#474d6a', fontSize: '18px' }}
            onClick={e => e.stopPropagation()}
          /> */}
          {!!isRead ? (
            <IoMailOpenOutline
              style={{ color: "#474d6a", fontSize: "18px" }}
              onClick={(e) => e.stopPropagation()}
            />
          ) : (
            <MdOutlineMarkEmailUnread
              style={{ color: "#474d6a", fontSize: "18px" }}
              onClick={(e) => e.stopPropagation()}
            />
          )}
          <MdDelete
            style={{ color: "#474d6a", fontSize: "18px" }}
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteMail(record.key);
            }}
          />
        </div>
      ),
      width: 50,
    },
  ];

  const draftColumns = [
    // {
    //   title: '',
    //   dataIndex: 'select',
    //   key: 'select',
    //   render: () => (
    //     <Checkbox
    //       onClick={e => e.stopPropagation()}
    //     />
    //   ),
    //   width: 50,
    // },
    // {
    //   title: '',
    //   dataIndex: 'starred',
    //   key: 'starred',
    //   render: (text, record) => (
    //     <div className='flex items-center'>
    //       {starredState[record.key] ? (
    //         <IoStar
    //           onClick={(e) => handleStarClick(e, record.key)}
    //           style={{ color: '#f3d16f', fontSize: '18px', cursor: 'pointer' }}
    //         />
    //       ) : (
    //         <IoStarOutline
    //           onClick={(e) => handleStarClick(e, record.key)}
    //           style={{ color: '#dadce0', fontSize: '18px', cursor: 'pointer' }}
    //         />
    //       )}
    //     </div>
    //   ),
    //   width: 50,
    // },
    {
      title: "",
      dataIndex: "receiver",
      key: "sender",
      width: 100,
    },
    {
      title: "",
      dataIndex: "subject",
      key: "subject",
      render: (text) => (
        <div
          style={{
            maxWidth: "400px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {text}
        </div>
      ),
      width: 350,
    },
    {
      title: "",
      dataIndex: "date",
      key: "date",
      width: 100,
    },
    {
      title: "",
      dataIndex: "isRead",
      key: "isRead",
      render: (isRead, record) => (
        <div className="flex items-center gap-4">
          {/* <MdOutlineArchive
            style={{ color: '#474d6a', fontSize: '18px' }}
            onClick={e => e.stopPropagation()}
          /> */}
          {!!isRead ? (
            <IoMailOpenOutline
              style={{ color: "#474d6a", fontSize: "18px" }}
              onClick={(e) => e.stopPropagation()}
            />
          ) : (
            <MdOutlineMarkEmailUnread
              style={{ color: "#474d6a", fontSize: "18px" }}
              onClick={(e) => e.stopPropagation()}
            />
          )}
          <MdDelete
            style={{ color: "#474d6a", fontSize: "18px" }}
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteMail(record.key);
            }}
          />
        </div>
      ),
      width: 50,
    },
  ];

  const trashColumns = [
    // {
    //   title: '',
    //   dataIndex: 'select',
    //   key: 'select',
    //   render: () => (
    //     <Checkbox
    //       onClick={e => e.stopPropagation()}
    //     />
    //   ),
    //   width: 50,
    // },
    // {
    //   title: '',
    //   dataIndex: 'starred',
    //   key: 'starred',
    //   render: (text, record) => (
    //     <div className='flex items-center'>
    //       {starredState[record.key] ? (
    //         <IoStar
    //           onClick={(e) => handleStarClick(e, record.key)}
    //           style={{ color: '#f3d16f', fontSize: '18px', cursor: 'pointer' }}
    //         />
    //       ) : (
    //         <IoStarOutline
    //           onClick={(e) => handleStarClick(e, record.key)}
    //           style={{ color: '#dadce0', fontSize: '18px', cursor: 'pointer' }}
    //         />
    //       )}
    //     </div>
    //   ),
    //   width: 50,
    // },
    {
      title: "",
      dataIndex: "sender",
      key: "sender",
      width: 100,
      render: (text, record) => (
        <div className={`${!record.isRead ? 'font-semibold' : ''}`}>
          {!!text ? text : '[Unknown]'}
        </div>
      )
    },
    {
      title: "",
      dataIndex: "subject",
      key: "subject",
      render: (text, record) => (
        <div
          className={`${!record.isRead ? 'font-semibold' : ''}`}
          style={{
            maxWidth: "400px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {text}
        </div>
      ),
      width: 350,
    },
    {
      title: "",
      dataIndex: "date",
      key: "date",
      width: 100,
      render: (text, record) => (
        <div className={`${!record.isRead ? 'font-semibold' : ''}`}>
          {text}
        </div>
      )
    },
    {
      title: "",
      dataIndex: "isRead",
      key: "isRead",
      render: (isRead, record) => (
        <div className="flex items-center gap-4">
          {/* <MdOutlineArchive
            style={{ color: '#474d6a', fontSize: '18px' }}
            onClick={e => e.stopPropagation()}
          /> */}
          {!!isRead ? (
            <IoMailOpenOutline
              style={{ color: "#474d6a", fontSize: "18px" }}
              onClick={(e) => e.stopPropagation()}
            />
          ) : (
            <MdOutlineMarkEmailUnread
              style={{ color: "#474d6a", fontSize: "18px" }}
              onClick={(e) => e.stopPropagation()}
            />
          )}
          <MdDelete
            style={{ color: "#474d6a", fontSize: "18px" }}
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteMail(record.key);
            }}
          />
        </div>
      ),
      width: 50,
    },
  ];

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ font: [] }],
      [{ size: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ script: "sub" }, { script: "super" }],
      [{ color: [] }, { background: [] }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const getTemplate = async (id) => {
    const response = await staffingUrl.get(`api/StaffMgnt/getEmailTemplate/${id}`);
    console.log(response.data);
    setResumeTemplate(response.data);
    setSentSubjectInput(response.data?.subject);
    setSentDescription(response.data?.body);
  }

  const getBlobFile = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      const filename = url?.split('/')?.pop();
      const file = new File([blob], filename, { type: blob.type, lastModified: Date.now() });
      setResumeBlob(prev => [...prev, file]);
    } catch (err) {
      console.log(err)
    }
  }

  const handleResumeRemove = (id)=> {
    const filter = selectedResumes.filter((value)=> value.candidate_id !==id);
    setSelectedResumes(filter);
  }
  useEffect(() => {
    if (!!selectedRows) {
      setSelectedResumes(selectedRows);
      getTemplate(4002);
      showModal();
      selectedRows?.forEach((value) => {
        getBlobFile(value?.resume);
      })
    }
  }, [selectedRows])

  useEffect(() => {
    const getAPIData = async () => {
      await fetchData(tabValue);
      tabRef.current = tabValue;
    };
    getAPIData();
    getAllMailIdsinOrg();
  }, [tabValue, selectedDate]);

  useEffect(() => {
    if (inputRef.current && inputRef.current.input) {
      const inputElement = inputRef.current.input;
      if (highlightedIndex >= 0 && highlightedIndex < suggestions.length) {
        inputElement.setAttribute(
          "aria-activedescendant",
          suggestions[highlightedIndex].value
        );
      } else {
        inputElement.removeAttribute("aria-activedescendant");
      }
    }
  }, [highlightedIndex, suggestions]);

  return (
    <>
      <div>
        <div className="flex items-center justify-between gap-5 mb-5">
          <button
            onClick={showModal}
            className="flex items-center gap-2 mail-btn"
          >
            <span>
              <MdOutlineMail style={{ fontSize: "18px" }} />
            </span>
            <span className="text-[14px]">New Mail</span>
          </button>
          <Input
            className="rounded-[16px] text-[14px]"
            size="large"
            placeholder="Search Email"
            prefix={<IoSearchOutline />}
            value={searchField}
            onChange={handleSearch}
          />
          <div>
            <Select
              className="min-w-44"
              showSearch
              optionFilterProp="label"
              placeholder="Date filter"
              defaultValue="thisWeek"
              onChange={handleDateChange}
              options={[
                {
                  value: "today",
                  label: "Today",
                },
                {
                  value: "yesterday",
                  label: "Yesterday",
                },
                {
                  value: "thisWeek",
                  label: "This Week",
                },
                {
                  value: "lastWeek",
                  label: "Last Week",
                },
                {
                  value: "thisMonth",
                  label: "This Month",
                },
                {
                  value: "lastMonth",
                  label: "Last Month",
                },
                {
                  value: "custom",
                  label: "Custom Range",
                },
              ]}
            />
          </div>
          {showDatePicker && (
            <div className="custom_date_picker">
              <Space direction="vertical" size={12}>
                <RangePicker
                  defaultValue={[null]}
                  allowClear={false}
                  value={[
                    !!dateRange.startDate ? dayjs(dateRange.startDate) : null,
                    !!dateRange.endDate ? dayjs(dateRange.endDate) : null,
                  ]}
                  onChange={onRangeChange}
                />
              </Space>
            </div>
          )}
        </div>
        <Tabs
          tabPosition="left"
          className="custom-email-tabs"
          onChange={(key) => setTabValue(key)}
        >
          <TabPane
            tab={
              <div className="flex items-center gap-2">
                <MdOutlineInbox size={20} />
                <span>Inbox</span>
              </div>
            }
            key="1"
          >
            <div className="inbox-table_wrap">
              <Table
                className="inbox-table"
                columns={inboxColumns}
                dataSource={inboxItems}
                loading={isLoading}
                onRow={(record) => ({
                  onClick: () => handleInboxRowClick(record.key, record.isRead, record),
                })}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
              />
            </div>
            <Modal
              className="Email-modal"
              open={visible}
              onCancel={() => {
                handleCancelPopup();
                handleCancel();
              }}
              footer={null}
              closable={false}
            >
              <div
                className="modal-head bg-[#dddddd2e] p-5"
                style={{ borderBottom: "1px solid #ddd" }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex">
                    <p className="text-[16px] font-medium">
                      Subject :{" "}
                      {!!messageData ? messageData[0].subject : "(No Subject)"}
                    </p>
                  </div>
                  <div>
                    <div className="flex items-center gap-1 popup-flex">
                      <span
                        onClick={() => {
                          handleCancelPopup();
                          handleCancel();
                        }}
                        className="hover:bg-[#ebebeb] p-2 rounded-[3px]"
                      >
                        <IoClose
                          className="cursor-pointer text-[#4c504f]"
                          size={19}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-5 email-body">
                <div
                  className={`${isModalVisible ? '' : 'hidden'}`}
                  style={{ border: '1px solid #ddd', borderRadius: '4px', marginBottom: '1rem' }}
                >
                  <div className="modal-body">
                    <div
                      className="flex items-center justify-between"
                      style={{
                        borderBottom: '1px solid #ddd',
                        marginInline: '-20px',
                        paddingBottom: '20px',
                        marginBottom: '20px'
                      }}
                    >
                      <div
                        className="flex items-center"
                        style={{
                          border: " 1px solid #0f6cbd",
                          borderRadius: "3px",
                          background: "#0f6cbd",
                          boxShadow:
                            "0 1px 2px 0 rgba(60,64,67,.3),0 1px 3px 1px rgba(60,64,67,.15)",
                          marginLeft: '20px'
                        }}
                      >
                        <button
                          className="text-[#fff] px-5 py-1 "
                          onClick={handleReplySubmit}
                          disabled={submitLoading}
                        >
                          Send
                        </button>
                      </div>
                      <span
                        style={{ marginRight: '20px', cursor: 'pointer' }}
                        onClick={() => {
                          setIsModalVisible(false);
                          setToInputValue("");
                          setCcInputValue("");
                          setBccInputValue("");
                          setSentDescription("");
                          setSentSubjectInput("");
                          setSentAttachments([]);
                          setSuggestions([]);
                          setHighlightedIndex(-1);
                          setFromField(null);
                          setSelectedToUsers([]);
                          setSelectedCCUsers([]);
                          setSelectedBCCUsers([]);
                        }}
                      >
                        <MdDelete size={20} style={{ color: '#979797' }} />
                      </span>
                    </div>
                    <div className="flex items-center gap-2 flex-wrap">
                      <div className="flex gap-2 w-full">
                        <p
                          style={{
                            border: "1px solid #ddd",
                            borderRadius: "3px",
                            padding: "2px 10px",
                            width: "50px",
                            textAlign: "center",
                          }}
                        >
                          To
                        </p>
                        <Dropdown
                          overlay={() => menu("to")}
                          visible={suggestions.length > 0 && fromField === "to"}
                          trigger={["click"]}
                        >
                          <Input
                            ref={inputRef}
                            value={toInputValue}
                            onChange={(e) => handleInputChange(e, "to")}
                            onKeyDown={handleKeyDown}
                            style={{
                              borderRadius: "0px",
                              borderBottom: "1px solid #ddd",
                            }}
                            className="border-0 focus-within:shadow-none"
                            aria-haspopup="listbox"
                          />
                        </Dropdown>
                      </div>
                      {selectedToUsers.length > 0 && (
                        <div
                          className={`flex pb-2 flex-wrap gap-y-2 w-full`}
                          style={{ borderRadius: "0px", borderBottom: "1px solid #ddd" }}
                        >
                          {selectedToUsers.map((user) => (
                            <Tag
                              key={user.value}
                              closable
                              onClose={() => handleClose(user, "to")}
                            >
                              {user.label}
                            </Tag>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="flex items-center gap-2 mt-5 flex-wrap">
                      <div className="flex gap-2 w-full">
                        <p
                          style={{
                            border: "1px solid #ddd",
                            borderRadius: "3px",
                            padding: "2px 10px",
                            width: "50px",
                            textAlign: "center",
                          }}
                        >
                          Cc
                        </p>
                        <Dropdown
                          overlay={() => menu("cc")}
                          visible={suggestions.length > 0 && fromField === "cc"}
                          trigger={["click"]}
                        >
                          <Input
                            ref={inputRef}
                            value={ccInputValue}
                            onChange={(e) => handleInputChange(e, "cc")}
                            onKeyDown={handleKeyDown}
                            style={{
                              borderRadius: "0px",
                              borderBottom: "1px solid #ddd",
                            }}
                            className="border-0 focus-within:shadow-none"
                            aria-haspopup="listbox"
                          />
                        </Dropdown>
                      </div>
                      {selectedCCUsers.length > 0 && (
                        <div
                          className={`flex pb-2 flex-wrap gap-y-2 w-full`}
                          style={{ borderRadius: "0px", borderBottom: "1px solid #ddd" }}
                        >
                          {selectedCCUsers.map((user) => (
                            <Tag
                              key={user.value}
                              closable
                              onClose={() => handleClose(user, "cc")}
                            >
                              {user.label}
                            </Tag>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="flex items-center gap-2 mt-5 flex-wrap">
                      <div className="flex gap-2 w-full">
                        <p
                          style={{
                            border: "1px solid #ddd",
                            borderRadius: "3px",
                            padding: "2px 10px",
                            width: "50px",
                            textAlign: "center",
                          }}
                        >
                          Bcc
                        </p>
                        <Dropdown
                          overlay={() => menu("bcc")}
                          visible={suggestions.length > 0 && fromField === "bcc"}
                          trigger={["click"]}
                        >
                          <Input
                            ref={inputRef}
                            value={bccInputValue}
                            onChange={(e) => handleInputChange(e, "bcc")}
                            onKeyDown={handleKeyDown}
                            style={{
                              borderRadius: "0px",
                              borderBottom: "1px solid #ddd",
                            }}
                            className="border-0 focus-within:shadow-none"
                            aria-haspopup="listbox"
                          />
                        </Dropdown>
                      </div>
                      {selectedBCCUsers?.length > 0 && (
                        <div
                          className={`flex pb-2 flex-wrap gap-y-2 w-full`}
                          style={{ borderRadius: "0px", borderBottom: "1px solid #ddd" }}
                        >
                          {selectedBCCUsers.map((user) => (
                            <Tag
                              key={user.value}
                              closable
                              onClose={() => handleClose(user, "bcc")}
                            >
                              {user.label}
                            </Tag>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="mt-5 mailUpload">
                      <ReactQuill
                        className="custom-quill"
                        modules={modules}
                        placeholder=""
                        value={sentDescription}
                        onChange={setSentDescription}
                      />
                      <Upload
                        beforeUpload={() => false}
                        name="file"
                        listType="picture"
                        multiple
                        onChange={(file) => handleUpload(file)}
                        fileList={sentAttachments}
                        className="cursor-pointer flex flex-col justify-start items-start"
                      >
                        <Button
                          icon={
                            <RiAttachment2
                              fontSize={16}
                              className="hover:text-blue-600"
                            />
                          }
                          className="flex items-center"
                        >
                          Attachment
                        </Button>
                      </Upload>
                    </div>
                  </div>
                </div>
                {!!messageData && messageData.map((item, i) => (
                  <div
                    style={{ border: '1px solid #ddd', borderRadius: '4px' }}
                    key={i}
                    className="mt-2"
                  >
                    <div className="flex items-start justify-between p-3" style={{ borderBottom: '1px solid #ddd' }}>
                      <div className="flex items-start gap-2" style={{ maxWidth: '70%' }}>
                        {!item.from && (
                          <>
                            <a
                              href={`mailto:${userName}`}
                            >
                              <div className="email-avatar">
                                {userName[0]}
                              </div>
                            </a>
                            <div className="flex flex-col gap-0.5">
                              <p className="text-[13px] text-[#f00] font-medium">
                                <span>
                                  [Draft]
                                </span>
                              </p>
                              <div>
                                <p className="text-[13px] text-[#f00] font-medium flex gap-2">
                                  <span>
                                    This message hasn't been sent.
                                  </span>
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                        {!!item.from && (
                          <>
                            <a
                              href={`mailto:
                          ${!!item
                                  ? item.from?.emailAddress.address
                                  : ""
                                }`}
                            >
                              <div className="email-avatar">
                                {!!item
                                  ? item.from?.emailAddress.name[0]
                                  : ""}
                              </div>
                            </a>
                            <div className="flex flex-col gap-0.5">
                              <p className="text-[13px] text-[#474d6a] font-medium">
                                <span>
                                  <a
                                    href={`mailto:${!!item
                                      ? item.from?.emailAddress.name
                                      : ""
                                      }`}
                                  >
                                    {!!item
                                      ? item.from?.emailAddress.name
                                      : ""}
                                  </a>
                                </span>
                              </p>
                              <div>
                                <p className="text-[13px] text-[#474d6a] font-medium flex gap-2">
                                  <span style={{ whiteSpace: "nowrap" }}>To :</span>
                                  <div className="flex flex-row flex-wrap gap-x-1 gap-y-0">
                                    {!!item
                                      ? item.toRecipients.map((item2, i) => (
                                        <div className="flex gap-1" key={i}>
                                          <span>
                                            <a
                                              href={`mailto:${item2.emailAddress.address}`}
                                            >
                                              {item2.emailAddress.address}
                                            </a>
                                          </span>
                                          {i !==
                                            item.toRecipients.length - 1 && (
                                              <span className="text-[#c0c0c1] text-[14px]">
                                                |
                                              </span>
                                            )}
                                        </div>
                                      ))
                                      : ""}
                                  </div>
                                </p>
                              </div>
                              <div>
                                <p className="text-[13px] text-[#474d6a] font-medium flex items-center gap-2">
                                  {!!item && item.ccRecipients.length > 0 && (
                                    <span style={{ whiteSpace: "nowrap" }}>Cc :</span>
                                  )}
                                  <div className="flex flex-row flex-wrap gap-1 items-center">
                                    {!!item
                                      ? item.ccRecipients.map((item2, i) => (
                                        <div className="flex gap-1" key={i}>
                                          <span>
                                            <a
                                              href={`mailto:${item2.emailAddress.address}`}
                                            >
                                              {item2.emailAddress.address}
                                            </a>
                                          </span>
                                          {i !==
                                            item.toRecipients.length - 1 && (
                                              <span className="text-[#c0c0c1] text-[14px]">
                                                |
                                              </span>
                                            )}
                                        </div>
                                      ))
                                      : ""}
                                  </div>
                                </p>
                              </div>
                              <div className="flex items-center gap-2">
                                {/* <span className='text-[#c0c0c1] text-[14px]'>|</span> */}
                                <p className="text-[13px] text-[#474d6a] font-medium">
                                  {!!item && item.bccRecipients.length > 0 && (
                                    <span style={{ whiteSpace: "nowrap" }}>Bcc :</span>
                                  )}
                                  <div className="flex flex-row flex-wrap gap-1 items-center">
                                    {!!item
                                      ? item.bccRecipients.map((item2, i) => (
                                        <div className="flex gap-1" key={i}>
                                          <span>
                                            <a
                                              href={`mailto:${item2.emailAddress.address}`}
                                            >
                                              {item2.emailAddress.address}
                                            </a>
                                          </span>
                                          {i !==
                                            item.toRecipients.length - 1 && (
                                              <span className="text-[#c0c0c1] text-[14px]">
                                                |
                                              </span>
                                            )}
                                        </div>
                                      ))
                                      : ""}
                                  </div>
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      {!!item.from && (
                        <div className="flex flex-col gap-y-2">
                          <div className="flex items-center gap-5 justify-end">
                            <span
                              style={{ cursor: 'pointer' }}
                              onClick={() => toggleModalVisibility(1, item)}
                            >
                              <PiArrowBendUpLeftBold
                                size={18}
                                className="text-[#8a1c9a]"
                              />
                            </span>
                            <span
                              style={{ cursor: 'pointer' }}
                              onClick={() => toggleModalVisibility(2, item)}
                            >
                              <PiArrowBendDoubleUpLeftBold
                                size={18}
                                className="text-[#8a1c9a]"
                              />
                            </span>
                            <span
                              style={{ cursor: 'pointer' }}
                              onClick={() => toggleModalVisibility(3, item)}
                            >
                              <PiArrowBendUpRightBold
                                size={18}
                                className="text-[#067bd5]"
                              />
                            </span>
                          </div>

                          <p className="text-[12px] font-medium text-[#4c504f] justify-end flex gap-1.5" style={{ whiteSpace: 'nowrap' }}>
                            <span>
                              {!!item
                                ? dayjs(item.sentDateTime.split("T")[0]).format(
                                  "ddd"
                                )
                                : ""}
                            </span>
                            <span>
                              {!!item
                                ? dayjs(item.sentDateTime.split("T")[0]).format(
                                  "DD/MM/YYYY"
                                )
                                : ""}
                            </span>
                            <span>
                              {!!item
                                ? dayjs(item.sentDateTime).format(
                                  "hh:mm A"
                                )
                                : ""}
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                    {!!item && !!item.recurrence && (
                      <div>
                        {item.recurrence.pattern.type === "weekly" && (
                          <p className="font-semibold text-sm my-2">
                            {`Occurs every ${item.recurrence.pattern.daysOfWeek
                              .map(
                                (item2) =>
                                  item2.charAt(0).toUpperCase() + item2.slice(1)
                              )
                              .slice(0, -1)
                              .join(", ") +
                              ", and " +
                              item.recurrence.pattern.daysOfWeek
                                .map(
                                  (item2) =>
                                    item2.charAt(0).toUpperCase() + item2.slice(1)
                                )
                                .slice(-1)
                              }
                          from
                          ${dayjs(item.startDateTime.dateTime).format(
                                "hh:mm A"
                              )}
                          to ${dayjs(item.endDateTime.dateTime).format(
                                "hh:mm A"
                              )}
                          effective ${dayjs(
                                item.recurrence.range.startDate
                              ).format("ddd")} ${dayjs(
                                item.recurrence.range.startDate
                              ).format("MM/DD/YYYY")}`}
                          </p>
                        )}
                        <a
                          className="font-semibold text-sm text-blue-500"
                          href={`${item.webLink}`}
                        >
                          {item.location.displayName}
                        </a>
                      </div>
                    )}
                    <div
                      className="mt-2 text-[#474d6a] text-[13px] p-3"
                      dangerouslySetInnerHTML={{
                        __html: !!item ? item.body.content : <></>,
                      }}
                    />
                    <div className="m-2">
                      {!!attachmentList
                        && attachmentList.length > 0
                        && attachmentList.map((item2, i) => (
                          <div
                            key={i}
                            className="flex gap-2 flex-wrap"
                          >
                            {item2.attachments.length > 0
                              && item2.messageId === item.id
                              && item2.attachments.map((attachment, j) => (
                                <div
                                  key={j}
                                  className="file-card flex-row cursor-pointer"
                                  style={{ width: "max-content" }}
                                  onClick={() => handleDownloadAttachment(attachment?.fileName, attachment.fileURL)}
                                >
                                  <FolderOpenOutlined className="text-[14px]" />
                                  <span className="ml-2 text-[12px]">{attachment?.fileName}</span>
                                </div>
                              ))
                            }
                          </div>
                        ))
                      }
                    </div>
                  </div>
                ))}
                {/* <div style={{ border: '1px solid #ddd', borderRadius: '4px' }}>
                  <div className="flex items-start justify-between p-3" style={{ borderBottom: '1px solid #ddd' }}>
                    <div className="flex items-start gap-2" style={{ maxWidth: '70%' }}>
                      <a
                        href={`mailto:
                          ${!!messageData
                            ? messageData.from?.emailAddress.address
                            : ""
                          }`}
                      >
                        <div className="email-avatar">
                          {!!messageData
                            ? messageData.from?.emailAddress.name[0]
                            : ""}
                        </div>
                      </a>
                      <div className="flex flex-col gap-0.5">
                        <p className="text-[13px] text-[#474d6a] font-medium">
                          <span>
                            <a
                              href={`mailto:${!!messageData
                                ? messageData.from?.emailAddress.name
                                : ""
                                }`}
                            >
                              {!!messageData
                                ? messageData.from?.emailAddress.name
                                : ""}
                            </a>
                          </span>
                        </p>
                        <div>
                          <p className="text-[13px] text-[#474d6a] font-medium flex gap-2">
                            <span style={{ whiteSpace: "nowrap" }}>To :</span>
                            <div className="flex flex-row flex-wrap gap-x-1 gap-y-0">
                              {!!messageData
                                ? messageData.toRecipients.map((item, i) => (
                                  <div className="flex gap-1" key={i}>
                                    <span>
                                      <a
                                        href={`mailto:${item.emailAddress.address}`}
                                      >
                                        {item.emailAddress.address}
                                      </a>
                                    </span>
                                    {i !==
                                      messageData.toRecipients.length - 1 && (
                                        <span className="text-[#c0c0c1] text-[14px]">
                                          |
                                        </span>
                                      )}
                                  </div>
                                ))
                                : ""}
                            </div>
                          </p>
                        </div>
                        <div>
                          <p className="text-[13px] text-[#474d6a] font-medium flex items-center gap-2">
                            {!!messageData && messageData.ccRecipients.length > 0 && (
                              <span style={{ whiteSpace: "nowrap" }}>Cc :</span>
                            )}
                            <div className="flex flex-row flex-wrap gap-1 items-center">
                              {!!messageData
                                ? messageData.ccRecipients.map((item, i) => (
                                  <div className="flex gap-1" key={i}>
                                    <span>
                                      <a
                                        href={`mailto:${item.emailAddress.address}`}
                                      >
                                        {item.emailAddress.address}
                                      </a>
                                    </span>
                                    {i !==
                                      messageData.toRecipients.length - 1 && (
                                        <span className="text-[#c0c0c1] text-[14px]">
                                          |
                                        </span>
                                      )}
                                  </div>
                                ))
                                : ""}
                            </div>
                          </p>
                        </div>
                        <div className="flex items-center gap-2">
                          <p className="text-[13px] text-[#474d6a] font-medium">
                            {!!messageData && messageData.bccRecipients.length > 0 && (
                              <span style={{ whiteSpace: "nowrap" }}>Bcc :</span>
                            )}
                            <div className="flex flex-row flex-wrap gap-1 items-center">
                              {!!messageData
                                ? messageData.bccRecipients.map((item, i) => (
                                  <div className="flex gap-1">
                                    <span>
                                      <a
                                        href={`mailto:${item.emailAddress.address}`}
                                      >
                                        {item.emailAddress.address}
                                      </a>
                                    </span>
                                    {i !==
                                      messageData.toRecipients.length - 1 && (
                                        <span className="text-[#c0c0c1] text-[14px]">
                                          |
                                        </span>
                                      )}
                                  </div>
                                ))
                                : ""}
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-2">
                      <div className="flex items-center gap-5 justify-end">
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => toggleModalVisibility(1)}
                        >
                          <PiArrowBendUpLeftBold
                            size={18}
                            className="text-[#8a1c9a]"
                          />
                        </span>
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => toggleModalVisibility(2)}
                        >
                          <PiArrowBendDoubleUpLeftBold
                            size={18}
                            className="text-[#8a1c9a]"
                          />
                        </span>
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => toggleModalVisibility(3)}
                        >
                          <PiArrowBendUpRightBold
                            size={18}
                            className="text-[#067bd5]"
                          />
                        </span>
                      </div>

                      <p className="text-[12px] font-medium text-[#4c504f] justify-end flex gap-1.5" style={{ whiteSpace: 'nowrap' }}>
                        <span>
                          {!!messageData
                            ? dayjs(messageData.sentDateTime.split("T")[0]).format(
                              "ddd"
                            )
                            : ""}
                        </span>
                        <span>
                          {!!messageData
                            ? dayjs(messageData.sentDateTime.split("T")[0]).format(
                              "DD/MM/YYYY"
                            )
                            : ""}
                        </span>
                        <span>
                          {!!messageData
                            ? dayjs(messageData.sentDateTime).format(
                              "hh:mm A"
                            )
                            : ""}
                        </span>
                      </p>
                    </div>
                  </div>
                  {!!messageData && !!messageData.recurrence && (
                    <div>
                      {messageData.recurrence.pattern.type === "weekly" && (
                        <p className="font-semibold text-sm my-2">
                          {`Occurs every ${messageData.recurrence.pattern.daysOfWeek
                            .map(
                              (item) =>
                                item.charAt(0).toUpperCase() + item.slice(1)
                            )
                            .slice(0, -1)
                            .join(", ") +
                            ", and " +
                            messageData.recurrence.pattern.daysOfWeek
                              .map(
                                (item) =>
                                  item.charAt(0).toUpperCase() + item.slice(1)
                              )
                              .slice(-1)
                            }
                          from
                          ${dayjs(messageData.startDateTime.dateTime).format(
                              "hh:mm A"
                            )}
                          to ${dayjs(messageData.endDateTime.dateTime).format(
                              "hh:mm A"
                            )}
                          effective ${dayjs(
                              messageData.recurrence.range.startDate
                            ).format("ddd")} ${dayjs(
                              messageData.recurrence.range.startDate
                            ).format("MM/DD/YYYY")}`}
                        </p>
                      )}
                      <a
                        className="font-semibold text-sm text-blue-500"
                        href={`${messageData.webLink}`}
                      >
                        {messageData.location.displayName}
                      </a>
                    </div>
                  )}
                  <div
                    className="mt-2 text-[#474d6a] text-[13px] p-3"
                    dangerouslySetInnerHTML={{
                      __html: !!messageData ? messageData.body.content : <></>,
                    }}
                  />
                  <div className="flex gap-2 flex-wrap m-2">
                    {!!attachmentList
                      && attachmentList.length > 0
                      && attachmentList.map((item, i) => (
                        <div
                          key={i}
                          className="file-card flex-row cursor-pointer"
                          style={{ width: "max-content" }}
                          onClick={() => handleDownloadAttachment(item?.fileName, item.fileURL)}
                        >
                          <FolderOpenOutlined className="text-lg" />
                          <span className="ml-2">{item?.fileName}</span>
                        </div>
                      ))
                    }
                  </div>
                </div> */}
              </div>
            </Modal>
          </TabPane>
          <TabPane
            tab={
              <div className="flex items-center gap-2">
                <PiTelegramLogo size={20} />
                <span>Sent</span>
              </div>
            }
            key="2"
          >
            <div className="inbox-table_wrap">
              <Table
                className="inbox-table"
                columns={sentColumns}
                dataSource={sentItems}
                loading={isLoading}
                onRow={(record) => ({
                  onClick: () => handleSentRowClick(record.key),
                })}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
              />
            </div>
          </TabPane>
          <TabPane
            tab={
              <div className="flex items-center gap-2">
                <MdOutlineEditNote size={20} />
                <span>Drafts</span>
              </div>
            }
            key="3"
          >
            <div className="inbox-table_wrap">
              <Table
                className="inbox-table"
                columns={draftColumns}
                dataSource={draftItems}
                loading={isLoading}
                onRow={(record) => ({
                  onClick: () => handleDraftRowClick(record.key),
                })}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
              />
            </div>
          </TabPane>
          <TabPane
            tab={
              <div className="flex items-center gap-2">
                <MdDelete size={20} />
                <span>Trash</span>
              </div>
            }
            key="4"
          >
            <div className="inbox-table_wrap">
              <Table
                className="inbox-table"
                columns={trashColumns}
                dataSource={trashItems}
                loading={isLoading}
                onRow={(record) => ({
                  onClick: () => handleTrashRowClick(record.key, record.isRead),
                })}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
      <Modal
        className="Email-modal"
        open={isModalOpen}
        closable={false}
        footer={[]}
      >
        <div
          className="modal-head bg-[#fff] px-5 py-3"
          style={{ borderBottom: "1px solid #ddd" }}
        >
          <div className="flex items-center justify-between">
            <div className="flex ">
              <div
                className="flex items-center"
                style={{
                  border: " 1px solid #0f6cbd",
                  borderRadius: "3px",
                  background: "#0f6cbd",
                  boxShadow:
                    "0 1px 2px 0 rgba(60,64,67,.3),0 1px 3px 1px rgba(60,64,67,.15)",
                }}
              >
                <button
                  className="text-[#fff] px-5 py-1"
                  onClick={handleSentSubmit}
                  disabled={submitLoading}
                // style={{ borderRight: '1px solid #fff' }}
                >
                  Send
                </button>
                {/* <span className='px-2 py-1'>
                  <IoMdArrowDropdown
                    onClick={toggleDropdown}
                    className='cursor-pointer text-[#fff] text-[18px]'
                  />
                </span>
                {isDropdownOpen && (
                  <div
                    className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10"
                    style={{
                      top: '8%',
                      left: '13%',
                      transform: 'translateX(-50%)',
                      border: ' 1px solid #dddddd73'
                    }}
                  >
                    <ul className="py-0">
                      <li className="px-4 py-2 hover:bg-[#dddddd63] cursor-pointer">
                        <div className='flex items-center gap-2'>
                          <MdScheduleSend className='text-[22px] text-[#7c7c7c]' />
                          <p className='text-[14px] text-[#7c7c7c]'>
                            Schedule Send
                          </p>
                        </div>
                      </li>
                      <li className="px-4 py-2 hover:bg-[#dddddd63] cursor-pointer">
                        <div className='flex items-center gap-2'>
                          <VscSend className='text-[20px] text-[#7c7c7c]' />
                          <p className='text-[14px] text-[#7c7c7c]'>Send</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                )} */}
              </div>
            </div>
            <div className="flex items-center gap-2">
              {/* <span className="hover:bg-[#ebebeb] p-2 rounded-[3px]">
                <MdOutlineDeleteOutline
                  className="cursor-pointer text-[#4c504f]"
                  size={19}
                />
              </span> */}
              <span
                onClick={() => handleCancel("makeDraft")}
                className="hover:bg-[#ebebeb] p-2 rounded-[3px]"
              >
                <IoClose
                  className="cursor-pointer text-[#4c504f]"
                  size={19}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="modal-body">
          <div className="flex items-center gap-2 flex-wrap">
            <div className="flex gap-2 w-full">
              <p
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "3px",
                  padding: "2px 10px",
                  width: "50px",
                  textAlign: "center",
                }}
              >
                To
              </p>
              <Dropdown
                overlay={() => menu("to")}
                visible={suggestions.length > 0 && fromField === "to"}
                trigger={["click"]}
              >
                <Input
                  ref={inputRef}
                  value={toInputValue}
                  onChange={(e) => handleInputChange(e, "to")}
                  onKeyDown={handleKeyDown}
                  style={{
                    borderRadius: "0px",
                    borderBottom: "1px solid #ddd",
                  }}
                  className="border-0 focus-within:shadow-none"
                  aria-haspopup="listbox"
                />
              </Dropdown>
            </div>
            {selectedToUsers.length > 0 && (
              <div
                className={`flex pb-2 flex-wrap gap-y-2 w-full`}
                style={{ borderRadius: "0px", borderBottom: "1px solid #ddd" }}
              >
                {selectedToUsers.map((user) => (
                  <Tag
                    key={user.value}
                    closable
                    onClose={() => handleClose(user, "to")}
                  >
                    {user.label}
                  </Tag>
                ))}
              </div>
            )}
          </div>
          <div className="flex items-center gap-2 mt-5 flex-wrap">
            <div className="flex gap-2 w-full">
              <p
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "3px",
                  padding: "2px 10px",
                  width: "50px",
                  textAlign: "center",
                }}
              >
                Cc
              </p>
              <Dropdown
                overlay={() => menu("cc")}
                visible={suggestions.length > 0 && fromField === "cc"}
                trigger={["click"]}
              >
                <Input
                  ref={inputRef}
                  value={ccInputValue}
                  onChange={(e) => handleInputChange(e, "cc")}
                  onKeyDown={handleKeyDown}
                  style={{
                    borderRadius: "0px",
                    borderBottom: "1px solid #ddd",
                  }}
                  className="border-0 focus-within:shadow-none"
                  aria-haspopup="listbox"
                />
              </Dropdown>
            </div>
            {selectedCCUsers.length > 0 && (
              <div
                className={`flex pb-2 flex-wrap gap-y-2 w-full`}
                style={{ borderRadius: "0px", borderBottom: "1px solid #ddd" }}
              >
                {selectedCCUsers.map((user) => (
                  <Tag
                    key={user.value}
                    closable
                    onClose={() => handleClose(user, "cc")}
                  >
                    {user.label}
                  </Tag>
                ))}
              </div>
            )}
          </div>
          <div className="flex items-center gap-2 mt-5 flex-wrap">
            <div className="flex gap-2 w-full">
              <p
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "3px",
                  padding: "2px 10px",
                  width: "50px",
                  textAlign: "center",
                }}
              >
                Bcc
              </p>
              <Dropdown
                overlay={() => menu("bcc")}
                visible={suggestions.length > 0 && fromField === "bcc"}
                trigger={["click"]}
              >
                <Input
                  ref={inputRef}
                  value={bccInputValue}
                  onChange={(e) => handleInputChange(e, "bcc")}
                  onKeyDown={handleKeyDown}
                  style={{
                    borderRadius: "0px",
                    borderBottom: "1px solid #ddd",
                  }}
                  className="border-0 focus-within:shadow-none"
                  aria-haspopup="listbox"
                />
              </Dropdown>
            </div>
            {selectedBCCUsers?.length > 0 && (
              <div
                className={`flex pb-2 flex-wrap gap-y-2 w-full`}
                style={{ borderRadius: "0px", borderBottom: "1px solid #ddd" }}
              >
                {selectedBCCUsers.map((user) => (
                  <Tag
                    key={user.value}
                    closable
                    onClose={() => handleClose(user, "bcc")}
                  >
                    {user.label}
                  </Tag>
                ))}
              </div>
            )}
          </div>
          <div className="mt-3">
            <Input
              style={{ borderRadius: "0px", borderBottom: "1px solid #ddd" }}
              placeholder="Add a subject"
              className="border-0 focus-within:shadow-none px-0 h-10"
              value={sentSubjectInput}
              onChange={(e) => setSentSubjectInput(e.target.value)}
            />
          </div>
          <div className="mt-5 mailUpload">
            <ReactQuill
              className="custom-quill"
              modules={modules}
              placeholder=""
              value={sentDescription}
              onChange={setSentDescription}
            />
            {selectedResumes?.map((value,index) => {
              return (
                <div key={index} className="flex items-center gap-2">
                  <div
                    className="file-card-mail flex-row mb-2"
                    style={{ width: "max-content" }}
                  >
                    <FolderOpenOutlined className="text-lg" />
                    <span className="ml-2"
                      style={{ fontSize: '12px' }}
                    >
                      {value.resume?.split('/')?.pop()}
                    </span>
                  </div>

                  <span
                    className="flex-end text-lg cursor-pointer"
                    style={{ fontSize: '12px' }}

                  onClick={()=>handleResumeRemove(value.candidate_id)}
                  >
                    <MdDelete className="text-[#7c7c7cad] text-[20px]" />
                  </span>
                </div>
              );
            })}

            <Upload
              beforeUpload={() => false}
              name="file"
              listType="picture"
              multiple
              onChange={(file) => handleUpload(file)}
              fileList={sentAttachments}
              className="cursor-pointer flex flex-col justify-start items-start"
            >
              <Button
                icon={
                  <RiAttachment2
                    fontSize={16}
                    className="hover:text-blue-600"
                  />
                }
                className="flex items-center"
              >
                Attachment
              </Button>
            </Upload>
            {/* <div className="flex gap-2 flex-wrap m-2">
              {!!attachmentList
                && attachmentList.length > 0
                && attachmentList.map((item, i) => (
                  <div className="flex items-center gap-2">
                    <div
                      key={i}
                      className="file-card flex-row cursor-pointer"
                      style={{ width: "max-content" }}
                      onClick={() => handleDownloadAttachment(item?.fileName, item.fileURL)}
                    >
                      <FolderOpenOutlined className="text-lg" />
                      <span className="ml-2">{item?.fileName}</span>
                    </div>
                    <span
                      className="flex-end mt-3 text-lg cursor-pointer"
                      onClick={handleAttachmentRemove}
                    >
                      <MdDelete className="text-[#7c7c7cad] text-[20px]" />
                    </span>
                  </div>
                ))
              }
            </div> */}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Email;
