import React, { useEffect, useState } from "react";
import { Sidebar } from "./sidebar/sidebar";
import { Dashboardheader } from "./dashboardheader/Dashboardheader";
import { Outlet, useLocation } from "react-router-dom";

export const Dashboardlayout = ({ isRegistered, empPosition }) => {
  const location = useLocation();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    if (!location.pathname.includes("requirement")) {
      sessionStorage.removeItem("RD_columnFilters_table");
      sessionStorage.removeItem("RD_globalFilter_table");
      sessionStorage.removeItem("RD_sorting_table");
      sessionStorage.removeItem("RD_showGlobalFilter_table");
      sessionStorage.removeItem("RD_showColumnFilters_table");
      sessionStorage.removeItem("RD_pagination_table");
      sessionStorage.removeItem("SelectedFilterCard");
      sessionStorage.removeItem("requirementDateRange");
      sessionStorage.removeItem("isFilterClicked");
    }

    if (!location.pathname.includes("candidate")) {
      sessionStorage.removeItem("CD_columnFilters_table");
      sessionStorage.removeItem("CD_globalFilter_table");
      sessionStorage.removeItem("CD_sorting_table");
      sessionStorage.removeItem("CD_showGlobalFilter_table");
      sessionStorage.removeItem("CD_showColumnFilters_table");
      sessionStorage.removeItem("CD_pagination_table");
      sessionStorage.removeItem("filter_by");
      sessionStorage.removeItem("employee");
      sessionStorage.removeItem("source");
      sessionStorage.removeItem("tab");
    }

    if (!location.pathname.includes("employee")) {
      sessionStorage.removeItem("EMP_columnFilters_table");
      sessionStorage.removeItem("EMP_globalFilter_table");
      sessionStorage.removeItem("EMP_sorting_table");
      sessionStorage.removeItem("EMP_showGlobalFilter_table");
      sessionStorage.removeItem("EMP_showColumnFilters_table");
      sessionStorage.removeItem("EMP_pagination_table");
    }
  }, [location.pathname]);

  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark">
      <div className="flex h-screen overflow-hidden">
        <div
          className={`ease-in-out ${isDrawerOpen ? "translate-x-0" : "block"}`}
        >
          <Sidebar
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
            isRegistered={isRegistered}
            empPosition={empPosition}
          />
        </div>
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          <Dashboardheader
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
          />
          <main className="flex-grow">
            {/* <div className="mx-auto max-w-screen-2xl p-2 md:p-4 2xl:p-6"> */}
            <div className="mx-auto p-2 md:p-4 2xl:p-6 bg-[#f9fafb]">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};
