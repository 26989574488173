import { Button, Dropdown, Space } from "antd";
import { BiCaretDown } from "react-icons/bi";

const items = [
  {
    key: "1",
    label: "Rahul",
  },
  {
    key: "2",
    label: "Priya",
  },
];

export default function MentionDropdown() {
  return (
    <div className="p-2">
      <div className="inline-flex gap-2 items-center">
        <span>To:</span>
        <Dropdown
          menu={{
            items,
          }}
        >
          <Button onClick={(e) => e.preventDefault()}>
            <Space>
              Priya
              <BiCaretDown />
            </Space>
          </Button>
        </Dropdown>
      </div>
    </div>
  );
}
