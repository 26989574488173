import React, { useState, useMemo, useEffect, useRef } from 'react';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CommonTable from "./CommonTable";
import { staffingUrl } from '../interceptor';
import Chip from '@mui/material/Chip';
import { Drawer } from 'antd';
import { MdStickyNote2 } from "react-icons/md";
import { useNavigate } from "react-router-dom";


const columnVisibilityModel = {
  "candidate_name": true,
  "req_title": true,
  "customer": true,
  "priority": false,
  "req_status": true,
}

function CandidateReport() {
  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [rowNotesData, setRowNotesData] = useState([]);
  const [customerData, setCustomerData] = useState([])

  const notesRef = useRef(null);

  const scrollToBottom = () => {
    if (notesRef.current) {
      notesRef.current.scrollTop = notesRef.current.scrollHeight;
    }
  };

  const onClose = () => {
    setIsDrawerOpen(false);
    setRowNotesData([]);
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "candidate_name",
        header: "Candidate Name",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <div className='capitalize flex'>{row.original.candidate_name}</div>
        )
      },
      {
        accessorKey: "req_title",
        header: "Requirement Title",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => {
          return (
            <div
              onClick={() => {
                navigate(`/requirement/info/${row.original.req_id}`);
              }}
              style={{
                color: "#6c5295",
                fontWeight: "500",
              }}
            >
              {row.original.req_title}
            </div>
          )
        }
      },
      {
        accessorKey: "customer",
        header: "Customer",
        size: 80,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        filterVariant: 'select',
        filterSelectOptions: customerData?.map(item => ({
          label: item,
          value: item,
        }))
      },
      {
        accessorKey: "employee_status",
        header: "Type",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        filterVariant: 'select',
        filterSelectOptions: [
          {
            "label": 'Select',
            'value': ''
          },
          {
            "label": 'Billable',
            'value': 'Billable'
          },
          {
            "label": 'Non-Billable',
            'value': 'Non-Billable'
          },
          {
            "label": 'Market',
            'value': 'Market'
          },
          {
            "label": 'Fresher',
            'value': 'Fresher'
          },
          {
            "label": 'priortize',
            'value': 'priortize'
          }
        ],
      },
      {
        accessorKey: "candidate_status",
        header: "Candidate Status",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        filterVariant: 'select',
        filterSelectOptions: [
          {
            "label": 'Select',
            'value': ''
          },
          {
            "label": 'Submitted',
            'value': 'Submitted'
          },
          {
            "label": 'Interview',
            'value': 'Interview'
          },
          {
            "label": 'Selected',
            'value': 'Selected'
          },
          {
            "label": 'Onboard',
            'value': 'Onboard'
          },
          {
            "label": 'Purchase Order',
            'value': 'Purchase Order'
          },
          {
            "label": 'Onhold',
            'value': 'Onhold'
          },
          {
            "label": 'Rejected',
            'value': 'Rejected'
          },
          {
            "label": 'Screening Rejected',
            'value': 'Screening Rejected'
          },
          {
            "label": 'Internal Pre-Screening',
            'value': 'Internal Pre-Screening'
          },
          {
            "label": 'Yet to Contact',
            'value': 'Yet to Contact'
          },
        ],
      },
      {
        accessorKey: "req_status",
        header: "Requirement Status",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        filterVariant: 'select',
        filterSelectOptions: [
          {
            "label": 'Select',
            'value': ''
          },
          {
            "label": 'Ongoing',
            'value': "Ongoing"
          },
          {
            "label": 'Hold',
            'value': "Hold"
          },
          {
            "label": 'Closed',
            'value': "Closed"
          },
          {
            "label": 'Yet to Start',
            'value': "Yet to Start"
          },
        ],
      },
      {
        accessorKey: "priority",
        header: "Priority",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        filterVariant: 'select',
        filterSelectOptions: [
          {
            "label": 'Select',
            'value': ''
          },
          {
            "label": 'Low',
            'value': 'Low'
          },
          {
            "label": 'Medium',
            'value': 'Medium'
          },
          {
            "label": 'High',
            'value': 'High'
          },

        ],
        Cell: ({ row }) => (
          <Chip
            label={row.original.priority}
            size="small"
            sx={{
              fontWeight: 300,
              minWidth: 70,
              fontSize: 11,
              color: 'white',
              bgcolor: row.original.priority === 'Low'
                ? 'rgb(0, 204, 0)'
                : row.original.priority === 'Medium'
                  ? '#ffa500ed'
                  : 'rgb(255, 51, 51)'
            }}
            small
          />
        ),
      },
      // {
      //   accessorKey: "action",
      //   header: "Action",
      //   enableSorting: false,
      //   enableColumnFilter: false,
      //   muiTableHeadCellProps: {
      //     align: "left",
      //   },
      //   size: 100,
      //   muiTableBodyCellProps: {
      //     align: "center",
      //   },
      //   Cell: ({ row }) => {
      //     return <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
      //       <MdStickyNote2
      //         title='Notes'
      //         onClick={() => {
      //           setIsDrawerOpen(true);
      //           setTimeout(scrollToBottom, 100);
      //           setRowNotesData(row.original.notes);
      //         }}
      //         style={{ color: '#6c5295', fontSize: '16px' }}
      //       />
      //     </div>;
      //   },
      // },
    ],
    [tableData]
  );

  const tableHead = [
    <>
      <h4 className='text-[17px] font-normal mt-2'>
        View Report
      </h4>
    </>,
  ];

  const fetchReport = async () => {
    try {
      setIsLoading(true);
      const fetchData = await staffingUrl.get(`api/StaffMgnt/Candidatereport`);

      const data = fetchData.data.list;
      const uniqueCustomer = [...new Set(data.map(item => item.customer))];
      setCustomerData(uniqueCustomer);

      const statusValue = [];
      fetchData.data.list.forEach((item) => {
        item.candidate_name = item.candidate_name.trim().toLowerCase();
        if (item.candidate_status === 1) {
          statusValue.push({ ...item, candidate_status: "Submitted" })
        } else if (item.candidate_status === 2) {
          statusValue.push({ ...item, candidate_status: "Interview" })
        } else if (item.candidate_status === 3) {
          statusValue.push({ ...item, candidate_status: "Selected" })
        } else if (item.candidate_status === 4) {
          statusValue.push({ ...item, candidate_status: "Onboard" })
        } else if (item.candidate_status === 5) {
          statusValue.push({ ...item, candidate_status: "Purchase Order" })
        } else if (item.candidate_status === 6) {
          statusValue.push({ ...item, candidate_status: "Onhold" })
        } else if (item.candidate_status === 7) {
          statusValue.push({ ...item, candidate_status: "Rejected" })
        } else if (item.candidate_status === 8) {
          statusValue.push({ ...item, candidate_status: "Screening Rejected" })
        } else if (item.candidate_status === 9) {
          statusValue.push({ ...item, candidate_status: "Internal Pre-Screening" })
        } else if (item.candidate_status === 9) {
          statusValue.push({ ...item, candidate_status: "Yet to Contact" })
        }
      });

      statusValue.forEach((item) => {
        if (item.customer === null) {
          item.customer = "-"
        }
        if (item.employee_status === null) {
          item.employee_status = "-"
        }
        if (item.req_status === 1) {
          item.req_status = "Ongoing"
        } else if (item.req_status === 2) {
          item.req_status = "Hold"
        } else if (item.req_status === 3) {
          item.req_status = "Closed"
        } else if (item.req_status === 4) {
          item.req_status = "Yet to Start"
        }
      });
      statusValue.sort((a, b) => a.candidate_name.localeCompare(b.candidate_name));
      setTableData(statusValue);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  useEffect(() => {
    fetchReport();
  }, []);

  return (
    <>
      <Card
        sx={{
          padding: "1em",
          paddingBottom: "2em",
          boxShadow: "none"
        }}
      >
        <Grid sx={{ background: "primary.main" }}>
          <Card sx={{ boxShadow: "none" }}>
            <Grid
              sx={{
                background: "primary.main",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CommonTable
                data={tableData}
                columns={columns}
                tableKey={'Candidate_Report'}
                isLoading={isLoading}
                defaultColumn={columnVisibilityModel}
                customizeHeader={tableHead}
                pinColumn={['action']}
              />
            </Grid>
          </Card>
        </Grid>
      </Card>
      <Drawer
        title="View Notes"
        onClose={onClose}
        open={isDrawerOpen}
        size="large"
      >
        <div className="flex flex-col h-full justify-end">
          <div className="overflow-auto h-full mb-3" ref={notesRef}>
            {rowNotesData.map(item => (
              <ol class="ms-4 relative border-s border-gray-200 dark:border-gray-700">
                <li class="mb-10 ms-6">
                  <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                    {item.user[0]}
                  </span>
                  <div class="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-700 dark:border-gray-600">
                    <div class="items-center justify-between mb-3 sm:flex">
                      <time class="mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0">
                        {`${item.created_at.split('T')[0]} ${item.created_at.split('T')[1].split(':')[0]}:${item.created_at.split('T')[1].split(':')[1]}`}
                      </time>
                      <div class="text-sm font-normal text-gray-500 lex dark:text-gray-300">{item.user}</div>
                    </div>
                    <div class="p-3 text-xs italic font-normal text-gray-500 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-300">
                      {item.notes}
                    </div>
                  </div>
                </li>
              </ol>
            ))}
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default CandidateReport;
