import React, { useEffect } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Select } from 'antd';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const options = [
  { label: "Submitted", value: 1 },
  { label: "Interview", value: 2 },
  { label: "Selected", value: 3 },
  { label: "Onboard", value: 4 },
  { label: "Purchase Order", value: 5 },
  { label: "Onhold", value: 6 },
  { label: "Rejected", value: 7 }
];

export default function Log({
  logData,
  from,
  dataList,
  handlefilterLog,
  filterValue,
  setFilterValue
}) {

  const dropDownOnChange = (value) => {
    if (value === 0) {
      handlefilterLog(0, "all");
      setFilterValue('All');
    } else {
      const id = value.split('-')[1];
      const name = value.split('-')[0];
      setFilterValue(name);
      handlefilterLog(id, name);
    }
  }

  return (
    <div className="ps-3 pe-3">
      <div>
        {from === "requirement" && (
          <Select
            showSearch
            placeholder="Select a person"
            optionFilterProp="label"
            onChange={dropDownOnChange}
            style={{ minWidth: "150px" }}
            value={filterValue}
            defaultValue={"All"}
            options={
              [
                {
                  label: "All",
                  value: 0
                },
                ...dataList?.map((item) => (
                  {
                    label: `${item.firstname} ${item.lastname}`,
                    value: `${item.firstname} ${item.lastname}-${item.candidate_id}`
                  }
                ))
              ]
            }
          />
        )}
        {from === "candidate" && (
          <Select
            showSearch
            placeholder="Select a person"
            optionFilterProp="label"
            onChange={dropDownOnChange}
            style={{ minWidth: "150px" }}
            defaultValue={"All"}
            value={filterValue}
            options={
              [
                {
                  label: "All",
                  value: 0
                },
                ...dataList?.map((item) => (
                  {
                    label: item.req_title,
                    value: `${item.req_title}-${item.req_id}`
                  }
                ))
              ]
            }
          />
        )}
      </div>
      <ol className="relative border-s border-gray-200 dark:border-gray-700">
        {!!logData
          && Object.keys(logData).length > 0
          && Object.entries(logData).map(([key, value], i) => {
            return (
              <React.Fragment key={i}>
                <div className="my-2 first:mt-0 mb-6 relative flex justify-center">
                  <span className="w-full h-[2px] absolute inset-0 bg-gray-200 top-1/2"></span>
                  <h3 className="text-[#7c7c7c] text-xs font-medium dark:text-neutral-400 p-2 bg-[#f9fafb] relative z-9">
                    {dayjs(key).isSame(dayjs(), 'day')
                        ? 'Today'
                      : dayjs(key).format('DD MMM, YYYY')
                    }
                  </h3>
                </div>
                {value.map((item, i) => {
                  if (item.hasOwnProperty("actionEvent")) {
                    if (item.actionEvent !== "Discussion Log") {
                      return (
                        <li className="mb-10 ms-6" key={i}>
                          <div className="flex items-center justify-between">
                            <time className="text-xs font-semibold dark:bg-gray-700 dark:text-gray-300">
                              {item.username}
                            </time>
                            <time className="mb-1 text-[11px] font-normal text-gray-400 sm:order-last sm:mb-0">
                              {`${item.occuredAt.split('T')[1].split(':')[0]}:${item.occuredAt.split('T')[1].split(':')[1]}`}
                            </time>
                          </div>
                          <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-md -mt-4 -start-3 dark:ring-gray-900 dark:bg-blue-900">
                            {item.changedBy.charAt(0).toUpperCase()}
                          </span>
                          <div className=" dark:bg-gray-700 dark:border-gray-600">
                            <div className="text-xs font-normal text-green-700 dark:text-gray-300 mt-2">
                              {item.actionEvent}
                            </div>
                            <div className="p-3 mt-2 text-xs italic font-normal text-gray-500 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-300">
                              <ol className="space-y-1 text-gray-500 dark:text-gray-400 flex flex-col gap-1.25">
                                {item.actionEvent === "Requirement Updated"
                                  && JSON.parse(item.changes).map((change, i) => (
                                    <li className="inline-flex flex-col gap-[3px]" key={i}>
                                      <div>{change.existing_data.key}</div>
                                      <div className="flex flex-wrap items-center gap-1">
                                        {change.existing_data.key !== "Job Description" && (
                                          <span className="font-normal text-[#474d6a] dark:text-white">
                                            {change.existing_data.value}
                                          </span>
                                        )}
                                        {change.existing_data.key === "Job Description" && (
                                          <span
                                            className="font-normal text-[#474d6a] dark:text-white" style={{ wordBreak: 'break-word' }}
                                            dangerouslySetInnerHTML={{
                                              __html: change.existing_data.value
                                            }}
                                          />
                                        )}
                                        <BsArrowRight />{" "}
                                        {change.existing_data.key !== "Job Description" && (
                                          <span className="font-normal text-[#474d6a] dark:text-white">
                                            {change.new_data.value}
                                          </span>
                                        )}
                                        {change.existing_data.key === "Job Description" && (
                                          <span
                                            className="font-normal text-[#474d6a] dark:text-white"
                                            dangerouslySetInnerHTML={{
                                              __html: change.new_data.value
                                            }}
                                          />
                                        )}
                                      </div>
                                    </li>
                                  ))
                                }
                                {item.actionEvent === "Candidate Updated"
                                  && JSON.parse(item.changes).map((change, i) => (
                                    <React.Fragment key={i}>
                                      {change.hasOwnProperty("key") && (
                                        <li>
                                          <div className="mt-2 font-semibold">Previous Company Details</div>
                                          {change["Added Company Details"].length > 0 && (
                                            <>
                                              <div className="mt-2 font-medium">Added Company Details</div>
                                              {change["Added Company Details"].map((detail, index) => (
                                                <div
                                                  key={index}
                                                  className="flex flex-col gap-1.5"
                                                >
                                                  <div className="mt-1 font-normal text-[#474d6a] dark:text-white">Company Name: {detail.prev_comp_name}</div>
                                                  <div className="font-normal text-[#474d6a] dark:text-white">Role: {detail.prev_role}</div>
                                                  <div className="font-normal text-[#474d6a] dark:text-white">Start Date: {detail.prev_comp_startdate}</div>
                                                  <div className="font-normal text-[#474d6a] dark:text-white">End Date: {detail.prev_comp_enddate}</div>
                                                  <div className="font-normal text-[#474d6a] dark:text-white">Years of Experience: {detail.prev_yoe}</div>
                                                </div>
                                              ))}
                                            </>
                                          )}
                                          {change["Updated Company Details"].length > 0 && (
                                            <>
                                              <div className="mt-2 font-medium">Updated Company Details</div>
                                              {change["Updated Company Details"].map((detail, index) => (
                                                <div key={index} className="mt-1 font-normal text-[#474d6a] dark:text-white">
                                                  {detail}
                                                </div>
                                              ))}
                                            </>
                                          )}
                                          {change["Removed Company Details"].length > 0 && (
                                            <>
                                              <div className="mt-2 font-medium">Removed Company Details</div>
                                              {change["Removed Company Details"].map((detail, index) => (
                                                <div
                                                  key={index}
                                                  className="flex flex-col gap-1.5"
                                                >
                                                  <div className="mt-1 font-normal text-[#474d6a] dark:text-white">Company Name: {detail.prev_comp_name}</div>
                                                  <div className="font-normal text-[#474d6a] dark:text-white">Role: {detail.prev_role}</div>
                                                  <div className="font-normal text-[#474d6a] dark:text-white">Start Date: {detail.prev_comp_startdate}</div>
                                                  <div className="font-normal text-[#474d6a] dark:text-white">End Date: {detail.prev_comp_enddate}</div>
                                                  <div className="font-normal text-[#474d6a] dark:text-white">Years of Experience: {detail.prev_yoe}</div>
                                                </div>
                                              ))}
                                            </>
                                          )}
                                        </li>
                                      )}
                                      {!change.hasOwnProperty("key") && !change.hasOwnProperty("Resume") && (
                                        <li className="inline-flex flex-col gap-[3px]" key={i}>
                                          <div>{change.existing_data?.key}</div>
                                          <div className="flex flex-wrap items-center gap-1">
                                            <span className="font-normal text-[#474d6a] dark:text-white">
                                              {change.existing_data?.value}
                                            </span>
                                            <BsArrowRight />{" "}
                                            <span className="font-normal text-[#474d6a] dark:text-white">
                                              {change.new_data?.value}
                                            </span>
                                          </div>
                                        </li>
                                      )}
                                      {change.hasOwnProperty("Resume") && (
                                        <div className="font-normal text-[#474d6a] dark:text-white">Resume has been updated</div>
                                      )}
                                    </React.Fragment>
                                  ))
                                }
                                {(item.actionEvent === "Task Created"
                                  || item.actionEvent === "Candidate Created"
                                ) && JSON.parse(item.changes).map((change, i) => (
                                  <li className="inline-flex flex-col" key={i}>
                                    {/* <div>{change.activity_assignedto}</div> */}
                                    <div className="flex flex-wrap items-center gap-1">
                                      <span className="font-normal text-[#474d6a] dark:text-white">
                                        {change.content}
                                      </span>
                                      {/* <BsArrowRight />{" "}
                                        <span className="font-normal text-[#474d6a] dark:text-white">
                                          {change.new_data.value}
                                        </span> */}
                                    </div>
                                  </li>
                                ))
                                }
                                {item.actionEvent === "Requirement Created"
                                  && JSON.parse(item.changes).map((change, i) => (
                                    <li className="inline-flex flex-col" key={i}>
                                      {/* <div>{change.activity_assignedto}</div> */}
                                      <div className="flex flex-wrap items-center gap-1">
                                        <span className="font-normal text-[#474d6a] dark:text-white">
                                          {change.req_title} requirment is created by {item.username}
                                        </span>
                                        {/* <BsArrowRight />{" "}
                                        <span className="font-normal text-[#474d6a] dark:text-white">
                                          {change.new_data.value}
                                        </span> */}
                                      </div>
                                    </li>
                                  ))
                                }
                                {item.actionEvent === "Task Board State Changes"
                                  && JSON.parse(item.changes).map((change, i) => (
                                    <li className="inline-flex flex-col" key={i}>
                                      <div className="flex flex-wrap items-center gap-1">
                                        <span className="font-normal text-[#474d6a] dark:text-white">
                                          {change.content}
                                        </span>
                                      </div>
                                    </li>
                                  ))}
                                {
                                  (item.actionEvent === "Candidate Mapping"
                                    || item.actionEvent === "Candidate Removing"
                                    || item.actionEvent === "Candidate Status Change"
                                  ) && JSON.parse(item.changes).map((change, i) => (
                                    <li className="inline-flex flex-col" key={i}>
                                      <div className="flex flex-wrap items-center gap-1">
                                        <span
                                          className="font-normal text-[#474d6a] dark:text-white"
                                          dangerouslySetInnerHTML={{
                                            __html: change.content
                                              ?.replace(new RegExp('Requirement: #[\\d]+-[^,]+', 'g'),
                                                '<strong style="color: #da0a00; font-style: italic; font-size: 12px;">$&</strong>')
                                              ?.replace(/\n/g, '<br />')
                                          }}
                                        />
                                      </div>
                                    </li>
                                  ))
                                }
                                {item.actionEvent === "Interview Scheduled" && JSON.parse(item.changes).map((change, i) => (
                                  <li className="inline-flex flex-col" key={i}>
                                    {/* <div>{change.activity_assignedto}</div> */}
                                    <div className="flex flex-wrap items-center gap-1">
                                      <span
                                        className="font-normal text-[#474d6a] dark:text-white"
                                        dangerouslySetInnerHTML={{
                                          __html: change.content
                                            ?.replace(new RegExp('Requirement: #[\\d]+-[^,]+', 'g'),
                                              '<strong style="color: #da0a00; font-style: italic; font-size: 12px;">$&</strong>')
                                            ?.replace(/\n/g, '<br />')
                                        }}
                                      />
                                    </div>
                                    <div className="flex flex-wrap gap-1 flex-col mt-2">
                                      <div className="flex gap-3">
                                        <span className="font-normal text-[#474d6a] dark:text-white">
                                          Level
                                        </span>
                                        <span className="font-normal text-[#474d6a] dark:text-white">
                                          -
                                        </span>
                                        <span className="font-normal text-[#474d6a] dark:text-white">
                                          {change.level}
                                        </span>
                                      </div>
                                      <div className="flex gap-3">
                                        <span className="font-normal text-[#474d6a] dark:text-white">
                                          Interview Date
                                        </span>
                                        <span className="font-normal text-[#474d6a] dark:text-white">
                                          -
                                        </span>
                                        <span className="font-normal text-[#474d6a] dark:text-white">
                                          {change.interview_date}
                                        </span>
                                      </div>
                                      <div className="flex gap-3">
                                        <span className="font-normal text-[#474d6a] dark:text-white">
                                          Start Time
                                        </span>
                                        <span className="font-normal text-[#474d6a] dark:text-white">
                                          -
                                        </span>
                                        <span className="font-normal text-[#474d6a] dark:text-white">
                                          {change.start_time}
                                        </span>
                                      </div>
                                      <div className="flex gap-3">
                                        <span className="font-normal text-[#474d6a] dark:text-white">
                                          End Time
                                        </span>
                                        <span className="font-normal text-[#474d6a] dark:text-white">
                                          -
                                        </span>
                                        <span className="font-normal text-[#474d6a] dark:text-white">
                                          {change.end_time}
                                        </span>
                                      </div>
                                      <div className="flex gap-3">
                                        <span className="font-normal text-[#474d6a] dark:text-white">
                                          Interview status
                                        </span>
                                        <span className="font-normal text-[#474d6a] dark:text-white">
                                          -
                                        </span>
                                        <span className="font-normal text-[#474d6a] dark:text-white">
                                          {change.interview_status}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                                {item.actionEvent === "Interview Status Change"
                                  && JSON.parse(item.changes).map((change, i) => (
                                    <li className="inline-flex flex-col" key={i}>
                                      <div className="flex flex-wrap items-center gap-1">
                                        <span
                                          className="font-normal text-[#474d6a] dark:text-white"
                                          dangerouslySetInnerHTML={{
                                            __html: change.content
                                              ?.replace(new RegExp('Requirement: #[\\d]+-[^,]+', 'g'),
                                                '<strong style="color: #da0a00; font-style: italic; font-size: 12px;">$&</strong>')
                                              ?.replace(/\n/g, '<br />')
                                          }}
                                        />
                                      </div>
                                      <div className="flex gap-1 flex-col mt-2">
                                        <span className="text-[#474d6a] dark:text-white font-bold">
                                          Feedback
                                        </span>
                                        <span className="font-normal text-[#474d6a] dark:text-white" style={{ wordWrap: 'break-word' }}>
                                          {change.feedback}
                                        </span>
                                      </div>
                                    </li>
                                  ))
                                }
                              </ol>
                            </div>
                          </div>
                        </li>
                      )
                    } else {
                      return (
                        <li className="mb-10 ms-6" key={i}>
                          <div className="flex items-center justify-between">
                            <time className="text-xs font-semibold dark:bg-gray-700 dark:text-gray-300">
                              {item.username}
                            </time>
                            <time className="mb-1 text-[11px] font-normal text-gray-400 sm:order-last sm:mb-0">
                              {`${item.occuredAt.split('T')[1].split(':')[0]}:${item.occuredAt.split('T')[1].split(':')[1]}`}
                            </time>
                          </div>

                          <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-md -mt-4 -start-3 dark:ring-gray-900 dark:bg-blue-900 z-10">
                            {item.changedBy.charAt(0).toUpperCase()}
                          </span>
                          <div className="items-start justify-between sm:flex dark:bg-gray-700 dark:border-gray-600">
                            <div className="text-[#474d6a] text-xs font-normal dark:bg-gray-700 dark:text-gray-300 mt-1">
                              {JSON.parse(item.changes).map((change, i) => (
                                <span
                                  key={i}
                                  className="text-xs"
                                  dangerouslySetInnerHTML={{
                                    __html: change.content
                                      ?.replaceAll(new RegExp(`@${change.mentionedEmp}`, 'g'), '<strong style="color: #6C5295; font-style: italic; font-size: 12px;">$&</strong>')
                                      ?.replaceAll(new RegExp(`\\$${change.mentionedCand}`, 'g'), '<strong style="color: #007acc; font-style: italic; font-size: 12px;">$&</strong>')
                                      ?.replaceAll(new RegExp(`#${change.mentionedReq}`, 'g'), '<strong style="color: #da0a00; font-style: italic; font-size: 12px;">$&</strong>')
                                      ?.replaceAll(/\n/g, '<br />')
                                  }}
                                />
                              ))}
                            </div>
                          </div>
                        </li>
                      )
                    }
                  } else {
                    return (
                      <li className="mb-10 ms-6" key={i}>
                        <div className="flex items-center justify-between">
                          <time className="text-xs font-semibold dark:bg-gray-700 dark:text-gray-300">
                            {item?.from_emp}
                          </time>
                          <time className="mb-1 text-[11px] font-normal text-gray-400 sm:order-last sm:mb-0">
                            {`${item.created_at.split('T')[1].split(':')[0]}:${item.created_at.split('T')[1].split(':')[1]}`}
                          </time>
                        </div>

                        <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-md -mt-4 -start-3 dark:ring-gray-900 dark:bg-blue-900 z-10">
                          {item?.from_emp?.charAt(0)}
                        </span>
                        <div className="items-start justify-between sm:flex dark:bg-gray-700 dark:border-gray-600">
                          <div className="text-[#474d6a] text-xs font-normal dark:bg-gray-700 dark:text-gray-300 mt-1">
                            <span
                              className="text-xs"
                              dangerouslySetInnerHTML={{
                                __html: item.comment
                                  ?.replace(new RegExp(`@${item.to_emp}`, 'g'), '<strong style="color: #6C5295; font-style: italic; font-size: 12px;">$&</strong>')
                                  ?.replace(/\n/g, '<br />')
                              }}
                            />
                          </div>
                        </div>
                      </li>
                    )
                  }
                })}
              </React.Fragment>
            )
          })}
        {!!logData
          && Object.keys(logData).length === 0
          && (
            <div className="flex justify-center items-center h-50">
              No records to display
            </div>
          )
        }
      </ol>
    </div>
  );
}
