import React, { useState, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CommonTable from "./CommonTable";
import { Button, Modal, notification } from "antd";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import { staffingUrl } from "../interceptor";
import { MdEditNote, MdDelete } from "react-icons/md";
import { useMsal } from "@azure/msal-react";
import { ExclamationCircleFilled } from '@ant-design/icons';


export default function EmployeeDashboard() {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { confirm } = Modal;
  const logged_user = instance.getActiveAccount()["username"];
  const [api, contextHolder] = notification.useNotification();

  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [emploeeMails, setEmployeeMails] = useState([]);

  const columnVisibilityModel = {
    s_no: false,
    username: true,
    phone_number: true,
    email: true,
    position: true,
    Emp_id: true,
  };

  const openNotificationWithIcon = (req) => {
    api[req?.color]({
      message: req?.Title,
      description: req?.description,
      duration: req?.duration
    });
  }

  const showConfirm = (Emp_id) => {
    confirm({
      title: 'Warning',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure want to delete this candidate?',
      onOk() {
        DeleteCandidate(Emp_id);
      },
      onCancel() {
      },
    });
  };

  const fetchData = async () => {
    try {
      const response = await staffingUrl.get('api/StaffMgnt/listOfAllEmployees');
      const employees = response?.data?.employees.filter(item => item.status) || [];
      const formattedData = employees.map((employee, index) => ({
        ...employee,
        s_no: index + 1,
      }));
      setEmployeeMails(employees.map(item => item.email.toLowerCase()));
      setDataList(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const DeleteCandidate = async (Emp_id) => {
    const payload = {
      empId: Emp_id,
      logged_user: logged_user
    }

    await staffingUrl.post('api/StaffMgnt/deleteEmployee', payload)
      .then((res) => {
        if (res.data.status == 1) {
          fetchData();
          openNotificationWithIcon({
            color: 'success',
            Title: 'Success',
            description: "Candidate deleted successfully",
            duration: 2
          });
        } else {
          openNotificationWithIcon({
            color: 'warning',
            Title: 'Warning',
            description: "Failed to delete candidate",
            duration: 2
          })
        }
      }).catch((err) => { console.log(err) });
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "s_no",
        header: "S.No",
        enableColumnFilter: false,
        size: 20,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "username",
        header: "Username",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "email",
        header: "E-mail id",
        size: 80,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "phone_number",
        header: "Phone Number",
        size: 200,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "position",
        header: "Position",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        filterVariant: 'select',
        filterSelectOptions: [...new Set(dataList.map(item => item.position))]
      },
      {
        accessorKey: "Emp_id",
        header: "Action",
        enableSorting: false,
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          align: "left",
        },
        size: 50,
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >

              <MdEditNote
                title='Edit'
                onClick={() => {
                  navigate(`/employee/${row.original.email}`);
                }}
                style={{ color: "#3366FF", fontSize: "19px" }}
              />

              <MdDelete
                title='Delete'
                onClick={() => {
                  showConfirm(row.original.Emp_id);
                }}
                style={{ color: "#FF3333", fontSize: "16px" }}
              />
            </div>
          );
        },
      },
    ],
    [dataList]
  );

  const tableHead = (
    <Button
      style={{ position: 'relative', top: '3.5rem' }}
      variant="contained"
      className="toolbutton"
      component="label"
      size="large"
      onClick={() => {
        navigate("/employee", { state: { resetFields: true, emploeeMails } });
      }}
    >
      <AddCircleOutlineIcon className="icontool" /> Create Employee
    </Button>
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {contextHolder}
      <div>
        <div className="text-left ml-5">
          <h4 className="text-[#6c5295] text-lg font-medium">
            Employee Dashboard
          </h4>
        </div>
        <div>
          <Card sx={{ padding: "1em", paddingBottom: "2em", boxShadow: "none" }}>
            <Grid sx={{ background: "primary.main", marginTop: '1em' }}>
              <Card
                sx={{ display: "flex", flexDirection: "column", paddingTop: "0em", boxShadow: "none" }}
              >
                <Grid
                  sx={{
                    background: "primary.main",
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "none"
                  }}
                >
                  <CommonTable
                    data={dataList}
                    columns={columns}
                    tableKey={"Employee_Dashboard"}
                    isLoading={loading}
                    customizeHeader={tableHead}
                    defaultColumn={columnVisibilityModel}
                    pinColumn={["Emp_id"]}
                  />
                </Grid>
              </Card>
            </Grid>
          </Card>
        </div>
      </div>
    </>
  );
}
