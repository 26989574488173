import React, { useState, useEffect, useMemo } from "react";
import { Select, Button, Modal, notification, Form, Upload } from 'antd';
import { MdEditNote, MdDelete } from "react-icons/md";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { HiDocumentText } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { IoMdPersonAdd } from "react-icons/io";
import { BsFillClipboard2DataFill } from "react-icons/bs";
import { GiDiscussion } from "react-icons/gi";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { TbDiscountCheckFilled } from "react-icons/tb";
import { FaPaperPlane } from "react-icons/fa";
import CommonTable from "./CommonTable";
import * as moment from 'moment';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ExclamationCircleFilled } from '@ant-design/icons';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { staffingUrl } from "../interceptor";
import { TailSpin } from "react-loader-spinner";
import { useMsal } from "@azure/msal-react";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Tooltip } from 'antd'
import dayjs from "dayjs";
import { DatePicker, Space } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";

export default function CandidateDashboard() {
  const [api, contextHolder] = notification.useNotification();
  const { confirm } = Modal;
  const { instance } = useMsal();
  const { RangePicker } = DatePicker;
  const userName = instance.getActiveAccount()["username"];
  const navigate = useNavigate();
  const { state } = useLocation();
  const { filterKey, filterValue, dateRange, fromKey } = state || {};

  const initialDateRange = {
    startDate: !!dateRange
      ? dateRange.startDate
      : !!sessionStorage.getItem('candidateDateRange')
        ? JSON.parse(sessionStorage.getItem('candidateDateRange'))["startDate"]
        : null, // dayjs().add(-7, "d").format("DD-MM-YYYY"),
    endDate: !!dateRange
      ? dateRange.endDate
      : !!sessionStorage.getItem('candidateDateRange')
        ? JSON.parse(sessionStorage.getItem('candidateDateRange'))["endDate"]
        : null //dayjs().format("DD-MM-YYYY")
  };

  const [selectedOption, setSelectedOption] = useState('Employee_type');
  const [dataList, setDataList] = useState([]);
  const [globalDataList, setGlobalDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [employeeType, setEmployeeType] = useState([]);
  const [employeeStatus, setEmployeeStatus] = useState([]);
  const [candidateFilterValue, setCandidateFilterValue] = useState("candidate_req")
  const [sourcefilter, setSourceFilter] = useState("All");
  const [employeetypefilter, setEmployeetypeFilter] = useState("All");
  const [employeeStsfilter, setEmployeeStsFilter] = useState("All");
  const [dashboardInfo, setDashboardInfo] = useState([]);
  const [filterIndex, setFilterIndex] = useState(0);
  const [candidateEmails, setCandidateEmails] = useState([]);
  const [reqDateRange, setReqDateRange] = useState(initialDateRange);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [resumeResponseObj, setResumeResponseObj] = useState(null)
  const [selectedRowIndex, setSelectedRowIndex] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [mailTemplateOpen, setMailTempplateOpen] = useState(false);
  const [location, setLocation] = useState(localStorage.getItem('current_location') || '')

  const [CandidateChoiceForm] = Form.useForm();

  const columnVisibilityModel = {
    "s_no": false,
    "name": true,
    "yoe": true,
    "skill_set": true,
    "employee_type": true,
    "employee_status": true,
    "work_mode": false,
    "notice_period": false,
    "email_id": false,
    "contact_no": false,
    "current_ctc": false,
    "expected_ctc": false,
    "source": true,
    "lwd": false,
    "end_date": true,
    "city": true,
    "state": false,
    "billing_date": false,
    "country": false,
    "preferred_city": false,
    "preferred_state": false,
    "preferred_country": false,
    "open_to_relocate": false
  }

  const Source = [
    {
      value: 'Internal',
      label: 'Internal',
    }, {
      value: 'WalkIn',
      label: 'WalkIn',
    }, {
      value: 'LinkedIn',
      label: 'LinkedIn',
    }, {
      value: 'Naukri',
      label: 'Naukri',
    }, {
      value: 'Reference',
      label: 'Reference',
    }
  ]

  const openNotificationWithIcon = (req) => {
    api[req?.color]({
      message: req?.Title,
      description: req?.description,
      duration: req?.duration
    });
  }

  const Loader =
    <TailSpin
      visible={true}
      height="30"
      width="50"
      radius="1"
    />;

  const getDataLsit = async () => {
    await staffingUrl.get(`api/StaffMgnt/getCandidatesList_CountryBased/${location}`).then((res) => {
      if (res.data) {
        setCandidateEmails(res.data.map(mail => mail.email_id));
        const structuredData = res?.data?.map((data, index) => {
          // Check if any of the values in 'data' is null or empty string, and replace them with "-"
          const newData = Object.fromEntries(
            Object.entries(data).map(([key, value]) => {
              if (key === 'is_priority') {
                return [key, value ? 'YES' : 'NO'];
              } else if (value === null || value === "") {
                return [key, "-"];
              } else if (key === 'lwd' || key === 'end_date') {
                return [
                  key,
                  key === 'lwd'
                    ? moment(new Date(data.lwd)).format('DD-MM-YYYY')
                    : moment(new Date(data.end_date)).format('DD-MM-YYYY')
                ];
              } else if (key === 'yoe') {
                return [key, value.replace(/(\d+) Years?/, '$1Y').replace(/(\d+) Months?/, '$1M').trim()];
              } else {
                return [key, value];
              }
            })
          );
          return {
            ...newData,
            s_no: index + 1,
            name: `${data.firstname} ${data.lastname}`.trim().toLocaleLowerCase()
          };
        });
        setGlobalDataList(structuredData);
        setDataList(structuredData);
        setIsLoading(false);
      } else {
        openNotificationWithIcon({
          color: 'warning',
          Title: 'Warning',
          description: "No data found",
          duration: 2
        })
      }
      setIsLoading(false)
    }).catch((err) => { console.log(err); setIsLoading(false) });
  }

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setReqDateRange({
        startDate: dateStrings[0],
        endDate: dateStrings[1]
      });
    } else {
      setReqDateRange(initialDateRange);
    }
  };

  const getData = async () => {
    setIsLoading(true);
    await staffingUrl.get(`api/StaffMgnt/getEmployeeType`).then((res) => {
      setEmployeeType(res?.data?.map((item) => ({
        value: item.emp_type,
        label: item.emp_type,
      })))
    }).catch(err => console.error(err));
    await staffingUrl.get(`api/StaffMgnt/getEmployeeStatus`).then((res) => {
      setEmployeeStatus(res?.data?.map((item) => ({
        value: item.emp_status,
        label: item.emp_status,
      })))
    }).catch(err => console.error(err));
    getDataLsit();
  }

  const DeleteCandidate = async (candidate_id) => {
    await staffingUrl.post(`api/StaffMgnt/deleteCandidate?candidate_id=${candidate_id}&username=${userName}`).then((res) => {
      if (res.data.status == 1) {
        getData();
        openNotificationWithIcon({
          color: 'success',
          Title: 'Success',
          description: "Candidate deleted successfully",
          duration: 2
        });
      } else {
        openNotificationWithIcon({
          color: 'warning',
          Title: 'Warning',
          description: "Failed to delete candidate",
          duration: 2
        })
      }
    }).catch((err) => { console.log(err) });
  }

  const showConfirm = (candidate_id) => {
    confirm({
      title: 'Warning',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure want to delete this candidate?',
      onOk() {
        DeleteCandidate(candidate_id);
      },
      onCancel() {
      },
    });
  };

  const getDashboardInfo = async (req, empFilter, srcFilter, tabFilter) => {
    staffingUrl.get(`api/StaffMgnt/getCandidateDashboardInfo/${req}/${location}`)
    .then((res) => {
      if (res.data) {
        setDashboardInfo(res.data.details);
        setFilterIndex(
          req === 'Source'
            ? res?.data?.details?.findIndex(a => a.Source === (srcFilter || sourcefilter))
            : res?.data?.details?.findIndex(a => a.Employee_type === (empFilter || employeetypefilter))
        );

        if (req === 'Source') {
          filterRequirement(
            res?.data?.details[res?.data?.details.findIndex(a => a.Source === (srcFilter || sourcefilter))][tabFilter || candidateFilterValue], tabFilter || candidateFilterValue
          )
        } else if (req === "Employee_status") {
          setFilterIndex(res?.data?.details?.findIndex(a => a.Employee_status === employeeStsfilter))
          filterRequirement(
            res.data.details[res.data.details.findIndex(a => a.Employee_status === employeeStsfilter)][tabFilter || candidateFilterValue], tabFilter || candidateFilterValue
          )
        } else if (!!filterKey) {
          filterRequirement(filterValue, filterKey);
        } else {
          filterRequirement(
            res.data.details[res.data.details.findIndex(a => a.Employee_type === (empFilter || employeetypefilter))][tabFilter || candidateFilterValue], tabFilter || candidateFilterValue
          )
        }
      }
    }).catch((err) => { console.log(err) });
  }

  const handleFilterChange = (value) => {
    getDashboardInfo(value)
    setSelectedOption(value);
  };

  const handleChangeSource = async (val) => {
    // sessionStorage.setItem("source", val);
    setSourceFilter(val);
    const findInd = dashboardInfo?.findIndex((a) => a.Source === val);
    setFilterIndex(findInd);
    filterRequirement(
      dashboardInfo[findInd !== -1 ? findInd : 0][
      candidateFilterValue
      ],
      candidateFilterValue
    );
  };

  const handleChangeEmployeeType = async (val) => {
    setEmployeetypeFilter(val);
    const finInd = dashboardInfo.findIndex((a) => a.Employee_type === val);
    setFilterIndex(finInd);

    filterRequirement(
      dashboardInfo[finInd !== -1 ? finInd : 0][
      candidateFilterValue
      ],
      candidateFilterValue
    );
  };

  const handleChangeEmpStatus = async (val) => {
    setEmployeeStsFilter(val);
    setFilterIndex(dashboardInfo.findIndex((a) => a.Employee_status === val));
    const findInd = dashboardInfo.findIndex((a) => a.Employee_status === val)
    setFilterIndex(findInd);
    filterRequirement(
      dashboardInfo[findInd !== -1 ? findInd : 0][
      candidateFilterValue
      ],
      candidateFilterValue
    );
  }

  const filterRequirement = (val, key) => {
    setCandidateFilterValue(key)
    let temp;
    temp = globalDataList?.filter(item => val?.includes(item.candidate_id));
    setDataList(temp)
  }

  const rangePresets = [
    {
      label: "Today",
      value: [dayjs().add(0, "d"), dayjs()],
    },
    {
      label: "Yesterday",
      value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
    },
    {
      label: "This Week",
      value: [dayjs().startOf('week'), dayjs()],
    },
    {
      label: "Last Week",
      value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')],
    },
    {
      label: "This Month",
      value: [dayjs().startOf('month'), dayjs().endOf('day')],
    },
    {
      label: "Last Month",
      value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    },
    {
      label: "This Year",
      value: [dayjs().startOf('year'), dayjs().endOf('day')],
    },
  ];

  const columns = useMemo(
    () => [
      {
        accessorKey: "s_no",
        header: "S.No",
        enableColumnFilter: false,
        enableSorting: false,
        size: 40,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 120,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => {
          return (
            <div
              className="capitalize"
              onClick={() => {
                navigate(`/candidateInfo/info/${row.original.candidate_id}`);
              }}
              style={{
                color: "#6c5295",
                fontWeight: "500",
              }}
            >
              {row.original.name}
            </div>
          )
        }
      },
      {
        accessorKey: "skill_set",
        header: "Skill Set",
        size: 120,
        // width: 80,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => {
          return (
            // <p title={row.original.skill_set} className="w-44 text-ellipsis overflow-hidden">
            <Stack
              direction="row"
              spacing={1}
              className="w-44 flex"
              title={row.original.skill_set}
            >
              {row.original?.skill_set
                ?.split(",")
                .filter((skill) => skill.trim() !== "").length > 0 ? (
                row?.original?.skill_set
                  ?.split(",")
                  ?.map((item) => (
                    <Chip
                      label={item}
                      variant="outlined"
                      size="small"
                      key={item}
                    />
                  ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  -
                </div>
              )}
            </Stack>
            // </p>
          )
        }
      },
      {
        accessorKey: "employee_type",
        header: "Employee Type",
        size: 80,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        filterVariant: 'select',
        filterSelectOptions: [
          {
            value: 'Internal',
            label: 'Internal',
          }, {
            value: 'External',
            label: 'External',
          }, {
            value: 'Sub-Contract',
            label: 'Sub-Contract',
          },
        ]
      },
      ...(fromKey ? [
        // {
        //   accessorKey: "vendor",
        //   header: "Billable Vendor",
        //   size: 100,
        //   muiTableHeadCellProps: {
        //     align: "left",
        //   },
        //   muiTableBodyCellProps: {
        //     align: "center",
        //   },
        // },
        {
          accessorKey: "Associated_Requirements",
          header: "Vendor",
          size: 100,
          muiTableHeadCellProps: {
            align: "left",
          },
          muiTableBodyCellProps: {
            align: "center",
          },
          Cell: ({ row }) => {
            const requirements = row.original.Associated_Requirements;
            if (requirements && requirements.length > 0) {
              const customerNames = requirements.slice(0, 3).map(req => req.customer_name);
              const displayNames = customerNames.join(', ');
              return requirements.length > 3 ? `${displayNames}...` : displayNames;
            } else {
              return "-";
            }
          },
        }
      ] : []),
      {
        accessorKey: "employee_status",
        header: "Employee Status",
        size: 130,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        filterVariant: 'select',
        filterSelectOptions: [
          {
            value: 'Billable',
            label: 'Billable',
          },
          {
            value: 'Non-Billable',
            label: 'Non-Billable',
          },
          {
            value: 'Market',
            label: 'Market'
          },
          {
            value: 'Fresher',
            label: 'Fresher'
          }
        ]
      },
      {
        accessorKey: "source",
        header: "Source",
        size: 80,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        filterVariant: 'select',
        filterSelectOptions: [
          {
            value: 'Internal',
            label: 'Internal',
          }, {
            value: 'WalkIn',
            label: 'WalkIn',
          }, {
            value: 'LinkedIn',
            label: 'LinkedIn',
          }, {
            value: 'Naukri',
            label: 'Naukri',
          }, {
            value: 'Reference',
            label: 'Reference',
          }
        ]
      },
      {
        accessorKey: "yoe",
        header: "YOE",
        size: 80,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => {
          const experience = row.original.yoe || "";
          const formattedExperience = experience
            .replace(/(\d+)\s*Years?/, "$1Y")
            .replace(/(\d+)\s*Months?/, "$1M")
            .replace(/undefined\s*Y?/, "")
            .replace(/undefined\s*M?/, "")
            .trim();
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px'
              }}
            >
              {formattedExperience}
            </div>
          )
        }
      },
      {
        accessorKey: "email_id",
        header: "Email",
        size: 80,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "contact_no",
        header: "Contact",
        size: 80,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "current_ctc",
        header: "Current CTC",
        size: 80,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => {
          return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
            {row.original.current_ctc == 0 ? "-" : row.original.current_ctc}
          </div>
        }
      },
      {
        accessorKey: "expected_ctc",
        header: "Expected CTC",
        size: 80,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        }, Cell: ({ row }) => {
          return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
            {row.original.expected_ctc == 0 ? "-" : row.original.expected_ctc}
          </div>
        }
      },
      {
        accessorKey: "work_mode",
        header: "Work Mode",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        filterVariant: 'select',
        filterSelectOptions: [
          {
            "label": 'On-Site',
            'value': 'On-Site'
          },
          {
            "label": 'Hybrid',
            'value': 'Hybrid'
          },
          {
            "label": 'Remote',
            'value': 'Remote'
          },
        ]
      },
      {
        accessorKey: "is_priority",
        header: "Prioritize",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        filterVariant: 'select',
        filterSelectOptions: [
          {
            "label": 'YES',
            'value': 'YES'
          },
          {
            "label": 'NO',
            'value': 'NO'
          }
        ]
      },
      {
        accessorKey: "notice_period",
        header: "Notice Period",
        size: 80,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        filterVariant: 'select',
        filterSelectOptions: [
          {
            "label": '15 Days or less',
            'value': '15 Days or less'
          },
          {
            "label": '1 Month',
            'value': '1 Month'
          },
          {
            "label": '2 Months',
            'value': '2 Months'
          },
          {
            "label": '3 Months',
            'value': '3 Months'
          },
          {
            "label": 'More than 3 Month',
            'value': 'More than 3 Month'
          },
          {
            "label": 'Serving Notice Period',
            'value': 'Serving Notice Period'
          },
        ]
      },
      {
        accessorKey: "lwd",
        header: "LWD",
        size: 80,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        sortingFn: (rowA, rowB) => {
          const dateA = (!!rowA.original.lwd && rowA.original.lwd !== "-")
            ? dayjs(rowA.original.lwd, 'DD-MM-YYYY')
            : "-";
          const dateB = (!!rowA.original.lwd && rowA.original.lwd !== "-")
            ? dayjs(rowB.original.lwd, 'DD-MM-YYYY')
            : "-";
          return dateA - dateB;
        },
      },
      {
        accessorKey: "end_date",
        header: "End Date",
        size: 80,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        sortingFn: (rowA, rowB) => {
          const dateA = (!!rowA.original.end_date && rowA.original.end_date !== "-")
            ? dayjs(rowA.original.end_date, 'DD-MM-YYYY')
            : "-";
          const dateB = (!!rowA.original.end_date && rowA.original.end_date !== "-")
            ? dayjs(rowB.original.end_date, 'DD-MM-YYYY')
            : "-";
          return dateA - dateB;
        },
      },
      {
        accessorKey: "country",
        header: "Country",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "state",
        header: "State",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "city",
        header: "City",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "preferred_country",
        header: "Preferred Country",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "preferred_state",
        header: "Preferred State",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "preferred_city",
        header: "Preferred Location",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "open_to_relocate",
        header: "Open To Relocate",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "candidate_id",
        header: "Action",
        enableSorting: false,
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          align: "left",
        },
        size: 50,
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Tooltip title="view" placement="bottom">
                <HiDocumentText
                  onClick={() => {
                    window.open(row.original.resume);
                  }}
                  style={{ color: "rgb(0, 204, 0)", fontSize: "16px" }}
                />
              </Tooltip>
              <Tooltip title="Edit" placement="bottom">
                <MdEditNote
                  onClick={() => {
                    sessionStorage.setItem("filter_by", selectedOption);
                    sessionStorage.setItem("employee", employeetypefilter);
                    sessionStorage.setItem("source", sourcefilter);
                    sessionStorage.setItem("tab", candidateFilterValue);
                    navigate(`/candidatedashboard/${row.original.candidate_id}`);
                  }}
                  style={{ color: "#3366FF", fontSize: "19px" }}
                />
              </Tooltip>
              <Tooltip title="Delete" placement="bottom">
                <MdDelete
                  onClick={() => {
                    showConfirm(row.original.candidate_id);
                  }}
                  style={{ color: "#FF3333", fontSize: "16px" }}
                />
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [dataList]
  );

  const handleButtonClick = () => {
    sessionStorage.setItem("filter_by", selectedOption);
    sessionStorage.setItem("employee", employeetypefilter);
    sessionStorage.setItem("source", sourcefilter);
    sessionStorage.setItem("tab", candidateFilterValue);

    setIsModalVisible(true);
    // navigate("/candidate", {
    //   state: {
    //     selectedOption,
    //     sourcefilter,
    //     candidateEmails
    //   },
    // });
  };

  const tableHead = [
    <div className="flex gap-6" style={{position:'relative',top:'3.5rem'}}>
      <div>
        <Button 
          variant="contained"
          className="toolbutton"
          component="label"
          size="large"
          onClick={handleButtonClick}
        >
          <AddCircleOutlineIcon className="icontool" /> Create Candidate
        </Button>
      </div>
      <div className="flex gap-6 items-center">
        <div className="flex items-center gap-2">
          {/* <span className="text-[#886cb3] font-medium text-sm whitespace-nowrap">Filter by:</span> */}
          <Select
            onChange={handleFilterChange}
            className={"w-40 h-9"}
            placeholder="Select filter"
            size="medium"
            value={selectedOption}
            options={[
              { label: 'Employee Type', value: "Employee_type" },
              { label: 'Source', value: "Source" },
              { label: 'Employee Status', value: "Employee_status" }
            ]}
          />
        </div>
        {selectedOption === 'Employee_type' && (
          <div className="w-full flex items-center gap-2">
            {/* <span className="text-[#886cb3] font-medium text-sm whitespace-nowrap">Employee Type:
            </span> */}
            <Select
              placeholder="Employee Type"
              size="medium"
              className={"w-40 h-9"}
              value={employeetypefilter}
              onChange={handleChangeEmployeeType}
              options={[{ label: 'All', value: 'All' }, ...employeeType]}
            />
          </div>
        )}
        {selectedOption === 'Source' && (
          <div className="w-full flex items-center gap-2">
            {/* <span className="text-[#886cb3] font-medium text-sm whitespace-nowrap">
              Source:
            </span> */}
            <Select
              id="source-select"
              size="medium"
              placeholder="Source"
              className={"w-40 h-9"}
              onChange={handleChangeSource}
              value={sourcefilter}
              options={[{ label: 'All', value: 'All' }, ...Source]}
            />
          </div>
        )}
        {selectedOption === 'Employee_status' && (
          <div className="w-full flex items-center gap-2">
            {/* <span className="text-[#886cb3] font-medium text-sm whitespace-nowrap">
              Source:
            </span> */}
            <Select
              id="source-select"
              size="medium"
              placeholder="Employee Status"
              className={"w-40 h-9"}
              onChange={handleChangeEmpStatus}
              value={employeeStsfilter}
              options={[{ label: 'All', value: 'All' }, ...employeeStatus]}
            />
          </div>
        )}
        <div>
          <Button
            className="save-btn"
            style={{ display: selectedRows.length > 0 ? "block" : "none" }}
            onClick={() => setMailTempplateOpen(true)}
            disabled={selectedRows.length < 1}
          >
            Send Mail
          </Button>
        </div>
      </div>
    </div>
  ];

  const handleModalCancel = () => {
    setIsModalVisible(false);
  }

  const handleOk = () => {
    setIsModalVisible(false);
    navigate("/candidate", {
      state: {
        selectedOption,
        sourcefilter,
        candidateEmails,
        resumeResponseObj,
      },
    });
  }

  const handleManualEntry = () => {
    navigate("/candidate", {
      state: {
        selectedOption,
        sourcefilter,
        candidateEmails,
      },
    });
  }

  const handleUpload = async (file) => {
    const formDataResume = new FormData();
    formDataResume.append('file', file.file);

    try {
      const resumeResponseApi = await axios.post('https://resumemakerai.talent.jetzerp.com/extract',
        formDataResume,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
          }
        }
      );
      setResumeResponseObj({
        first_name: resumeResponseApi?.data?.first_name,
        last_name: resumeResponseApi?.data?.last_name,
        email: resumeResponseApi?.data?.email,
        phone: resumeResponseApi?.data?.phone_number.Number,
        yoe_year: resumeResponseApi.data?.years_of_experience == 0 ? ("0 Years") : (`${resumeResponseApi.data?.years_of_experience?.toString()?.split('.')[0]} Years`),
        yoe_month: !(resumeResponseApi.data?.years_of_experience.toString().includes('.')) ? '0 Months' : `${resumeResponseApi.data?.years_of_experience?.toString()?.split('.')[1]} Months`,
        skill_set: resumeResponseApi?.data?.skills?.filter((skill) => skill?.split(',')),
        countryCode: resumeResponseApi?.data?.phone_number?.Country_Code.includes("+") ? resumeResponseApi?.data?.phone_number?.Country_Code : `+${resumeResponseApi?.data?.phone_number?.Country_Code}`,
        prev_company_name: resumeResponseApi?.data?.previous_companies_names,
        prev_company_role: resumeResponseApi?.data?.previous_companies_roles,
        prev_company_duration: resumeResponseApi?.data?.previous_companies_tenures,
        resume: file.file
      })
    } catch (err) {
      console.log(err)
    }
  }

  const handleTemplateClicked = (value) => {
    console.log(value);
    navigate('/email', {
      state: {
        selectedRows
      }
    })
  }

  useEffect(() => {
    const newState = dataList.filter((_, index) => selectedRowIndex.some((value) => value == index));
    setSelectedRows(newState);
  }, [selectedRowIndex])

  useEffect(() => {
    if (!!resumeResponseObj) {
      setIsModalVisible(false);
      navigate("/candidate", {
        state: {
          selectedOption,
          sourcefilter,
          candidateEmails,
          resumeResponseObj,
        },
      });
    }
  }, [resumeResponseObj])

  useEffect(() => {
    getData();
    !fromKey
      && !filterKey
      // && filterRequirement(
      //   dashboardInfo[filterIndex]?.candidateFilterValue,
      //   candidateFilterValue
      // );
      && filterRequirement(
        dashboardInfo[filterIndex]?.candidate_req,
        'candidate_req'
      );
  }, [fromKey]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (globalDataList.length > 0) {
      const mainFilter = sessionStorage.getItem("filter_by")
      const empFilter = sessionStorage.getItem("employee")
      const srcFilter = sessionStorage.getItem("source")
      const tabFilter = sessionStorage.getItem("tab")

      if (!!mainFilter && !!empFilter && !!srcFilter && !!tabFilter) {
        setSelectedOption(mainFilter);
        setSourceFilter(srcFilter);
        setEmployeetypeFilter(empFilter);
        setCandidateFilterValue(sessionStorage.getItem("tab"));
        getDashboardInfo(mainFilter, empFilter, srcFilter, tabFilter);

        sessionStorage.removeItem("filter_by")
        sessionStorage.removeItem("employee")
        sessionStorage.removeItem("source")
        sessionStorage.removeItem("tab")
      } else {
        getDashboardInfo(selectedOption);
        setCandidateFilterValue(tabFilter || "candidate_req");
      }
    }
  }, [globalDataList]);

  useEffect(() => {
    !!filterKey && filterRequirement(filterValue, filterKey);
  }, [filterValue, filterKey]);

  useEffect(()=> {
    const tabFilter = sessionStorage.getItem("tab")

    getDashboardInfo(selectedOption,employeetypefilter,sourcefilter,tabFilter);
    getDataLsit();
  },[location])

  useEffect(() => {
    const onLocationChange = () => {
      setLocation(localStorage.getItem('current_location') || '');
    };
    window.addEventListener('locationChanged', onLocationChange);

    return () => {
      window.removeEventListener('locationChanged', onLocationChange);
    };
  }, []);

  return (
    <>
      <div className="text-left ml-5 flex justify-between items-center">
        <div className="flex gap-2">
          <h4 className=" text-[17px] font-normal">
            {!!fromKey
              ? (
                <span>
                  <span
                    className="cursor-pointer hover:opacity-50"
                    onClick={() => navigate('/dashboard')}
                  >
                    Dashboard
                  </span>
                  {` > ${fromKey}`}
                </span>
              )
              : "Candidate Dashboard"
            }
          </h4>
        </div>
        <div className="flex" style={{ marginRight: "1em" }}>
          <Space direction="vertical" size={12}>
            <RangePicker
              defaultValue={[null, null]}
              presets={rangePresets}
              onChange={onRangeChange}
              allowClear={false}
              value={[
                !!reqDateRange?.startDate
                  ? dayjs(reqDateRange?.startDate)
                  : null,
                !!reqDateRange?.endDate
                  ? dayjs(reqDateRange?.endDate)
                  : null
              ]}
              disabled
            // className="rounded-full"
            />
          </Space>
        </div>
      </div>
      {contextHolder}
      <Card sx={{ padding: "1em", paddingBottom: "2em", boxShadow: "none" }}>
        {/* <Grid
          sx={{ background: "primary.main" }}
          container
          spacing={2}
          justifyContent="center"
        >
          <Grid item xs={12} md={12}>
            <Card
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignContent: "stretch",
                justifyContent: "space-between"
              }}
            >
              <Link
                onClick={() => {
                  filterRequirement(
                    dashboardInfo[filterIndex]?.candidate_req,
                    'candidate_req'
                  )
                }}
                style={{
                  width: "100%",
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CardContent
                  sx={{
                    flex: "1 0 auto",
                    paddingBottom: '16px',
                    backgroundColor:
                      candidateFilterValue === "candidate_req"
                        ? 'rgb(235 223 255)'
                        : '',
                    borderRadius:
                      candidateFilterValue === "candidate_req" ? '5px' : ''
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <div style={{ marginBottom: '10px' }}>
                      <Typography
                        component="div"
                        sx={{ color: "#53596F", fontSize: "13px", fontWeight: "500", textAlign: 'center' }}
                      >
                        Candidates
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        width: '60%'
                      }}
                    >
                      <IoMdPersonAdd
                        style={{ fontSize: "24px", color: "#808080" }}
                      />
                      <div>
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          sx={{
                            color: "#808080",
                            fontSize: "20px",
                            lineHeight: "25px",
                          }}
                          component="h6"
                          fontWeight={600}
                        >
                          {isLoading ? Loader : dashboardInfo[filterIndex]?.candidate_count}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Link>
              <Link
                onClick={() => {
                  filterRequirement(
                    dashboardInfo[filterIndex]?.submitted_req,
                    "submitted_req"
                  )
                }}
                style={{
                  width: "100%",
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CardContent
                  sx={{
                    flex: "1 0 auto",
                    paddingBottom: '16px',
                    backgroundColor:
                      candidateFilterValue === "submitted_req"
                        ? 'rgb(235 223 255)'
                        : '',
                    borderRadius:
                      candidateFilterValue === "submitted_req" ? '5px' : ''
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <div style={{ marginBottom: '10px' }}>
                      <Typography
                        component="div"
                        sx={{ color: "#53596F", fontSize: "13px", fontWeight: "500", textAlign: 'center' }}
                      >
                        Submission
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        width: '60%'
                      }}
                    >
                      <FaPaperPlane
                        style={{ fontSize: "18px", color: "#001F3F" }}
                      />
                      <div>
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          sx={{
                            color: "#001F3F",
                            fontSize: "20px",
                            lineHeight: "25px",
                          }}
                          component="h6"
                          fontWeight={600}
                        >
                          {isLoading ? Loader : dashboardInfo[filterIndex]?.submission_count}
                        </Typography>
                      </div>

                    </div>
                  </div>
                </CardContent>

              </Link>
              <Link
                onClick={() => {
                  filterRequirement(
                    dashboardInfo[filterIndex]?.interview_req,
                    "interview_req"
                  )
                }}
                style={{
                  width: "100%",
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CardContent
                  sx={{
                    flex: "1 0 auto",
                    paddingBottom: '16px',
                    backgroundColor:
                      candidateFilterValue === "interview_req"
                        ? 'rgb(235 223 255)'
                        : '',
                    borderRadius:
                      candidateFilterValue === "interview_req" ? '5px' : ''
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <div style={{ marginBottom: '10px' }}>
                      <Typography
                        component="div"
                        sx={{ color: "#53596F", fontSize: "13px", fontWeight: "500", textAlign: 'center' }}
                      >
                        Interview
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        width: '60%'
                      }}
                    >
                      <GiDiscussion
                        style={{ fontSize: "24px", color: "#3498db" }}
                      />
                      <div>
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          sx={{
                            color: "#3498db",
                            fontSize: "20px",
                            lineHeight: "25px",
                          }}
                          component="h6"
                          fontWeight={600}
                        >
                          {isLoading ? Loader : dashboardInfo[filterIndex]?.interview_count}
                        </Typography>
                      </div>

                    </div>
                  </div>
                </CardContent>


              </Link>
              <Link
                onClick={() => {
                  filterRequirement(
                    dashboardInfo[filterIndex]?.selected_req,
                    "selected_req"
                  )
                }}
                style={{
                  width: "100%",
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"

                }}
              >
                <CardContent
                  sx={{
                    flex: "1 0 auto",
                    paddingBottom: '16px',
                    backgroundColor:
                      candidateFilterValue === "selected_req"
                        ? 'rgb(235 223 255)'
                        : '',
                    borderRadius:
                      candidateFilterValue === "selected_req" ? '5px' : ''
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <div style={{ marginBottom: '10px' }}>
                      <Typography
                        component="div"
                        sx={{ color: "#53596F", fontSize: "13px", fontWeight: "500", textAlign: 'center' }}
                      >
                        Selected
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        width: '60%'
                      }}
                    >
                      <BsFillPersonCheckFill
                        style={{ fontSize: "24px", color: "#27ae60" }}
                      />
                      <div>
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          sx={{
                            color: "#27ae60",
                            fontSize: "20px",
                            lineHeight: "25px",
                          }}
                          component="h6"
                          fontWeight={600}
                        >
                          {isLoading ? Loader : dashboardInfo[filterIndex]?.selected_count}
                        </Typography>
                      </div>

                    </div>
                  </div>
                </CardContent>

              </Link>
              <Link
                onClick={() => {
                  filterRequirement(
                    dashboardInfo[filterIndex]?.onboard_req,
                    "onboard_req"
                  )
                }}
                style={{
                  width: "100%",
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CardContent
                  sx={{
                    flex: "1 0 auto",
                    paddingBottom: '16px',
                    backgroundColor:
                      candidateFilterValue === "onboard_req"
                        ? 'rgb(235 223 255)'
                        : '',
                    borderRadius:
                      candidateFilterValue === "onboard_req" ? '5px' : ''
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <div style={{ marginBottom: '10px' }}>
                      <Typography
                        component="div"
                        sx={{ color: "#53596F", fontSize: "13px", fontWeight: "500", textAlign: 'center' }}
                      >
                        Onboard
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        width: '60%'
                      }}
                    >
                      <TbDiscountCheckFilled
                        style={{ fontSize: "24px", color: "#9b59b6" }}
                      />
                      <div>
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          sx={{
                            color: "#9b59b6",
                            fontSize: "20px",
                            lineHeight: "25px",
                          }}
                          component="h6"
                          fontWeight={600}
                        >
                          {isLoading ? Loader : dashboardInfo[filterIndex]?.onboard_count}
                        </Typography>
                      </div>

                    </div>
                  </div>
                </CardContent>

              </Link>
              <Link
                onClick={() => {
                  filterRequirement(
                    dashboardInfo[filterIndex]?.purchase_order_req,
                    "purchase_order_req"
                  )
                }}
                style={{
                  width: "100%",
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CardContent
                  sx={{
                    flex: "1 0 auto",
                    paddingBottom: '16px',
                    backgroundColor:
                      candidateFilterValue === "purchase_order_req"
                        ? 'rgb(235 223 255)'
                        : '',
                    borderRadius:
                      candidateFilterValue === "purchase_order_req" ? '5px' : ''
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <div style={{ marginBottom: '10px' }}>
                      <Typography
                        component="div"
                        sx={{ color: "#53596F", fontSize: "13px", fontWeight: "500", textAlign: 'center' }}
                      >
                        Purchase Order
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        width: '60%'
                      }}
                    >
                      <BsFillClipboard2DataFill
                        style={{ fontSize: "24px", color: "#f39c12" }}
                      />
                      <div>
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          sx={{
                            color: "#f39c12",
                            fontSize: "20px",
                            lineHeight: "25px",
                          }}
                          component="h6"
                          fontWeight={600}
                        >
                          {isLoading ? Loader : dashboardInfo[filterIndex]?.purchase_order_count}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Link>
            </Card>
          </Grid>
        </Grid> */}
        <Grid sx={{ background: "primary.main" }}>
          <Card sx={{ boxShadow: "none" }}>
            <CommonTable
              data={dataList}
              columns={columns}
              tableKey={'Candidate_Dashboard'}
              isLoading={isLoading}
              customizeHeader={tableHead}
              defaultColumn={columnVisibilityModel}
              pinColumn={['candidate_id']}
              setSelectedRowIndex={setSelectedRowIndex}
            />
          </Card>
        </Grid>
      </Card>
      <Modal
        className="candidate-modal"
        title={"Add Candidate"}
        visible={isModalVisible}
        onCancel={handleModalCancel}
        onOk={handleOk}
        footer={[
          <div className="flex items-center gap-2 justify-end">
            <Upload className="custom-upload"
              beforeUpload={() => false}
              name="file"
              listType="picture"
              maxCount={1}
              accept=".pdf, .doc, .docx"
              onChange={(file) => handleUpload(file)}
            >
              <Button icon={<UploadOutlined />}> Upload</Button>
            </Upload>
            <Button onClick={handleManualEntry}>Cancel</Button>
          </div>
        ]}
        width={500}
      >
        <Form
          form={CandidateChoiceForm}
        >
          <div className="flex items-center">
            <div>
              <Form.Item className="resume-label"
                name="Resume"
                rules={[
                  {
                    required: true,
                    message: "Please upload your resume",
                  },
                  {
                    validator: (_, file) => {
                      if (
                        !file ||
                        file.length === 0 ||
                        typeof (CandidateChoiceForm.getFieldValue('Resume')) === 'string'
                      ) {
                        return Promise.resolve();
                      }
                      const format = file.file.type;
                      const acceptedFormats = [
                        "application/pdf",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        "application/msword",
                      ];
                      // const fileExtension = ;
                      if (!acceptedFormats.includes(format)) {
                        return Promise.reject(
                          "You can only upload PDF, DOC, or DOCX files!"
                        );
                      }
                      if (!acceptedFormats.includes(format)) {
                        return Upload.LIST_IGNORE;
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <div>
                  <label>
                    Make your candidate registration simple. By uploading your resume (supported files .pdf, .doc, .docx), our AI will easily fetch
                    the information. Please proceed by uploading your resume. Otherwise click cancel for manual entry.
                  </label>
                  <div className="uploaded-file"></div>
                </div>
              </Form.Item>
            </div>
            {/* <div>
              <Form.Item>
                <Button style={{marginLeft:'10px'}}
                  key="submit"
                  className="btn save-btn"
                  onClick={handleManualEntry}
                >
                  Manual Entry
                </Button>
              </Form.Item>
            </div> */}
          </div>
        </Form>
      </Modal>

      <Modal
        title="Choose mail templates"
        visible={mailTemplateOpen}
        onCancel={() => setMailTempplateOpen(false)}
      >
        <Select
          defaultValue="1"
          style={{
            width: 120,
          }}
          onChange={handleTemplateClicked}
          options={[
            {
              value: '1',
              label: 'Template 1',
            },
            {
              value: '2',
              label: 'Template 2',
            },
            {
              value: '3',
              label: 'Template 3',
            },
          ]}
        />
      </Modal>
    </>
  );
};
