import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FaRegCircleDot } from "react-icons/fa6";
import { staffingUrl } from "../interceptor";
import { notification, Spin, Space } from "antd";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { format } from 'date-fns';
import '../index.css';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import { FaFile } from "react-icons/fa6";
import { logger } from "../utils/logger";
import dayjs from "dayjs";
import { useMsal } from "@azure/msal-react";
import { LoadingOutlined } from '@ant-design/icons';

const ItemTypes = {
  TASK: 'task',
};

const TaskBoard = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const accDetails = instance.getActiveAccount();
  const userName = accDetails?.name;
  const userEmail = accDetails?.username;
  const parentRef = useRef(null);
  const [api, contextHolder] = notification.useNotification();
  const currentEmpId = localStorage.getItem("current_emp_id");

  const currentDate = dayjs().format('YYYY-MM-DD');

  const [assignedRequirements, setAssignedRequirements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const openNotificationWithIcon = (req) => {
    api[req?.color]({
      message: req?.Title,
      description: req?.description,
      duration: req?.duration,
    });
  };

  const getActiveRequirementsforCurrentUser = async () => {
    try {
      // const res = await staffingUrl.get(
      //   `api/StaffMgnt/getEmployeeDiscussionsonRequirement/${currentEmpId}/null/0`
      // );
      const requirementsArray = [];
      const tasksArray = [];
      setIsLoading(true);

      const emp_position = localStorage.getItem('current_emp_position');
      console.log(emp_position)

      if (emp_position === "Management Team") {
        const apiResponse = await staffingUrl.get(`api/StaffMgnt/getAssignedRequirementorTask/0/${currentDate}/${currentDate}`);
        const sortedData = apiResponse.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

        sortedData.forEach(item => {
          if (item.Requirements.length > 0) {
            requirementsArray.push(...item.Requirements);
          }
          if (item.Tasks.length > 0) {
            tasksArray.push(...item.Tasks);
          }
        });

        const resultArray = [
          { Requirements: requirementsArray },
          { Tasks: tasksArray }
        ];

        setAssignedRequirements(resultArray);
        setIsLoading(false);
      } else {
        const res = await staffingUrl.get(`api/StaffMgnt/getAssignedRequirementorTask/${currentEmpId}/All/All`);
        const sortedData = res.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setAssignedRequirements(sortedData);
        setIsLoading(false);
      }
    } catch (err) {
      console.error(err);
      setAssignedRequirements([]);
      setIsLoading(false);
    }
  };

  const updateRequirementState = async (key, data, Requirements, id) => {
    if (Requirements == 'Requirements'
      ? data.requirement_status !== parseInt(key)
      : data.task_status !== parseInt(key)
    ) {
      try {
        const payload = {

          task_id: Requirements == 'Requirements' ? 0 : data.task_id,
          state: key,
          req_id: data.requirement_id

        }
        console.log(Requirements, "moveTask");

        const res = await staffingUrl.post(`api/StaffMgnt/UpdateAssignedRequirementorTaskState`, payload);
        if (res?.data?.status === 1) {
          openNotificationWithIcon({
            color: "success",
            Title: "Success",
            description: `${Requirements} status updated successfully`,
            duration: 2,
          });
          //requirement_status  task_status

          let payloads = {

            req_id: data.requirement_id,
            actionEvent: "Task Board State Changes",
            changedBy: userName,
            username: userEmail,
            occuredAt: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
            changes: [
              {
                content: `${Requirements == 'Requirements' ? 'Requirements ' : 'Task '}` + `${Requirements == 'Requirements' ? data.requirement_id : data.task_id} - ${Requirements == 'Requirements' ? data.requirement_title : data.task_type}'s state changed from ${Requirements == 'Requirements' ? data.requirement_status == 1 ? "In Progress" : data.requirement_status == 2 ? "Hold" : data.requirement_status == 3 ? "Done" : 'yet to start' : data.task_status == 1 ? "In Progress" : data.task_status == 2 ? "Hold" : data.task_status == 3 ? "Done" : 'yet to start'} to ${key == 1 ? "In Progress" : key == 2 ? "Hold" : key == 3 ? "Done" : "yet to start"} by ${userName}`,
              },
            ],

          }
          console.log(payloads, "payloads")
          logger(payloads);

          getActiveRequirementsforCurrentUser();

        } else {
          openNotificationWithIcon({
            color: "warning",
            Title: "Warning",
            description: res.data.msg,
            duration: 2,
          });
        }
      } catch (err) {
        console.error(err);
        openNotificationWithIcon({
          color: "warning",
          Title: "Warning",
          description: err.message,
          duration: 2,
        });
      }
    } else {
      return openNotificationWithIcon({
        color: "warning",
        Title: "Warning",
        description: "Requirement status is already updated",
        duration: 2,
      });
    }

  };

  const moveTask = useCallback((id, toColumn, req_id, Requirements) => {
    if (Requirements == "Requirements") {
      const req = assignedRequirements[0]['Requirements'].find((item) => item.requirement_id === id);
      if (req) {
        updateRequirementState(toColumn, req, Requirements);
      }
    }
    else {
      const req = assignedRequirements[1]['Tasks'].find((item) => item.task_id === id);
      if (req) {
        updateRequirementState(toColumn, req, Requirements, id);
      }
    }

  }, [assignedRequirements]);

  const renderTasks = (tasks, column) => {
    return (
      <div>
        {assignedRequirements[0]['Requirements'] != null &&
          assignedRequirements[0]['Requirements']
            .filter((req) => req.requirement_status == column)
            .map((req) => (
              <Task
                key={req.requirement_id}
                req={req}
                moveTask={moveTask}
                column={column}
                navigate={navigate}
                Requirements={"Requirements"}
              />
            ))}
        {assignedRequirements[1]['Tasks'] != null &&
          assignedRequirements[1]['Tasks']
            .filter((req) => req.task_status == column)
            .map((req) => (
              <Task
                key={req.requirement_id}
                req={req}
                moveTask={moveTask}
                column={column}
                navigate={navigate}
                Requirements={"Task"}
              />
            ))}
      </div>
    );
  };

  useEffect(() => {
    getActiveRequirementsforCurrentUser();
  }, []);

  return (
    <>
      {contextHolder}
      <div className="w-10% h-25 pt-5">
        <span className="text-[17px] font-normal">Task Board</span>
        <p className="text-sm mt-1 text-[#5A5A65]">A board to keep track of personal tasks.</p>
      </div>
      {!isLoading
        ? (
          <>
            {(assignedRequirements[0] && assignedRequirements[0]['Requirements']?.length || assignedRequirements[1] && assignedRequirements[1]['Tasks']?.length) > 0 ? (
              <DndProvider backend={HTML5Backend}>
                <div className="status-table-wrap px-5">
                  <table className="satus-table" style={{ width: "100%" }} ref={parentRef}>
                    <tbody>
                      <tr>
                        <Column id="4" title={<span className="not-yet" style={{ backgroundColor: '#E7E6E6' }}>Yet to Start</span>} moveTask={moveTask}>
                          {renderTasks(assignedRequirements, "4")}
                        </Column>
                        <Column id="1" title={<span className="progress" style={{ backgroundColor: '#CCE7FF' }}>In Progress</span>} moveTask={moveTask}>
                          {renderTasks(assignedRequirements, "1")}
                        </Column>
                        <Column id="2" title={<span className="hold" style={{ backgroundColor: '#ff000066' }}>Hold</span>} moveTask={moveTask}>
                          {renderTasks(assignedRequirements, "2")}
                        </Column>
                        <Column id="3" title={<span className="done" style={{ backgroundColor: '#C1FFC7' }}>Completed</span>} moveTask={moveTask}>
                          {renderTasks(assignedRequirements, "3")}
                        </Column>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </DndProvider>
            ) : (
              <div className="flex flex-col items-center justify-center pt-20">
                <div className="flex items-center justify-center mb-4">
                  <div>
                    <AssignmentLateIcon fontSize='large' />
                  </div>
                </div>
                <h2 className="text-center">No task has been assigned yet</h2>
              </div>
            )}
          </>
        )
        : (
          <Spin
            className="bg-red-0"
            style={{
              width: "100%",
              paddingTop: "130px",
            }}
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 58,
                }}
                spin
              />
            }
            size="large"
          />
        )
      }
    </>
  );
};

const Column = ({ id, title, background, children, moveTask }) => {
  const [, drop] = useDrop({
    accept: ItemTypes.TASK,
    drop: (item) => {
      moveTask(item.id, id, item.req_id, item.Requirements); // Modify moveTask to accept req_id if needed
    },
  });

  return (
    <td id={id} ref={drop} style={{ ...columnStyle, background }}>
      <span className="dropAreaLabel">{title}</span>
      <div className="draggable-container">{children}</div>
    </td>
  );
};

const Task = ({ req, column, navigate, Requirements }) => {
  const [, drag] = useDrag({
    type: ItemTypes.TASK,
    item: { id: Requirements === 'Requirements' ? req.requirement_id : req.task_id, req_id: req.requirement_id, Requirements },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const formattedDate = Requirements === 'Requirements'
    ? format(new Date(req.requirement_createdat), "MMMM dd yyyy, h:mm a")
    : format(new Date(req.task_createdat), "MMMM dd yyyy, h:mm a");

  const getBorderLeftColor = (column) => {
    switch (column) {
      case "4":
        return "#808080";
      case "1":
        return "#289ecf";
      case "3":
        return "#24d153";
      default:
        return "red";
    }
  };

  const getIconColor = (column) => {
    switch (column) {
      case "4":
        return "#808080"; // Grey for Not Yet Started
      case "1":
        return "#289ecf"; // Blue for In Progress
      case "3":
        return "#24d153"; // Green for Done
      default:
        return "red"; // Default color
    }
  };

  const draggableStyle = {
    width: "220px",
    textAlign: "center",
    background: "white",
    cursor: "move",
    padding: "10px",
    boxSizing: "border-box",
    overflow: "hidden",
    borderRight: "1px solid #a09f9f",
    borderBottom: "1px solid #a09f9f",
    borderTop: "1px solid #a09f9f",
    borderLeft: `3px solid ${getBorderLeftColor(column)}`,
    borderRadius: "5px",
    marginBottom: "10px",
    textAlign: "left",
    fontWeight: "400",
    fontSize: "15px",
  };

  const description = Requirements === 'Requirements'
    ? req.requirement_description
    : req.task_description;

  const formattedDescription = description
    ? description?.replace(/\n/g, '<br />')
    : "";

  // const formattedDescription = description
  //   ? description.replaceAll(/<\/span><\/p>/g, '<br /></span></p>')
  //   : "";

  return (
    <>
      <div
        ref={drag}
        className="draggable-item"
        style={draggableStyle}
        onClick={() => { navigate(`/requirement/info/${req.requirement_id}`) }}
      >
        <div className="flex items-center justify-between">
          <span className="font-medium">#{req.requirement_id}</span>
          <div className="created-at">
            <p style={{ fontSize: '10px' }}>{formattedDate}</p>
          </div>
        </div>
        <div className="flex flex-row pt-2 items-center justify-between">
          <div className="flex items-center">
            {Requirements == 'Requirements' ?
              (
                <FaFile
                  className="mr-2 task-icon"
                  style={{ color: getIconColor(column) }}
                />
              )
              : (
                <FaRegCircleDot
                  className="mr-2 req-icon"
                  style={{ color: getIconColor(column) }}
                />
              )}
            <p className="pr-1 status-type">
              {Requirements} #{
                Requirements == 'Requirements'
                  ? req.requirement_id
                  : req.task_id}
            </p>
          </div>
        </div>
        <div style={{ marginLeft: '20px' }}>
          <p className="pr-1 mt-1 text-[13px]">
            {Requirements == 'Requirements'
              ? req.requirement_title
              : req.task_type
            }
          </p>
          <div>
            <p
              className="mt-1 task-description truncated-text" dangerouslySetInnerHTML={{ __html: formattedDescription }}
            >
            </p>
          </div>
          <div className="flex justify-start mt-1">
            {Requirements != 'Requirements' ? <p className="pr-1 text-[10px] text-[#00000080]"><span>Due Date : </span>{req.due_date}</p> : ""}
          </div>
        </div>
      </div>
    </>
  );
};

const columnStyle = {
  verticalAlign: "top",
  // width: "33.33%",
  padding: "10px",
  backgroundColor: '#fff',
  borderRight: '10px solid #f9fafb'
};

export default TaskBoard;
